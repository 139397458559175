import type { User } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UsersState {
	users: User[];
	usersSearch: User[];
	selectedUser: null | User;
	selectedParticipants: number[];
	init_status: true | false;
}

const initialState: UsersState = {
	init_status: true,
	users: [],
	usersSearch: [],
	selectedUser: null,
	selectedParticipants: []
};

export const userSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		// setUsers populates the list of users once at the launch of app by client
		setUsers(state, action: PayloadAction<User[]>) {
			if (state.init_status) {
				state.users = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for users
		addUser(state, action: PayloadAction<User>) {
			state.users.push(action.payload);
		},
		removeUser(state, action: PayloadAction<number>) {
			state.users = state.users.filter((user) => user.id !== action.payload);
		},
		updateUser(state, action: PayloadAction<User>) {
			let index = state.users.findIndex((user) => user.id === action.payload.id);
			state.users[index] = action.payload;
		},
		setFilteredUsers(state, action: PayloadAction<User[]>) {
			state.usersSearch = action.payload;
		},
		updateSingleFilteredUser(state, action: PayloadAction<User>) {
			let index = state.usersSearch.findIndex((user) => user.id === action.payload.id);
			state.usersSearch[index] = action.payload;
		},
		resetUserSearch(state) {
			state.usersSearch = state.users;
			state.selectedUser = null;
		},
		clearSelectedUser(state) {
			state.selectedUser = null;
		},
		setSelectedUser(state, action: PayloadAction<User | null>) {
			state.selectedUser = action.payload;
			state.usersSearch = state.users.filter((user) => user.id === action.payload?.id);
		},
		setSelectedUsers(state, action: PayloadAction<User[]>) {
			state.usersSearch = action.payload;
		},
		clearSelectedUsers(state) {
			state.usersSearch = state.users;
		},
		// this is for beermeets
		setSelectedParticipants(state, action: PayloadAction<number[]>) {
			state.selectedParticipants = action.payload;
		},
		clearSelectedParticipants(state) {
			state.selectedParticipants = [];
		},
		
	},
});

export const {
	addUser,
	removeUser,
	updateUser,
	setUsers,
	setFilteredUsers,
	updateSingleFilteredUser,
	resetUserSearch,
	clearSelectedUser,
	setSelectedUser,
	clearSelectedParticipants,
	setSelectedParticipants
} = userSlice.actions;
export default userSlice.reducer;
