import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";

import React from "react";
import { Outlet } from "react-router-dom";

const NoFooterLayout = () => {
    return (
      
        
         <div className="flex flex-col h-screen overflow-hidden">

            {/* <div className="flex flex-col h-full">
          
            <div className="">
                <Navbar />
            </div>
            
            <div className="flex-1">
              <Outlet />
            </div>
            

        </div>
         */}
          <div className="flex bg-red-100"><Navbar /></div>
          <div className="flex flex-1 bg-gray-100"><Outlet /></div>
          <div className="flex bg-blue-100"><Footer/></div>
        </div>
        
      
    );
  };
  
export default NoFooterLayout;

