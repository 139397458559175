import React from "react";
import UserForm from "../components/Users/UserForm";
import RegisterFormTile from "../components/Users/RegisterFormTile";

const Register: React.FC = () => {
	document.title = "Register Page";

	return (
		<div className="flex mt-4 w-1/5 mx-auto gap-6">
			<div className="w-3/4">
				<RegisterFormTile></RegisterFormTile>
			</div>
		</div>
	);
};

export default Register;
