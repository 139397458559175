import type { Article } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ArticleState {
	articles: Article[];
	init_status: true | false;
}

const initialState: ArticleState = {
	init_status: true,
	articles: [],
};

export const articleSlice = createSlice({
	name: "articles",
	initialState,
	reducers: {
		// setArtciles populates the list
		setArticles(state, action: PayloadAction<Article[]>) {
			if (state.init_status) {
				state.articles = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for users
		addArticle(state, action: PayloadAction<Article>) {
			state.articles.push(action.payload);
		},
		removeArticle(state, action: PayloadAction<number>) {
			state.articles = state.articles.filter((article) => article.id !== action.payload);
		},
		updateArticle(state, action: PayloadAction<Article>) {
			let index = state.articles.findIndex((article) => article.id === action.payload.id);
			state.articles[index] = action.payload;
		},
	},
});

export const { addArticle, removeArticle, updateArticle, setArticles } = articleSlice.actions;
export default articleSlice.reducer;
