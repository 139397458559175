import { Message, User } from "@prisma/client";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface IProps {
	message: Message;
}

const MessageTile: React.FC<IProps> = (props: IProps) => {
	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
	const userId = cookies.id;
	let author: User | undefined = useSelector((state: RootState) =>
		state.users.users.find((user) => user.id === props.message.userId)
	);

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md p-2 m-2">
			<div className="grow">
				<div
					className={
						"w-12 rounded-full overflow-hidden " +
						(props.message.userId === userId ? "float-right" : "float-left")
					}
				>
					<svg
						className="w-[48px] h-[48px] text-gray-800 dark:text-white"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path
							stroke="black"
							stroke-width="2"
							d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
						/>
					</svg>
				</div>
				{/* creatorId == currentUser?.id ? 'text-right' : '' */}
				<div className={props.message.userId === userId ? "text-right" : ""}>
					<p className="text-gray-800">
						{/* link '/profile/' +	(creatorId == currentUser?.id ? currentUser.id.toString : currentReceiver?.id.toString) */}

						{/* {{ creatorId == currentUser?.id ? currentUser.displayname : currentReceiver?.displayname }} */}
						{/* TODO jak dawno */}
					</p>
					{/* (creatorId == currentUser?.id ? 'text-right' : '') */}
					<p className="p-3">{author ? author.username : "Loading..."}</p>
				</div>
			</div>
			<div className={props.message.userId === userId ? "text-right break-words w-full" : "break-words w-full"}>
				{props.message.content}
			</div>
		</div>
	);
};

export default MessageTile;
