
import React, { useEffect, useState } from 'react'
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { Invite, User } from '@prisma/client';
import { AcceptInvite, DeclineInvite, DeleteInviteBySenderIdAndReceiverId, GetPendingInvitesForUser } from '../../Services/invitesAPICalls';
import { useCookies } from 'react-cookie';

import { Avatar, Typography } from '@mui/material';
import { toastSuccess } from '../../Services/toastify';

interface InviteWithUser extends Invite {
    sender: User
}

interface IProps {
    user: User
}

const PendingInvitesList: React.FC<IProps> = (props) => {

    const [invites, setInvites] = useState<InviteWithUser[]>([]);
    const fetchPendingInvites = async () => {
        try{
            // console.log("user to fetch pending invites", props.user);
            const invitesResponse = await GetPendingInvitesForUser(props.user.id ? props.user.id : 0);
            // console.log("invites and users list", invitesResponse);
            setInvites(invitesResponse as InviteWithUser[]);
            
            
        }catch(e){
            console.log(e);
        }
    }

        useEffect(() => {
        if(props.user){
            fetchPendingInvites();
        }
    }, [props])


    const acceptInvite = async (inviteId: number) => {
        try{
            const response = await AcceptInvite(inviteId);
            console.log("response", response);
            toastSuccess("Friend invite accepted");
            setInvites(invites.filter((invite) => invite.id !== inviteId));
        }catch(e){
            console.log(e);
        }
    }

    const declineInvite = async (inviteId: number) => {
        try{			
			await DeclineInvite(inviteId);
			toastSuccess("Friend invite canceled");
            setInvites(invites.filter((invite) => invite.id !== inviteId));
		}catch(e){
			console.log(e);
		}
    }


    return(
        <div className="bg-white shadow-md shadow-gra y-500 rounded-md mb-5 p-2 h-1/2 overflow-auto">
            {/* list of invites */}
            {[...invites].map((invite) => (
                <div className="bg-white shadow-md shadow-gra y-500 rounded-md mb-5 p-2 flex flex-row w-full">
                    {/* avatar and name */}
                    <div className='flex flex-row p-2 justify-start w-1/2'>
                        <Avatar alt={invite.sender.username ? invite.sender.username : ""} src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
                        <h3 className='ml-2'>{invite.sender.username}</h3>                        
                    </div>
                    {/* buttons */}
                    <div className='flex flex-row w-1/2'>
                        <button onClick={() => acceptInvite(invite.id)} className=" p-2 m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Accept
                        </button>
                        <button onClick={() => declineInvite(invite.id)} className="p-2 m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Reject
                        </button>
                    </div>    
                </div>  
            ))}            
        </div>
    )
}

export default PendingInvitesList