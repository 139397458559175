import type { Beer } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BeerState {
	beers: Beer[];
	init_status: true | false;
}

const initialState: BeerState = {
	init_status: true,
	beers: [],
};

export const beerSlice = createSlice({
	name: "beers",
	initialState,
	reducers: {
		setBeers(state, action: PayloadAction<Beer[]>) {
			if (state.init_status) {
				state.beers = action.payload;
				state.init_status = false;
			}
		},
		// CRUD bier
		addBeer(state, action: PayloadAction<Beer>) {
			state.beers.push(action.payload);
		},
		removeBeer(state, action: PayloadAction<Beer>) {
			state.beers = state.beers.filter((beer) => beer.id !== action.payload.id);
		},
		updateBeer(state, action: PayloadAction<Beer>) {
			let index = state.beers.findIndex((beer) => beer.id === action.payload.id);
			state.beers[index] = action.payload;
		},
	},
});

export const { setBeers, addBeer, removeBeer, updateBeer } = beerSlice.actions;
export default beerSlice.reducer;
