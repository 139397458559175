export const howLongAgo = (date: Date) => {
	let currDate = new Date();
	let difference: Date = new Date(currDate.getTime() - date.getTime());

	if (difference.getFullYear() - 1970) {
		if (difference.getFullYear() - 1970 > 1) {
			return `${difference.getFullYear()} years`;
		}
		return `${difference.getFullYear()} year`;
	}
	if (difference.getMonth()) {
		if (difference.getMonth() > 1) {
			return `${difference.getMonth()} months`;
		}
		return `${difference.getMonth()} month`;
	}
	if (difference.getTime() / (1000 * 60 * 60 * 24) >= 1) {
		if (difference.getTime() / (1000 * 60 * 60 * 24) >= 2) {
			return `${Math.floor(difference.getTime() / (1000 * 60 * 60 * 24))} days`;
		}
		return `${Math.floor(difference.getTime() / (1000 * 60 * 60 * 24))} day`;
	}
	if (difference.getHours() - 1) {
		if (difference.getHours() - 1 > 1) {
			return `${difference.getHours() - 1} hours`;
		}
		return `${difference.getHours() - 1} hour`;
	}
	if (difference.getMinutes()) {
		if (difference.getMinutes() > 1) {
			return `${difference.getMinutes()} minutes`;
		}
		return `${difference.getMinutes()} minute`;
	}
	if (difference.getSeconds() !== 1) {
		return `${difference.getSeconds()} seconds`;
	}
	return `${difference.getSeconds()} second`;
};


