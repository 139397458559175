import { Article, Place, User } from "@prisma/client";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle } from "../../reducers/articlesReducer";
import { RootState } from "../../store";
import { PostArticle } from "../../Services/articlesAPICalls";
import { toastSuccess, toastError } from "../../Services/toastify";
import { Status } from "@prisma/client";

import { PlaceType } from "@prisma/client";
import { addPlace } from "../../reducers/placesReducer";
import { PostPlace, PostPlaceWithLocation } from "../../Services/placesAPICalls";
import { setSearchedPlaces } from "../../reducers/placesSearchReducer";

const PlaceForm: React.FC = () => {
	// redux action dispatcher
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	// formData
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		type: PlaceType.PUB,
		open_time: "",
		address: "",
		lat: 0,
		lng: 0,
	});

	// changes the state of the formData on every input
	const handleChange = (event: any) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		// console.log(formData);
	};

	const submitPlace = async (event: any) => {
		event.preventDefault();
		//content validator
		// if(formData.name === ""){
		// 	toastError("Please fill out name for place");
		// 	return
		// }
		// if(formData.description === ""){
		// 	toastError("Please fill out description for place");
		// 	return
		// }
		// if(formData.open_time === ""){
		// 	toastError("Please fill out name for place");
		// 	return
		// }

		setIsLoading(true);
		try {
			const newPlace: Omit<Place, "id" | "createdAt" | "updatedAt" | "locationId"> = {
				name: formData.name,
				description: formData.description,
				type: formData.type,
				open_time: formData.open_time,
				address: formData.address,
			};
			const locationData: { lat: number; lng: number } = {
				lat: formData.lat,
				lng: formData.lng,
			};
			console.log("before post place", newPlace, locationData);
			const submitResponse = await PostPlaceWithLocation(newPlace, locationData);
			console.log("after post place", submitResponse);
			dispatch(addPlace(submitResponse));
			toastSuccess("Your place has been submitted");
			event.target.reset();
		} catch (error) {
			toastError("Error while submitting place");
			console.log(error);
		}
		setIsLoading(false);
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 m-2">
			<form onSubmit={submitPlace}>
				<p className="p-3">Place Form</p>
				<div className="grid grid-cols-2">
					{/* info */}
					<div className="">
						<div className="flex justify-end">
							<input
								type="text"
								id="name"
								name="name"
								onChange={handleChange}
								placeholder={`name`}
								maxLength={100}
								className=" grow p-3"
							></input>
							{/*  */}
						</div>
						<div className="flex justify-end">
							<input
								type="text"
								id="description"
								name="description"
								onChange={handleChange}
								placeholder="descrption"
								className="grow p-3"
							></input>
						</div>

						<div className="flex justify-end">
							<input
								type="text"
								id="open_time"
								name="open_time"
								onChange={handleChange}
								placeholder="open info"
								className="grow p-3"
							></input>
						</div>
						<div className="flex justify-end">
							<label htmlFor="type">Choose place type </label>
							<select id="type" name="type" onChange={handleChange}>
								{Object.keys(PlaceType).map((type: string) => (
									<option key={type} value={type}>
										{type}
									</option>
								))}
							</select>
						</div>
					</div>
					{/* location */}
					<div>
						<div className="flex justify-end">
							<input
								type="text"
								id="lat"
								name="lat"
								onChange={handleChange}
								placeholder="lat"
								className="grow p-3"
							></input>
						</div>
						<div className="flex justify-end">
							<input
								type="text"
								id="lng"
								name="lng"
								onChange={handleChange}
								placeholder="lng"
								className="grow p-3"
							></input>
						</div>
						<div className="flex justify-end">
							<input
								type="text"
								id="address"
								name="address"
								onChange={handleChange}
								placeholder="address"
								className="grow p-3"
							></input>
						</div>
					</div>
				</div>
				<div className="flex justify-end">
					<button
						type="submit"
						className={"bg-black text-white px-10 py-2 rounded-md m-2"}
						disabled={isLoading}
					>
						{" "}
						Add Place{" "}
					</button>
				</div>
			</form>
		</div>
	);
};

export default PlaceForm;
