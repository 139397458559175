import { AxiosResponse } from "axios";
import { NewUser } from "../../api/routes/user/user.service";
import { User } from "@prisma/client";
import axiosConfig from "../axiosConfig";

export const PostUser = async (user: NewUser): Promise<User> => {
	let data_to_send = JSON.stringify(user);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/auth/register",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response: AxiosResponse<User> = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PutUser = async (user: User): Promise<User> => {
	let data_to_send = JSON.stringify(user);

	let config = {
		method: "put",
		maxBodyLength: Infinity,
		url: `/users/${user.id}`,
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response: AxiosResponse<User> = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetUsers = async (): Promise<User[]> => {
	let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/users",
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response: AxiosResponse<User[]> = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetUserById = async (id: number): Promise<User> => {
	let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/users/${id}`,
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response: AxiosResponse<User> = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

//get users by id list
export const GetUsersByIds = async (ids: number[]): Promise<User[]> => {
	const idsArray = JSON.stringify(ids);
	let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/users/list/${idsArray}`,
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response: AxiosResponse<User[]> = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const getMutualFriends = async (id1: number, id2: number): Promise<number> => {
	let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/users/mutualFriends/${id1}/${id2}`,
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response: AxiosResponse<number> = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
