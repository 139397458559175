import { Place } from "@prisma/client";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setSelectedPlace } from "../../reducers/placesReducer";
import { Rating, Typography } from "@mui/material";
import PlaceInfoDialog from "./PlaceInfoDialog";

interface IProps {
    place: Place
}

const PlaceTile: React.FC<{ place: Place }> = (props: IProps) => {
    const place: Place = props.place
    const selPlace: null | Place = useSelector((state: RootState) => state.places.selectedPlace);
    const dispatch = useDispatch();
    const selectPlace = () => {
      dispatch(setSelectedPlace(place));
    }
    

    return (
      <>
        {
          selPlace?.id === place.id ? 
          <div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-4 m-2">      
            <div  onClick={selectPlace}>
            <div className="flex justify-between">
                <p className="font-bold text-lg mb-2">{place.name}</p>
                <p className=" m-2 p-2"><Rating
                                            name="read-only"
                                            precision={0.1}
                                            value={place.avgStars} 
                                            readOnly
                                        /></p>
              </div>
              
              <p className="text-gray-700 mb-2">{place.description}</p>
              <div className="flex flex-wrap">
                <p className="w-full md:w-1/2">Place Type: {place.type}</p>
                <p className="w-full md:w-1/2">Location: {place.address}</p>
                <p className="w-full md:w-1/2">Open Time: {place.open_time}</p>
                
              </div>
              
            </div>
            <div className="flex justify-end">
              {/* <button onClick={() => showPlaceInfo(place?.id)} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">See more info</button> */}
              <PlaceInfoDialog place={place} />
            </div>
            
          </div>
          :
          <div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-4 m-2">      
            <div  onClick={selectPlace}>
              <div className="flex justify-between">
                <p className="font-bold text-lg mb-2">{place.name}</p>
                <p className=" m-2 p-2"><Rating
                                            name="read-only"
                                            precision={0.1}
                                            value={place.avgStars} 
                                            readOnly
                                        /></p>
              </div>
              
              <p className="text-gray-700 mb-2">{place.description}</p>
              <div className="flex flex-wrap">
                <p className="w-full md:w-1/2">Place Type: {place.type}</p>
                <p className="w-full md:w-1/2">Location: {place.address}</p>
                <p className="w-full md:w-1/2">Open Time: {place.open_time}</p>
                
              </div>
              
            </div>
            <div className="flex justify-end">
              {/* <button onClick={() => showPlaceInfo(place?.id)} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">See more info</button> */}
              <PlaceInfoDialog place={place} />
            </div>
            
          </div>
          }
      </>  
    );
}

export default PlaceTile;