import axios from "axios";

var host = "http://localhost:2137";

if (!process.env.REACT_APP_PORT_API) {
	console.error("w .env należy ustalić port do API w zmiennej REACT_APP_PORT_API");
	host = "http://localhost:" + 2137;
} else {
	host = "http://localhost:" + process.env.REACT_APP_PORT_API;
}

if (process.env.REACT_APP_HOST_API && process.env.REACT_APP_PORT_API) {
	host = process.env.REACT_APP_HOST_API + ":" + process.env.REACT_APP_PORT_API;
}

console.log("host", host);

const axiosConfig = axios.create({
	baseURL: host,
});

export default axiosConfig;
