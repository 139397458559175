import "../../stylesheets/footer.css";
import React from "react";
import logo from "../../images/logo.png";

const Footer: React.FC = () => {
	return (
		<div className="row-auto columns-2 grow bg-slate-900 text-slate-500">
			<div className="col">
				<a className="navbar-brand" href="/" style={{ textDecoration: "none" }}>
					<img src={logo} width={"125px"} alt="Logo" />
				</a>
				<div id="cp">
					<p>© 2024 BeerHub®. All rights reserved.</p>
				</div>
			</div>
			<div className="col text-right p-2">
				<p>Kontakt</p>
				<p>Facebook Instagram Twitter</p>
			</div>
		</div>
	);
};

export default Footer;
