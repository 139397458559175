import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetSearch } from "../../reducers/placesReducer";
import { RootState } from "../../store";
import { User } from "@prisma/client";
import UserListFilter from "./UserListFilter";
import UserListFiltered from "./UserListFiltered";

interface IProps {
	action(): any;
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ChooseUserDialog: React.FC<IProps> = (props: IProps) => {
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const selUser: null | User = useSelector((state: RootState) => state.users.selectedUser);
	const handleClickOpen = () => {
		dispatch(resetSearch());
		setOpen(true);
	};
	const handleClose = () => {
		dispatch(resetSearch());
		setOpen(false);
	};

	const handleAgree = () => {
		setOpen(false);
		props.action();
	};

	return (
		<div className="flex flex-row h-full">
			{/* Button to trigger the opening of the dialog */}

			<Button
				variant="text"
				onClick={handleClickOpen}
				className="flex hoverPointer text-left w-full p-2 gap-2 hover:shadow-gray-500 hover:shadow-sm active:shadow-inner"
			>
				<svg
					className="w-6 h-6 text-gray-800 dark:text-white"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						stroke="black"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="m10.8 17.8-6.4 2.1 2.1-6.4m4.3 4.3L19 9a3 3 0 0 0-4-4l-8.4 8.6m4.3 4.3-4.3-4.3m2.1 2.1L15 9.1m-2.1-2 4.2 4.2"
					/>
				</svg>
				Add User to chat
			</Button>

			{/* Dialog that is displayed if the state open is true */}
			<Dialog
				className="p-10"
				open={open}
				maxWidth="md"
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				TransitionComponent={Transition}
			>
				<div className="p-5 h-full">
					{/* Info */}
					<div className="mt-5 grid-cols-2 gap-4 h-full">
						{/* List */}
						<UserListFilter />
						<UserListFiltered />
					</div>
				</div>
				<DialogActions>
					<Toolbar>
						<Button autoFocus color="inherit" onClick={handleAgree} disabled={selUser === null}>
							Choose Selected
						</Button>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Cancel
						</Button>
					</Toolbar>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ChooseUserDialog;
