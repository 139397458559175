import axiosConfig from "../axiosConfig";
import { ArticleReaction } from "@prisma/client";

export const GetArticleReactions = async (): Promise<ArticleReaction[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/article_reactions",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PostArticleReaction = async (
	article_reaction: Omit<ArticleReaction, "id" | "createdAt" | "updatedAt">
): Promise<ArticleReaction> => {
	let data_to_send = JSON.stringify(article_reaction);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/article_reactions",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const DeleteArticleReaction = async (id: number): Promise<ArticleReaction> => {
	let config = {
		method: "delete",
		maxBodyLength: Infinity,
		url: `/article_reactions/${id}`,
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
