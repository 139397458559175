import type { Brewery } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BreweryState {
	breweries: Brewery[];
	init_status: true | false;
}

const initialState: BreweryState = {
	init_status: true,
	breweries: [],
};

export const brewerySlice = createSlice({
	name: "breweries",
	initialState,
	reducers: {
		setBreweries(state, action: PayloadAction<Brewery[]>) {
			if (state.init_status) {
				state.breweries = action.payload;
				state.init_status = false;
			}
		},
		// CRUD bier
		addBrewery(state, action: PayloadAction<Brewery>) {
			state.breweries.push(action.payload);
		},
		removeBrewery(state, action: PayloadAction<Brewery>) {
			state.breweries = state.breweries.filter((brewery) => brewery.id !== action.payload.id);
		},
		updateBrewery(state, action: PayloadAction<Brewery>) {
			let index = state.breweries.findIndex((brewery) => brewery.id === action.payload.id);
			state.breweries[index] = action.payload;
		},
	},
});

export const { setBreweries, addBrewery, removeBrewery, updateBrewery } = brewerySlice.actions;
export default brewerySlice.reducer;
