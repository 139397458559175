import React, { useEffect } from "react";

import { Beer, NewBeer } from "../../../api/routes/beer/beer.service";
import { useDispatch, useSelector } from "react-redux";
import { PostBeer } from "../../Services/beersAPICalls";
import { toast } from "react-toastify";
import { toastError, toastSuccess } from "../../Services/toastify";
import { addBeer } from "../../reducers/beersReducer";
import { BeerType, Brewery, HopComponent } from "@prisma/client";
import { RootState } from "../../store";

const BeerFormTile: React.FC = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = React.useState(false);
	const [error, setError] = React.useState<string>("");
	// select zwraca id w postaci stringa, tez nie rozumiem jak cos
	const [formData, setFormData] = React.useState({
		name: "",
		typeId: "",
		breweryId: "",
		hopComponentId: "",
	});

	let breweries: Brewery[] = useSelector((state: RootState) => state.breweries.breweries);
	let hop_components: HopComponent[] = useSelector((state: RootState) => state.hop_components.hop_components);
	let beer_types: BeerType[] = useSelector((state: RootState) => state.beer_types.beer_types);

	const handleChange = (event: any) => {
		setFormData((prevData) => ({
			...prevData,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		// name validator
		let name = "";
		if (formData.name) {
			name = formData.name;
			console.log("beer name", name);
		} else {
			toastError("Please fill out name for beer");
			return;
		}

		let typeId = 0;
		if (formData.typeId) {
			typeId = parseInt(formData.typeId);
		} else {
			toastError("Please select beer type");
			return;
		}

		let breweryId = 0;
		if (formData.breweryId) {
			breweryId = parseInt(formData.breweryId);
		} else {
			toastError("Please select brewery");
			return;
		}

		let hopComponentId = 0;
		if (formData.hopComponentId) {
			hopComponentId = parseInt(formData.hopComponentId);
		} else {
			toastError("Please select hop component");
			return;
		}

		setIsLoading(true);
		try {
			const newBeer: Omit<Beer, "id" | "createdAt" | "updatedAt"> = {
				name,
				typeId: typeId,
				hopComponentId: hopComponentId,
				breweryId: breweryId,
			};
			const submitResponse = await PostBeer(newBeer);
			dispatch(addBeer(submitResponse));
			console.log("after post beer", submitResponse);
			toastSuccess("Your beer has been submitted");
			event.target.reset();
		} catch (error) {
			toastError("Error while submitting beer");
			console.log("Beer error", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<form onSubmit={handleSubmit}>
				<label>
					Name:
					<input type="text" name="name" value={formData.name} onChange={handleChange} />
				</label>
				<br />
				<label>
					Type:
					<select id="typeId" name="typeId" onChange={handleChange}>
						<option value={0}>---------</option>
						{beer_types.map((beer_type: BeerType) => (
							<option key={beer_type.id} value={beer_type.id}>
								{beer_type.name}
							</option>
						))}
					</select>
				</label>
				<br />
				<label>
					Brewery:
					<select id="breweryId" name="breweryId" onChange={handleChange}>
						<option value={0}>---------</option>
						{breweries.map((brewery: Brewery) => (
							<option key={brewery.id} value={brewery.id}>
								{brewery.name}
							</option>
						))}
					</select>
				</label>
				<br />
				<label>
					Hop:
					<select id="hopComponentId" name="hopComponentId" onChange={handleChange}>
						<option value={0}>---------</option>
						{hop_components.map((hop_component: HopComponent) => (
							<option key={hop_component.id} value={hop_component.id}>
								{hop_component.name}
							</option>
						))}
					</select>
				</label>
				<br />
				<button type="submit" onClick={handleSubmit} disabled={isLoading}>
					Submit
				</button>
			</form>
		</div>
	);
};

export default BeerFormTile;
