import React from "react";

const BlankArticleTile: React.FC = () => {
	return (
		
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<p className="text-center text-gray-500">There's nothing here!</p>
		</div>
	);
};

export default BlankArticleTile;


