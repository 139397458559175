import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { NewUser } from "../../../api/routes/user/user.service";
import { PostUser } from "../../Services/userAPICalls";
import { toastError, toastSuccess } from "../../Services/toastify";

const RegisterFormTile: React.FC = () => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		username: "",
		password: "",
		confirmPassword: "",
		email: "",
		submitString: "Register",
	});

	const handleChange = (event: any) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		if (!emailRegex.test(formData.email)) {
			toastError("Bad email");
			return;
		}

		// if (!passwordRegex.test(formData.password)) {
		// 	toastError(
		// 		"Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
		// 	);
		// 	return;
		// }

		// fast validation
		if (formData.username.length < 3) {
			toastError("Username must be at least 4 characters");
			return;
		}
		if (formData.email.length < 3) {
			toastError("Email must be at least 4 characters");
			return;
		}
		if (formData.password.length < 3) {
			toastError("Password must be at least 3 characters");
			return;
		}

		if (formData.password !== formData.confirmPassword) {
			toastError("Passwords do not match");
			return;
		}

		const userSend: NewUser = {
			username: formData.username,
			password: formData.password,
			email: formData.email,
		};

		// posting new user, profile and details
		// TODO maybe jakaś weyfikacja maili?
		try {
			const response = await PostUser(userSend);
			console.log("Register Response: ", response);
			toastSuccess("Registration successful, redirecting to login");
			navigate("/");
		} catch (error) {
			toastError("Registration failed");
			console.log("Registration error: ", error);
		}
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 w-full">
			<div className="block gap-3">
				<p className="ml-auto mr-auto text-sm text-gray-400">Register</p>
				<form>
					<div className="p-2 ml-2">Username:</div>
					<input
						className="p-2 m-2 border rounded-md shadow-sm shadow-gray-300"
						type="text"
						placeholder="Username"
						name="username"
						value={formData.username}
						onChange={handleChange}
					/>
					<br />
					<div className="p-2 ml-2">E-mail:</div>

					<input
						className="p-2 m-2 border rounded-md shadow-sm shadow-gray-300"
						type="text"
						placeholder="Email"
						name="email"
						value={formData.email}
						onChange={handleChange}
					/>
					<br />
					<div className="p-2 ml-2">Password:</div>
					<input
						className="p-2 m-2 border rounded-md shadow-sm shadow-gray-300"
						type="password"
						placeholder="Password"
						name="password"
						value={formData.password}
						onChange={handleChange}
					/>
					<br />
					<div className="p-2 ml-2">Confirm Password:</div>
					<input
						className="p-2 m-2 border rounded-md shadow-sm shadow-gray-300"
						type="password"
						placeholder="Re-enter your password"
						name="confirmPassword"
						value={formData.confirmPassword}
						onChange={handleChange}
					/>
					<div className="grow"></div>
					<button
						className="bg-black text-white px-10 py-2 rounded-md m-2 shadow-sm shadow-gray-500 active:shadow-inner"
						type="submit"
						onClick={handleSubmit}
					>
						Register
					</button>
				</form>
			</div>
		</div>
	);
};

export default RegisterFormTile;
