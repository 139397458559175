import { User } from '@prisma/client';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';  

const CalendarTile: React.FC = () => {
	const params = useParams();
	const userId: number = Number(params.userId);
	// const profile = useSelector((state: RootState) => state.profiles.profiles.find((profile) => profile.id === userId));
	const user: User | undefined = useSelector((state: RootState) =>
		state.users.users.find((user) => user.id === userId)
	);
    type ValuePiece = Date|null;
    type Value = ValuePiece | [ValuePiece, ValuePiece];

	// console.log("userId", userId);
	// console.log("user", user);
    const [value, setValue] = useState<Value>(new Date());

    const onDataClick = (value, event) =>  {
        console.log("value", event.target.value);
        setValue(new Date());
    }

    return(
        
            <div className="bg-white shadow-md shadow-gra y-500 rounded-md m-auto p-4">
                <div className='flex justify-center items-center'>
                    <Calendar onChange={setValue} onClickDay={onDataClick} value={value}/>
                </div>
                
                <br></br>
                 
            </div>
            
        
    )

}

export default CalendarTile;