import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./reducers/usersReducer";
import articleReducer from "./reducers/articlesReducer";
import currentUserReducer from "./reducers/currentUserReducer";
import placesReducer from "./reducers/placesReducer";
import placesSearchReducer from "./reducers/placesSearchReducer";
import chatsReducer from "./reducers/chatsReducer";
import chatUsersReducer from "./reducers/chatUsersReducer";
import messagesReducer from "./reducers/messagesReducer";
import locationReducer from "./reducers/locationsReducer";
import hopComponentsReducer from "./reducers/hopComponentsReducer";
import beerTypesReducer from "./reducers/beerTypesReducer";
import breweriesReducer from "./reducers/breweriesReducer";
import beersReducer from "./reducers/beersReducer";
import articleCommentsReducer from "./reducers/articleCommentsReducer";
import articleReactionsReducer from "./reducers/articleReactionsReducer";
import articleCommentReactionsReducer from "./reducers/articleCommentReactionsReducer";
import beerOpinionsReducer from "./reducers/beerOpinionsReducer";
// import authReducer from "./reducers/authReducer";

export const store = configureStore({
	reducer: {
		current_user: currentUserReducer,
		users: usersReducer,
		articles: articleReducer,
		article_comments: articleCommentsReducer,
		article_reactions: articleReactionsReducer,
		article_comment_reactions: articleCommentReactionsReducer,
		places: placesReducer,
		chats: chatsReducer,
		chat_users: chatUsersReducer,
		messages: messagesReducer,
		locations: locationReducer,
		beers: beersReducer,
		beer_types: beerTypesReducer,
		beer_opinions: beerOpinionsReducer,
		breweries: breweriesReducer,
		hop_components: hopComponentsReducer,
		// invites: invitesReducer,
		// auth: authReducer
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
