import type { ChatUser, Message } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

interface MessageState {
	messages: Message[];
	init_status: true | false;
}

const initialState: MessageState = {
	init_status: true,
	messages: [],
};

type AddMessage = {
	message: Message;
	chatId: number;
	chat_users: ChatUser[];
	socket: Socket;
};

export const messageSlice = createSlice({
	name: "messages",
	initialState,
	reducers: {
		// setMessages populates the list
		setMessages(state, action: PayloadAction<Message[]>) {
			if (state.init_status) {
				state.messages = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for messages
		addMessage(state, action: PayloadAction<Message>) {
			state.messages.push(action.payload);
		},
		removeMessage(state, action: PayloadAction<number>) {
			state.messages = state.messages.filter((message) => message.id !== action.payload);
		},
		updateMessage(state, action: PayloadAction<Message>) {
			let index = state.messages.findIndex((message) => message.id === action.payload.id);
			state.messages[index] = action.payload;
		},
	},
});

export const { addMessage, removeMessage, updateMessage, setMessages } = messageSlice.actions;
export default messageSlice.reducer;
export type { AddMessage };
