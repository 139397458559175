import type { BeerType } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BeerTypeState {
	beer_types: BeerType[];
	init_status: true | false;
}

const initialState: BeerTypeState = {
	init_status: true,
	beer_types: [],
};

export const beerTypeSlice = createSlice({
	name: "beer_types",
	initialState,
	reducers: {
		// setBeerTypes populates the list
		setBeerTypes(state, action: PayloadAction<BeerType[]>) {
			if (state.init_status) {
				state.beer_types = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for beerTypes
		addBeerType(state, action: PayloadAction<BeerType>) {
			state.beer_types.push(action.payload);
		},
		removeBeerType(state, action: PayloadAction<number>) {
			state.beer_types = state.beer_types.filter((beerType) => beerType.id !== action.payload);
		},
		updateBeerType(state, action: PayloadAction<BeerType>) {
			let index = state.beer_types.findIndex((beerType) => beerType.id === action.payload.id);
			state.beer_types[index] = action.payload;
		},
	},
});

export const { addBeerType, removeBeerType, updateBeerType, setBeerTypes } = beerTypeSlice.actions;
export default beerTypeSlice.reducer;
