import io from "socket.io-client";

let address = "";

if (process.env.REACT_APP_HOST_API) {
	address += process.env.REACT_APP_HOST_API;
} else {
	address += "http://localhost";
}

if (process.env.REACT_APP_PORT_API) {
	address += ":" + process.env.REACT_APP_PORT_API;
} else {
	address += ":2137";
}

export const socket = io(address);
