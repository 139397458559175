import axiosConfig from "../axiosConfig";
import { Location, Place } from "@prisma/client";

export const GetPlaces = async (): Promise<Place[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/places",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetPlaceById = async (id: number): Promise<Place> => {
	let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/places/${id}`,
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PostPlaceWithLocation = async (
	newPlace: Omit<Place, "id" | "createdAt" | "updatedAt" | "locationId">,
	locationData: { lat: number; lng: number }
): Promise<Place> => {
	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/places",
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			name: newPlace.name,
			description: newPlace.description,
			type: newPlace.type,
			open_time: newPlace.open_time,
			address: newPlace.address,
			location_lat: locationData.lat,
			location_long: locationData.lng,
		},
	};

	try {
		console.log("config", config);
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong while posting place with location");
	}
};

export const PostPlace = async (newPlace: Omit<Place, "id" | "createdAt" | "updatedAt">): Promise<Place> => {
	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/places",
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			name: newPlace.name,
			description: newPlace.description,
			type: newPlace.type,
			open_time: newPlace.open_time,
			address: newPlace.address,
			locationId: newPlace.locationId,
		},
	};

	try {
		console.log("config", config);
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong while posting place");
	}
};

export const getPlaceLocation = async (placeId: number): Promise<Location> => {
	let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/locations/${placeId}`,
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const getPlacesLocations = async (places: Place[]): Promise<Location[]> => {
	const locations: Location[] = [];
	try {
		places.forEach(async (place) => {
			let config = {
				method: "get",
				maxBodyLength: Infinity,
				url: `/locations/${place.locationId}`,
				headers: {
					"Content-Type": "application/json",
				},
			};
			locations.push((await axiosConfig.request(config)).data);
		});
		console.log("locations", locations);

		return locations;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
