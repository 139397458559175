
import React, { useEffect, useState } from 'react'
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { User } from '@prisma/client';
import { ListUserFriends } from '../../Services/invitesAPICalls';
import { Avatar, Typography } from '@mui/material';


interface IProps {
    user: User
}

const UserFriendsList: React.FC<IProps> = (props) => {

    const current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);

    const [friends, setFriends] = useState<User[]>([]);

    const fetchUserFriends = async () => {
        try{
            const friendsResponse = await ListUserFriends(props.user.id ? props.user.id : 0);
            // console.log("friends", friendsResponse);
            setFriends(friendsResponse);
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        if(props.user){
            fetchUserFriends();
        }
        // console.log("friends useEffects");
    }, [props])


    return(
        <div className="bg-white shadow-md shadow-gra y-500 rounded-md m-2 p-2">
            {/* List of Friends with profile urls on click */}
            {
                [...friends].map((friend) => (
                    (
                        <div className='cursor-pointer grow'>
                            <div className='flex flex-row p-2 w-full '  key={friend.id} onClick={() => window.location.href = `/profile/${friend.id}`}>
                                {/* avatar and name */}
                                <Avatar alt={friend.username ? friend.username : ""} src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
                                <div className='grow w-full m-2'>  
                                <Typography className='m-auto'>{friend.username}</Typography>
                                </div>
                            </div>
                        </div>
                    )
                ))
            }

        </div>
    )
};

export default UserFriendsList