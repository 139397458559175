import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store";
import { Article, Status, User } from "@prisma/client";
import ArticleList from "../components/Articles/ArticleList";
import ProfileLeftTile from "../components/Profiles/ProfileLeftTile";
import CalendarTile from "../components/Profiles/CalendarTile";
import PendingInvitesList from "../components/Profiles/PendingInvitesList";
import UserFriendsList from "../components/Profiles/UserFriendsList";
import { useParams } from "react-router-dom";


// pozyskanie id ze sciezki, np. /profile/1
// useSelector do pobrania profilu ze store

const Profile: React.FC = () => {
	const params = useParams();
	const userId: number = Number(params.userId);
	// current profile user
	const user: User | undefined = useSelector((state: RootState) =>
		state.users.users.find((user) => user.id === userId)
	);
	// logged user
	let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);
	// boolean value is this profile of logged user
	const isCurrentUserProfile:Boolean = user?.id === current_user?.id;

	// 
	let articles: Article[] = useSelector((state: RootState) =>
		state.articles.articles.filter((article) => article.status === Status.PUBLIC && article.authorId === user?.id)
	);

	

	return (
		<div className="flex mt-4 grow px-20 mx-auto gap-6 fixed h-4/5 w-full grow">
			<div className="w-1/4 h-3/4 grow">
				<ProfileLeftTile />
				<CalendarTile/>
			</div>
			<div className="w-1/2 overflow-y-auto h-full no-scrollbar grow">
				<ArticleList articleList={articles} />
				{/* <Tile content="e kolezko, takiego w bazie nie posiadamy" /> */}
			</div>
			<div className="w-1/4 h-3/4 grow" >
				<div className="">
				<h1 className="p-2 m-2 text-2xl">Friends</h1>
				<UserFriendsList user={user as User}/>
				</div>
				<div>
				<h1 className="m-2 p-2 text-2xl">Invites</h1>
				<PendingInvitesList user={user as User}/>
				</div>
				
			</div>
		</div>
	);
	// return (
	// 	<div className="flex mt-4 grow px-20 mx-auto gap-6 h-screen">
	// 	  <div className="w-1/4 fixed h-full left-0 top-4">
	// 		<ProfileLeftTile />
	// 		<CalendarTile />
	// 	  </div>
	// 	  <div className="w-1/2 mx-1/4 overflow-y-auto h-full mt-4">
	// 		<ArticleList articleList={articles} />
	// 		{/* <Tile content="e kolezko, takiego w bazie nie posiadamy" /> */}
	// 	  </div>
	// 	  <div className="w-1/4 fixed h-full right-0 top-4">
	// 		<Typography variant="h5">User Friends</Typography>
	// 		<UserFriendsList user={user as User} />
	// 		<Typography variant="h5">Pending Invites</Typography>
	// 		<PendingInvitesList user={user as User} />
	// 	  </div>
	// 	</div>
	//   );
};

export default Profile;
