import React from "react";

import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "../../Services/toastify";
import { BeerType } from "@prisma/client";
import { PostBeerType } from "../../Services/beerTypesAPICalls";
import { addBeerType } from "../../reducers/beerTypesReducer";

const BeerTypeFormTile: React.FC = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = React.useState(false);
	const [formData, setFormData] = React.useState({
		name: "",
		bio: "",
	});

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		let name = "";
		if (formData.name) {
			name = formData.name;
		} else {
			toastError("Please fill out name for beer type");
			return;
		}

		let bio = "";
		if (formData.bio) {
			bio = formData.bio;
		} else {
			toastError("Please fill out bio for beer type");
			return;
		}

		setIsLoading(true);
		try {
			const newBeerType: Omit<BeerType, "id" | "createdAt" | "updatedAt"> = {
				name,
				bio,
			};
			const submitResponse = await PostBeerType(newBeerType);
			dispatch(addBeerType(submitResponse));
			console.log("after post beer type", submitResponse);
			toastSuccess("Your beer type has been submitted");
			event.target.reset();
		} catch (error) {
			toastError("Error while submitting beer type");
			console.log("Beer type error", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<p>BeerTypeFormTile</p>
			<form onSubmit={handleSubmit}>
				<label>
					Name:
					<input type="text" name="name" value={formData.name} />
				</label>
				<br />
				<label>
					Bio:
					<input type="textarea" name="bio" value={formData.name} />
				</label>
				<br />
				<button type="submit" onClick={handleSubmit} disabled={isLoading}>
					Submit
				</button>
			</form>
		</div>
	);
};

export default BeerTypeFormTile;
