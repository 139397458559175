import type { User } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CurrentUserState {
	current_user: User | null;
	init_status: true | false;
}

const initialState: CurrentUserState = {
	init_status: true,
	current_user: null,
};

export const currentUserSlice = createSlice({
	name: "current_user",
	initialState,
	reducers: {
		// setCurrentUser populates the list of users once at the launch of app by client
		// no w tym przypadku nie
		setCurrentUser(state, action: PayloadAction<User>) {
			if (state.init_status) {
				state.current_user = action.payload;
				state.init_status = false;
			}
		},
		removeCurrentUser(state) {
			state.current_user = null;
		},
		updateCurrentUser(state, action: PayloadAction<User>) {
			state.current_user = action.payload;
		},
	},
});

export const { setCurrentUser, removeCurrentUser, updateCurrentUser } = currentUserSlice.actions;
export default currentUserSlice.reducer;
