import axiosConfig from "../axiosConfig";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

/**
 * Posts user login information to the server.
 *
 * @param {string} email - The user's email address
 * @param {string} password - The user's password
 * @return {Promise} The response from the server after attempting to log in
 */

export const PostLogin = async (email: string, password: string) => {

    let data_to_send = JSON.stringify({
        "email": email,
        "password": password
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/auth/login',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data_to_send
    };
    
    try {
        const response = axiosConfig.request(config);
        return response;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}
 
/**
 * Logs the user out by removing the token and id cookies, then navigates to the home page.
 *
 */
// export const Logout = () => {
//     const navigate = useNavigate();
//     Cookies.remove("token");
//     Cookies.remove("id");
//     navigate("/");
// }



