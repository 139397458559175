import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import {
	Article,
	ArticleComment,
	ArticleCommentReaction,
	ArticleReaction,
	Beer,
	BeerType,
	Brewery,
	HopComponent,
} from "@prisma/client";
import { GetArticles } from "../Services/articlesAPICalls";
import { setArticles } from "../reducers/articlesReducer";
import { setCurrentUser, removeCurrentUser } from "../reducers/currentUserReducer";
import { useCookies } from "react-cookie";
import { Chat, ChatUser, Location, Message, Place, User } from "@prisma/client";
import { GetUserById, GetUsers } from "../Services/userAPICalls";
import { setUsers } from "../reducers/usersReducer";
import { setPlaces } from "../reducers/placesReducer";
import { GetPlaces } from "../Services/placesAPICalls";
import { addChat, setChats } from "../reducers/chatsReducer";
import { GetChats } from "../Services/chatsAPICalls";
import { GetChatUsers } from "../Services/chatUsersAPICalls";
import { addChatUser, setChatUsers } from "../reducers/chatUsersReducer";
import { GetMessages } from "../Services/messagesAPICalls";
import { addMessage, setMessages } from "../reducers/messagesReducer";
import { setLocations } from "../reducers/locationsReducer";
import { GetLocations } from "../Services/locationAPICalls";
import { RootState } from "../store";
import { setHopComponents } from "../reducers/hopComponentsReducer";
import { GetBeerTypes } from "../Services/beerTypesAPICalls";
import { GetBeers } from "../Services/beersAPICalls";
import { GetBreweries } from "../Services/breweriesAPICalls";
import { GetHopComponents } from "../Services/hopComponentsAPICalls";
import { setBeerTypes } from "../reducers/beerTypesReducer";
import { setBeers } from "../reducers/beersReducer";
import { setBreweries } from "../reducers/breweriesReducer";
import { addArticleComment, setArticleComments } from "../reducers/articleCommentsReducer";
import { GetArticleComments } from "../Services/articleCommentsAPICalls";
import { GetArticleReactions } from "../Services/articleReactionsAPICalls";
import { setArticleReactions } from "../reducers/articleReactionsReducer";
import { GetArticleCommentReactions } from "../Services/articleCommentReactionsAPICalls";
import { setArticleCommentReactions } from "../reducers/articleCommentReactionsReducer";
import { AddMessage } from "../reducers/messagesReducer";
import { NotifyMessage } from "./BasicLayout";
import { socket } from "../socket";

const ReduxSetter = () => {
	// here comes basic reads from database to redux

	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
	const cookieId = cookies.id;

	// redux articles setter
	useEffect(() => {
		fetchArticles();
		fetchArticleComments();
		fetchArticleReactions();
		fetchArticleCommentReactions();
		fetchUsers();
		fetchChats();
		fetchChatUsers();
		fetchPlaces();
		fetchLocations();
		fetchMessages();
		fetchBeers();
		fetchBeerTypes();
		fetchBreweries();
		fetchHopComponents();
	}, []);

	const fetchArticles = async () => {
		try {
			const articles: Article[] = await GetArticles();
			dispatch(setArticles(articles));
			console.log("article fetching reposnse ", articles);
		} catch (error) {
			console.log("error fetching articles", error);
		}
	};
	const fetchArticleComments = async () => {
		try {
			const article_comments: ArticleComment[] = await GetArticleComments();
			dispatch(setArticleComments(article_comments));
			console.log("article comments fetching reposnse ", article_comments);
		} catch (error) {
			console.log("error fetching article comments", error);
		}
	};
	const fetchArticleReactions = async () => {
		try {
			const article_reactions: ArticleReaction[] = await GetArticleReactions();
			dispatch(setArticleReactions(article_reactions));
			console.log("article reactions fetching reposnse ", article_reactions);
		} catch (error) {
			console.log("error fetching article reactions", error);
		}
	};
	const fetchArticleCommentReactions = async () => {
		try {
			const article_comment_reactions: ArticleCommentReaction[] = await GetArticleCommentReactions();
			dispatch(setArticleCommentReactions(article_comment_reactions));
			console.log("article comment reactions fetching response");
		} catch (error) {
			console.log("error fetching article commnet reactions", error);
		}
	};
	const fetchUsers = async () => {
		try {
			const users: User[] = await GetUsers();
			dispatch(setUsers(users));
			console.log("users fetching reposnse ", users);
		} catch (error) {
			console.log("error fetching users", error);
		}
	};
	const fetchChats = async () => {
		try {
			const chats: Chat[] = await GetChats();
			dispatch(setChats(chats));
			console.log("chats fetching reposnse ", chats);
		} catch (error) {
			console.log("error fetching chats", error);
		}
	};
	const fetchChatUsers = async () => {
		try {
			const chat_users: ChatUser[] = await GetChatUsers();
			dispatch(setChatUsers(chat_users));
			console.log("chat_users fetching reposnse ", chat_users);
		} catch (error) {
			console.log("error fetching chats", error);
		}
	};
	const fetchPlaces = async () => {
		try {
			const places: Place[] = await GetPlaces();
			dispatch(setPlaces(places));
			console.log("places fetching reposnse ", places);
		} catch (error) {
			console.log("error fetching places", error);
		}
	};
	const fetchLocations = async () => {
		try {
			const locations: Location[] = await GetLocations();
			dispatch(setLocations(locations));
			console.log("locations fetching reposnse ", locations);
		} catch (error) {
			console.log("error fetching places", error);
		}
	};
	const fetchMessages = async () => {
		try {
			const messages: Message[] = await GetMessages();
			dispatch(setMessages(messages));
			console.log("messages fetching reposnse ", messages);
		} catch (error) {
			console.log("error fetching messages", error);
		}
	};
	const fetchBeers = async () => {
		try {
			const beers: Beer[] = await GetBeers();
			dispatch(setBeers(beers));
			console.log("beers fetching reposnse ", beers);
		} catch (error) {
			console.log("error fetching beers", error);
		}
	};
	const fetchBeerTypes = async () => {
		try {
			const beer_types: BeerType[] = await GetBeerTypes();
			dispatch(setBeerTypes(beer_types));
			console.log("beer types fetching reposnse ", beer_types);
		} catch (error) {
			console.log("error fetching beer types", error);
		}
	};
	const fetchBreweries = async () => {
		try {
			const breweries: Brewery[] = await GetBreweries();
			dispatch(setBreweries(breweries));
			console.log("breweries fetching reposnse ", breweries);
		} catch (error) {
			console.log("error fetching breweries", error);
		}
	};
	const fetchHopComponents = async () => {
		try {
			const hop_components: HopComponent[] = await GetHopComponents();
			dispatch(setHopComponents(hop_components));
			console.log("hop components fetching reposnse ", hop_components);
		} catch (error) {
			console.log("error fetching hop components", error);
		}
	};

	// redux current user setter based on cookies
	// nie sprawdza jak legitny jest token :kekw:
	// wystarczy id zmienic xdd
	useEffect(() => {
		const fetchLoggedUser = async () => {
			try {
				const current_user: User | null = await GetUserById(cookieId);
				dispatch(setCurrentUser(current_user));
			} catch (error) {
				console.log("error fetching current user", error);
				dispatch(removeCurrentUser());
			}
		};

		if (cookieId) {
			fetchLoggedUser();
		}
	}, [cookieId]);

	//socket.io update wiadomosci
	useEffect(() => {
		socket.on("message", (message: NotifyMessage) => {
			console.log(message);
			dispatch(addMessage(message.message));
			console.log("emitting...", socket.emit("notify_start", message));
		});
		socket.on("chat", (chat: Chat) => {
			console.log(chat);
			dispatch(addChat(chat));
		});
		socket.on("chat_user", (chat_user: ChatUser) => {
			console.log(chat_user);
			dispatch(addChatUser(chat_user));
		});
		socket.on("article_comment", (article_comment: ArticleComment) => {
			console.log(article_comment);
			dispatch(addArticleComment(article_comment));
		});
	}, []);

	return (
		<>
			<Outlet />
		</>
	);
};

export default ReduxSetter;
