import "../../stylesheets/navbar.css";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBeer,
	faBook,
	faComments,
	faMapMarkerAlt,
	faPlusCircle,
	faSignIn,
	faSignOut,
	faUser,
	faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { current } from "@reduxjs/toolkit";
import { User } from "@prisma/client";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

function Navbar(): React.ReactNode {
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
	let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);

	const Logout = () => {
		removeCookie("token");
		removeCookie("id");
		navigate("/");
		window.location.reload();
	};

	return (
		<nav className="columns-2 row-auto p-3 grow bg-slate-900">
			<div className="p-2">
				<Link to="/" style={{ textDecoration: "none" }}>
					<img src={logo} width={"125px"} alt="Logo" />
				</Link>
			</div>

			<ul className="menu-list flex items-center">
				{current_user ? (
					// <LoggedPanel />
					<>
						{/* profile */}
						<li
							className="nav_element"
							onClick={() => navigate(current_user ? `/profile/${current_user.id}` : `/`)}
						>
							<div className="container flex items-center text-white">
								<FontAwesomeIcon icon={faUser} size="1x" color="#1A5FB4" className="mr-2" />
								<text>Profile</text>
							</div>
						</li>
						{/* friends */}

						{/* <li className="nav_element">
						<div className="container flex items-center text-white"  >
						<FontAwesomeIcon icon={faUserFriends} size="3x" color="#1A5FB4" />
						<text>Friends</text>
						</div>
					</li> */}

						{/* Chats */}
						<li className="nav_element" onClick={() => navigate("/messenger")}>
							<div className="container flex items-center text-white">
								<FontAwesomeIcon icon={faComments} size="1x" color="#1A5FB4" className="mr-2" />
								<text>Chats</text>
							</div>
						</li>

						{/* Logout */}
						<li className="nav_element" onClick={Logout}>
							<div className="container flex items-center text-white">
								<FontAwesomeIcon icon={faSignOut} size="1x" color="#1A5FB4" className="mr-2" />
								<text>Logout</text>
							</div>
						</li>
					</>
				) : (
					// <NotLoggedPanel />
					<>
						{/* Login */}
						{/* <li className="nav_element" onClick={() => navigate("/login")}>
							<div className="container flex items-center text-white">
								<FontAwesomeIcon icon={faUser} size="1x" color="#1A5FB4" className="mr-2" />
								<text>Login</text>
							</div>
						</li> */}
						{/* Register TMP? */}
						<li className="nav_element" onClick={() => navigate("/register")}>
							<div className="container flex items-center text-white">
								<FontAwesomeIcon icon={faSignIn} size="1x" color="#1A5FB4" className="mr-2" />
								<text>Register</text>
							</div>
						</li>
					</>
				)}

				{/* Beer Meets */}
				<li className="nav_element" onClick={() => navigate("/beermeets")}>
					<div className="container flex items-center text-white">
						<FontAwesomeIcon icon={faBeer} size="1x" color="#1A5FB4" className="mr-2" />
						<text>Beer Meets</text>
					</div>
				</li>

				{/* Add Article */}
				{/* <li className="nav_element">
					<FontAwesomeIcon icon={faPlusCircle} size="3x" color="#1A5FB4" className="mr-2"/>
				</li> */}

				{/* MapView */}
				<li className="nav_element">
					<div className="container flex items-center text-white" onClick={() => navigate("/places")}>
						<FontAwesomeIcon icon={faMapMarkerAlt} size="1x" color="#1A5FB4" className="mr-2" />
						<text>Map</text>
					</div>
				</li>

				{/* Beeropedia */}
				<li className="nav_element">
					<div className="container flex items-center text-white" onClick={() => navigate("/encyclopedia")}>
						<FontAwesomeIcon icon={faBook} size="1x" color="#1A5FB4" className="mr-2" />
						<text>Beeropedia</text>
					</div>
				</li>
			</ul>
		</nav>
	);
}

export default Navbar;
