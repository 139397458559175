import type { Article, ArticleComment } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ArticleCommentState {
	article_comments: ArticleComment[];
	init_status: true | false;
}

const initialState: ArticleCommentState = {
	init_status: true,
	article_comments: [],
};

export const articleCommentSlice = createSlice({
	name: "article_comments",
	initialState,
	reducers: {
		// setArtciles populates the list
		setArticleComments(state, action: PayloadAction<ArticleComment[]>) {
			if (state.init_status) {
				state.article_comments = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for users
		addArticleComment(state, action: PayloadAction<ArticleComment>) {
			state.article_comments.push(action.payload);
		},
		removeArticleComment(state, action: PayloadAction<number>) {
			state.article_comments = state.article_comments.filter((loc) => loc.id !== action.payload);
		},
		updateArticleComment(state, action: PayloadAction<ArticleComment>) {
			let index = state.article_comments.findIndex((loc) => loc.id === action.payload.id);
			state.article_comments[index] = action.payload;
		},
	},
	selectors: {
		getArticleComments: (state) => state.article_comments,
		getArticleCommentsForArticle: (state, action: PayloadAction<Article>): ArticleComment[] =>
			state.article_comments.filter((comment) => comment.articleId === action.payload.id) as ArticleComment[],
	},
});

export const { addArticleComment, removeArticleComment, updateArticleComment, setArticleComments } =
	articleCommentSlice.actions;
export default articleCommentSlice.reducer;
