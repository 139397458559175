import React, { useCallback, useEffect, useState } from "react";
// import { GoogleMap, useLoadScript, Marker, Libraries } from '@react-google-maps/api';
import { toastError } from "../../Services/toastify";
import {
	APIProvider,
	AdvancedMarker,
	AdvancedMarkerRef,
	InfoWindow,
	Map,
	Pin,
	useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { faBeer, faCampground, faCodeFork, faCompactDisc, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BeerBlue from "./Pins/BeerBlue";
import RestaurantBlue from "./Pins/RestaurantBlue";
import ClubBlue from "./Pins/ClubBlue";
import OutdoorBlue from "./Pins/OutdoorBlue";
import BeerOrange from "./Pins/BeerOrange";
import RestaurantOrange from "./Pins/RestaurantOrange";
import ClubOrange from "./Pins/ClubOrange";
import OutdoorOrange from "./Pins/OutdoorOrange";

import { Location, Place, PlaceType, Role, User } from "@prisma/client";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { clearSelectedPlace, resetSearch, setSelectedPlace } from "../../reducers/placesReducer";


interface MarkerPosition {
	lat: number;
	lng: number;
}

const AdvancedMarkerWithInfoWindow: React.FC<{ place:Place, position:MarkerPosition|undefined }> = ({ place, position}) => {
	const [markerRef, marker] = useAdvancedMarkerRef();
	const dispatch = useDispatch();
    const [infoWindowShown, setInfoWindowShown] = useState(false);
	const selPlace: null | Place = useSelector((state: RootState) => state.places.selectedPlace);

    const handleMarkerClick = useCallback(() =>
        setInfoWindowShown(isShown => !isShown),
        []
		
    );

	const onPinClick = () => {
		dispatch(setSelectedPlace(place));
		handleMarkerClick();
	};

    // const handleClose = useCallback(() => setInfoWindowShown(false), []);
	const handleClose = useCallback(() => setInfoWindowShown(false), []);

	const CloseInfoWindowClick = () => {
		dispatch(resetSearch());
		handleClose();
	}

	return (
        <>
            <AdvancedMarker 
                ref={markerRef}
                position={position}
                onClick={onPinClick}>
					{ selPlace?.id === place.id ?
						PinSwitchSelected(place.type) : PinSwitch(place.type)
					}
                    {}
            </AdvancedMarker>
            {selPlace?.id === place.id && (
                <InfoWindow anchor={marker} onClose={handleClose} onCloseClick={CloseInfoWindowClick}>
                    <div>
                        <h2>{place.name}</h2>
                        <p>Address: {place.address}</p>
                        <p>{place.description}</p>
                    </div>
                    
                </InfoWindow>
            )}
		</>
	);
};

export default AdvancedMarkerWithInfoWindow;


const PinSwitch = (type:PlaceType) => {
	switch(type) {
		case PlaceType.PUB:
			return <BeerOrange/>
		case PlaceType.RESTAURANT:
			return <RestaurantOrange/>
		case PlaceType.CLUB:
			return <ClubOrange/>
		case PlaceType.WILDSPOT:
			return <OutdoorOrange/>
	}
}

const PinSwitchSelected = (type:PlaceType) => {
	switch(type) {
		case PlaceType.PUB:
			return <BeerBlue/>
		case PlaceType.RESTAURANT:
			return <RestaurantBlue/>
		case PlaceType.CLUB:
			return <ClubBlue/>
		case PlaceType.WILDSPOT:
			return <OutdoorBlue/>
	}
}


