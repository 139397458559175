import React from "react";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ToastComponent: React.FC = () => {
	return (
		<>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                rtl={false}
                theme="light"
                />
            <ToastContainer />

		</>
	);
};

export default ToastComponent;


