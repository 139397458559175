import { User } from "@prisma/client";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";

const Test: React.FC = () => {
	const navigate = useNavigate();

	const users = useSelector((state: RootState) => state.users);
	console.log(users);

	return (
		<div>
			<h1>TEST Welcome TEST to TEST the TEST Homepage TEST!</h1>
			<button onClick={() => navigate("/login")}>Go to Login</button>
		</div>
	);
};

export default Test;
