import React from "react";
import { Article } from "@prisma/client";
import ArticleTile from "./ArticleTile";
import BlankArticleTile from "./BlankArticleTile";

const ArticleList: React.FC<{ articleList: Article[] }> = (props) => {
	// ale to dlaczego to tak
	// zajebiste pytanie
	let articles: Article[] = Array.from(props.articleList);

	return (
		<>
			{articles.length !== 0 ? (
				[...articles]
					.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
					.map((article: Article) => <ArticleTile key={article.id} article={article} />)
			) : (
				<>
					<BlankArticleTile />
				</>
			)}
		</>
	);
};

export default ArticleList;
