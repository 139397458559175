import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { Article, BeerMeet, BeerMeetType, Place, Status, User } from "@prisma/client";
import ArticleList from "../components/Articles/ArticleList";
import Tile from "../components/Layout/Tile";
import ProfileTile from "../components/Profiles/ProfileTile";
import CalendarTile from "../components/Profiles/CalendarTile";
import PostsTile from "../components/Profiles/PostsTile";

// pozyskanie id ze sciezki, np. /profile/1
// useSelector do pobrania profilu ze store

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import * as dayjs from 'dayjs'
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import ChoosePlaceDialog from "../Places/ChoosePlaceDialog";
import { clearSelectedPlace } from "../../reducers/placesReducer";
import { toastError } from "../../Services/toastify";
import { Cookies, useCookies } from "react-cookie";
import ChooseUsersDialog from "../Users/ChooseUsersDialog";
import { getBeerMeetParticipants, getBeerMeets, postBeerMeet } from "../../Services/beerMeetsAPICalls";
import { clearSelectedUsers } from "../../reducers/usersReducer";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { Avatar, Badge } from "@mui/material";
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { GetPlaceById } from "../../Services/placesAPICalls";
import { useNavigate } from "react-router-dom";
dayjs().format()


const BeerMeetList: React.FC = () => {
    const dispatch = useDispatch();

    const requestAbortController = React.useRef<AbortController | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [bmSearch, setBmSearch] = React.useState("");
    const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
   
    const [selDay, setSelDay] = React.useState<Dayjs | null>(null);
    const [value, setValue] = React.useState<Dayjs | null>(null);
    const [beerMeets, setBeerMeets] = useState<BeerMeet[]>([]);

    // dayjs('2022-04-17T15:30')



    useEffect(() => {
        const fetchBeerMeets = async () => {
            const response = await getBeerMeets();
            setBeerMeets(response);
        }
        
        fetchBeerMeets();        
      }, [value]);
   
    let cUserId: number|null = cookies.id ? cookies.id : null;

   
    const handleMonthChange = (date: Dayjs) => {
        if (requestAbortController.current) {
          // make sure that you are aborting useless requests
          // because it is possible to switch between months pretty quickly
          requestAbortController.current.abort();
        }
        
        
      };

      const initialValue = dayjs('2024-06-10');

    //   function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
    //     const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    //     const parsedmy = (date: Date) => {
    //         let day = String(new Date(date).getDate());
    //         let month = String(new Date(date).getMonth() + 1);
    //         const year = new Date(date).getFullYear();
    //         if(Number(day) < 10){
    //             day = "0" + String(day) 
    //         }
    //         if(Number(month) < 10){
    //             month = "0" + String(month)
    //         }
    //         return day + "." + month + "." + year
    //     }


        
    //     // console.log(day.format("DD.MM.YYYY"), parsedmy(beerMeets[0].startingAt))
    //     // beerMeets.forEach((beerMeet) => {
    //     //     if(parsedmy(beerMeet.startingAt).localeCompare(day.format("DD.MM.YYYY"))){
    //     //         setDayAndEvents((prev) => [...prev, {day: day, events: [beerMeet]}]) 
    //     //     }    
    //     // })
    //     // beerMeets.forEach((beerMeet) => {
    //     //     // console.log("beerMeet", parsedmy(beerMeet.startingAt), day.format("DD.MM.YYYY"))
    //     //     // console.log("beerMeet", typeof parsedmy(beerMeet.startingAt), typeof day.format("DD.MM.YYYY"))
    //     //     // console.log("beerMeet", parsedmy(beerMeet.startingAt) == day.format("DD.MM.YYYY"))

            
    //     //     if(parsedmy(beerMeet.startingAt).localeCompare(day.format("DD.MM.YYYY"))){
    //     //         setDayAndEvents((prev) => [...prev, {day: day, events: [beerMeet]}])
    //     //         console.log("beerMeet", parsedmy(beerMeet.startingAt), day.format("DD.MM.YYYY"))
    //     //         // console.log("setDayAndEvents", dayAndEvents)
    //     //     }
    //     //     // } highlightedDays.push(beerMeet.startingAt.getDate())
    //     // })
    //     // console.log("dayAndEvents", dayAndEvents)
    //     // console.log("props", props)
    //     // console.log("day", props.day)
      
    //     const isSelected =
    //       !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
    //     // console.log(props.day.toString(), isSelected)

    //     if(isSelected) 
    //     {
    //         setSelDay(day)
    //         // console.log("setSelDay", selDay)
    //         // console.log("day", day)
    //     }
            


    //     return (
    //       <Badge
    //         key={props.day.toString()}
    //         overlap="circular"
    //         badgeContent={isSelected ? '2' : undefined}
    //       >
    //         <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    //       </Badge>
    //     );
    //   }


    const filterBeerMeets = (meets: BeerMeet[]) => { 

        if(bmSearch === "") return meets
        else return meets.filter((beerMeet) => {
            if(beerMeet.name?.toLowerCase().includes(bmSearch.toLowerCase())) return true
            if(beerMeet.description?.toLowerCase().includes(bmSearch.toLowerCase())) return true
            else return false
        })
    }
    const filterByDate = (meets: BeerMeet[]) => {
        const parsedmy = (date: Date):String => {
                    let day = String(new Date(date).getDate());
                    let month = String(new Date(date).getMonth() + 1);
                    const year = new Date(date).getFullYear();
                    if(Number(day) < 10){
                        day = "0" + String(day) 
                    }
                    if(Number(month) < 10){
                        month = "0" + String(month)
                    }
                    return day + "." + month + "." + year
                }
        if(null === value) return meets
        else return meets.filter((beerMeet) => {
            if(parsedmy(beerMeet.startingAt).localeCompare(value.format("DD.MM.YYYY"))===0){
                console.log("beerMeet", parsedmy(beerMeet.startingAt), value.format("DD.MM.YYYY"))
                return true
            }else return false
        })
    }

    const dayClicked = (day: Dayjs) => {
        setValue(day)
        console.log(day.format("DD.MM.YYYY"))
        console.log(value)

    }


	return (
		<div className="flex gap-2 h-full w-full mx-auto flex-row">
            <div className="w-2/3 h-full" >
                <div className="w-full flex flex-col h-full">
                    {/* sort */}
                    <div className="w-full grow bg-white shadow-md shadow-gray-500 rounded-md p-2 mb-2 max-h-20">
                        
                        <input onChange={(e) => setBmSearch(e.target.value)} type="text" name="search" maxLength={50} className="w-full p-2" placeholder="search beermeets"></input>
                        
                    </div>
                    
                    {/* list */}
                    <div className="overflow-y-auto no-scrollbar w-full mb-5 ">
                        {
                            filterByDate(filterBeerMeets(beerMeets)).reverse().map((beerMeet) => 
                                <BeerMeetTile key={beerMeet.id} beerMeet={beerMeet} 
                            />)
                        }
                    </div>
                </div>
                
            </div>
            <div className="w-1/3 bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 ml-auto">
                <div className="w-full flex flex-col">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
			    <DateCalendar
                    defaultValue={initialValue}
                    loading={isLoading}
                    onMonthChange={handleMonthChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    value={value} 
                    onChange={(event) => dayClicked(event)}
                    
                />
                 {/* <DateCalendar views={['day']} /> */}
                </LocalizationProvider>
                <button onClick={() => setValue(null)} className="bg-black text-white px-10 py-2 rounded-md m-2">
                    Clear Data search 
                </button>
                </div>
            </div>
            
            
		</div>
	);
};

export default BeerMeetList;


interface IProps {
    beerMeet: BeerMeet
}

const BeerMeetTile: React.FC<IProps> = (props:  IProps) => {
    
    const beermeet: BeerMeet = props.beerMeet;
    const [participants, setParticipants] = useState<User[]>([]);
    const [place, setPlace] = useState<Place>({} as Place);
    const [owner, setOwner] = useState<User>({} as User);

    const navigate = useNavigate();
    const [fetchPlace, setFetchPlace] = useState<boolean>(false);
    useEffect(() => {
        const fetchParticipants = async () => {
            const response = await getBeerMeetParticipants(beermeet.id);
            setParticipants(response);
            setOwner(participants.find((user) => user.id === beermeet.ownerId) as User);
        }
        const fetchPlace = async () => {
            if(!beermeet.placeId) return
            const response:Place = await GetPlaceById(beermeet.placeId);
            setPlace(response);
            setFetchPlace(true);
        }
        fetchParticipants();
        fetchPlace();
        // console.log("typ ",typeof beermeet.createdAt)
        // console.log(beermeet.createdAt)
    }, []);

    const parseDate = (date: string) => {
        return new Date(date).getDate() 
        + "." + (new Date(date).getMonth() + 1 
        + ".") + new Date(date).getFullYear() + " " + new Date(date).getHours() + ":" + new Date(date).getMinutes();
    }

    const parseDMY = (date: string) => {
        return new Date(date).getDate() 
        + "." + (new Date(date).getMonth() + 1 
        + ".") + new Date(date).getFullYear();
    }

    return (
        <div className="p-2 ml-auto mr-auto mb-2 bg-white shadow-md shadow-gray-500 rounded-md w-full">
            <div className="flex flex-row">
                <div className="flex flex-col mr-auto">
                    <div className="text-lg font-bold ml-2 mt-2 mr-2 p-2"> {beermeet.name}</div>

                    <div className="text-sm m-2 p-2"> {beermeet.description}</div>    
                </div>
                <div className="flex flex-col ml-auto">
                    <div className="m-2 p-2">
                        <div className="text-right text-gray-500">{owner ? owner.username : "undefined"} {parseDMY(beermeet.createdAt)}</div>
                        <div><strong>Event begins: </strong>{parseDate(beermeet.startingAt)}-{ parseDate(beermeet.endingAt)}</div>
                        <div className="text-right">
                            {place === null ? null : <><strong>Place: </strong>{place.name}</>}
                            </div>
                            
                        
                    </div>
                </div>
                

            </div>
            <div>
                <div className="m-2 p-2">Participants </div>
                <div className="m-2 p-2 grid grid-cols-4">

                    {[...participants].slice(0, 3).map((user) => <div className="cursor-pointer" onClick={() => navigate(`/profile/${user.id}`)}>
                        <Avatar alt={user.username ? user.username : ""} src={user?.avatarpath ? user.avatarpath : "http://www.w3.org/2000/svg"} />
                        <div>{user.username}</div>
                    </div>)}
                    <div>
                        {[...participants].length > 3 ? <div className="cursor-pointer" onClick={() => navigate(`/profile/${owner.id}`)}>
                            <div className="ml-auto mr-auto"><Avatar alt="+" src={owner?.avatarpath ? owner.avatarpath : "http://www.w3.org/2000/svg"} /></div>
                            <div>More</div>
                        </div> : null}
                    </div>

                </div>
            </div>
            
            


        </div>
    );
}
