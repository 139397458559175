import React, { useEffect, useState } from "react";
import { User } from "@prisma/client";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedUser, resetUserSearch, setFilteredUsers } from "../../reducers/usersReducer";

const UserListFilter: React.FC<{}> = () => {
	const usersList: User[] = useSelector((state: any) => state.users.users);
	const dispatch = useDispatch();
	const [sortData, setSortData] = useState({
		searchString: "",
		order: "asc",
	});

	useEffect(() => {
		filterAndSort();
	}, [usersList, sortData]);

	const filterAndSort = () => {
		let updatedList: User[] = [...usersList];
		if (sortData.searchString === "") {
			updatedList = usersList;
		} else {
			updatedList = updatedList.filter(
				(user: User) =>
					// searching by username and email
					user.username?.toLowerCase().includes(sortData.searchString.toLocaleLowerCase()) ||
					user.email.toLocaleLowerCase().includes(sortData.searchString.toLocaleLowerCase())
			);
		}

		// nullable string z jakiegos powodu :|
		if (sortData.order === "asc") {
			updatedList = [...updatedList].sort((a: User, b: User) => {
				if (a.username) {
					if (b.username) {
						return a.username < b.username ? -1 : 1;
					} else {
						return 1;
					}
				} else {
					return -1;
				}
			});
		} else {
			updatedList = [...updatedList].sort((a: User, b: User) => {
				if (a.username) {
					if (b.username) {
						return a.username < b.username ? 1 : -1;
					} else {
						return -1;
					}
				} else {
					return 1;
				}
			});
		}

		dispatch(setFilteredUsers(updatedList));
		dispatch(clearSelectedUser());
	};

	const handleChange = (event: any) => {
		const { name, value } = event.target;
		setSortData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const Clear = (e) => {
		e.preventDefault();
		var form: HTMLFormElement = document.getElementById("form") as HTMLFormElement;
		setSortData({
			searchString: "",
			order: "asc",
		});
		dispatch(resetUserSearch());
		form.reset();
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 m-2 mr-6">
			{/* Searching and filtering */}
			<form id="form">
				<div className="flex flex-row">
					<div className="flex flex-col p-2 m-2">
						<p className="p-3">Search Users</p>

						<input
							type="text"
							id="searchString"
							name="searchString"
							onChange={handleChange}
							placeholder={`search phrase`}
							maxLength={100}
							className="grow p-3"
						></input>
					</div>
					{/* <div className="flex flex-col w-30 p-2 m-2">
							<p className="p-3 h-full ">Order</p>
							<select id="order" name="order" onChange={handleChange} className=" p-2 m-2 h-full">
								<option value="NONE">---</option>
								<option value="asc">asc</option>
								<option value="desc">desc</option>
							</select>
						</div> */}

					<div className="flex flex-col p-2 m-2">
						<button
							onClick={Clear}
							className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m"
						>
							Clear
						</button>
					</div>
				</div>
				{/* <div className="flex flex-row-reverse">
					<button onClick={Clear} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m" >
								Clear 
					</button>
					</div> */}
			</form>
		</div>
	);
};

export default UserListFilter;
