import { Place, PlaceOpinion } from "@prisma/client";
import axiosConfig from "../axiosConfig";
import { Interface } from "readline";

export const getPlaceOpinions = async (): Promise<PlaceOpinion[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/place_opinions",
        headers: {
            "Content-Type": "application/json",
        },
    }
    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

export interface IReturnPostPlaceOpinion {
    placeOpinion: PlaceOpinion,
    place: Place
}

export const postPlaceOpinion = async (placeOpinion: Omit<PlaceOpinion, "id" | "createdAt" | "updatedAt">): Promise<IReturnPostPlaceOpinion> => {
    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/place_opinions",
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            content: placeOpinion.content,
            userId: placeOpinion.userId,
            placeId: placeOpinion.placeId,
            stars: placeOpinion.stars
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) { 
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
      }  
} 

export const deletePlaceOpinion = async (id: number): Promise<void> => {
    let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `/place_opinions/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        await axiosConfig.request(config);
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}


export const getPlaceOpinionsByPlaceId = async (placeId: number): Promise<PlaceOpinion[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/place_opinions/place/${placeId}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config); 
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }

}


export const getPlaceOpinion = async (id: number): Promise<PlaceOpinion | null> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/place_opinions/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

