import type { ChatUser } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ChatUserState {
	chat_users: ChatUser[];
	init_status: true | false;
}

const initialState: ChatUserState = {
	init_status: true,
	chat_users: [],
};

export const chatUserSlice = createSlice({
	name: "chat_users",
	initialState,
	reducers: {
		// setChatUsers populates the list
		setChatUsers(state, action: PayloadAction<ChatUser[]>) {
			if (state.init_status) {
				state.chat_users = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for chatUsers
		addChatUser(state, action: PayloadAction<ChatUser>) {
			state.chat_users.push(action.payload);
		},
		removeChatUser(state, action: PayloadAction<number>) {
			state.chat_users = state.chat_users.filter((chatUser) => chatUser.id !== action.payload);
		},
		updateChatUser(state, action: PayloadAction<ChatUser>) {
			let index = state.chat_users.findIndex((chatUser) => chatUser.id === action.payload.id);
			state.chat_users[index] = action.payload;
		},
	},
});

export const { addChatUser, removeChatUser, updateChatUser, setChatUsers } = chatUserSlice.actions;
export default chatUserSlice.reducer;
