import React from "react";

import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "../../Services/toastify";
import { HopComponent } from "@prisma/client";
import { PostHopComponent } from "../../Services/hopComponentsAPICalls";
import { addHopComponent } from "../../reducers/hopComponentsReducer";

const HopComponentFormTile: React.FC = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = React.useState(false);
	const [formData, setFormData] = React.useState({
		name: "",
	});

	const handleChange = (event: any) => {
		setFormData((prevData) => ({
			...prevData,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		let name = "";
		if (formData.name) {
			name = formData.name;
		} else {
			toastError("Please fill out name for beer type");
			return;
		}

		setIsLoading(true);
		try {
			const newHopComponent: Omit<HopComponent, "id" | "createdAt" | "updatedAt"> = {
				name,
			};
			const submitResponse = await PostHopComponent(newHopComponent);
			dispatch(addHopComponent(submitResponse));
			console.log("after post hop component", submitResponse);
			toastSuccess("Your hop component has been submitted");
			event.target.reset();
		} catch (error) {
			toastError("Error while submitting hop component");
			console.log("Hop component error", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<p>HopComponentFormTile</p>
			<form onSubmit={handleSubmit}>
				<label>
					Name:
					<input type="text" name="name" value={formData.name} onChange={handleChange} />
				</label>
				<br />
				<button type="submit" onClick={handleSubmit} disabled={isLoading}>
					Submit
				</button>
			</form>
		</div>
	);
};

export default HopComponentFormTile;
