import React from "react";
import { Place } from "@prisma/client";
import PlaceTile from "./PlaceTile";
import { useSelector } from "react-redux";

const PlaceListFiltered: React.FC<{}> = () => {
	
	const placesList: Place[] = useSelector((state: any) => state.places.placesSearch);

	return (							
			<div className="overflow-y-auto max-h-[60vh]"> 
				{/* List of places */}
				{[...placesList]
					.map((place: Place) => (
						<PlaceTile key={place.id} place={place} />
					))}
			</div>
	);
};

export default PlaceListFiltered;
