import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store";
import { Article, Status, User } from "@prisma/client";
import ArticleList from "../components/Articles/ArticleList";
import Tile from "../components/Layout/Tile";
import ProfileTile from "../components/Profiles/ProfileTile";
import CalendarTile from "../components/Profiles/CalendarTile";
import PostsTile from "../components/Profiles/PostsTile";
import BeerMeetForm from "../components/BeerMetts/BeerMeetForm";
import BeerMeetList from "../components/BeerMetts/BeerMeetList";

// pozyskanie id ze sciezki, np. /profile/1
// useSelector do pobrania profilu ze store

const BeerMeets: React.FC = () => {
	// const params = useParams();
	// const userId: number = Number(params.userId);
	// // const profile = useSelector((state: RootState) => state.profiles.profiles.find((profile) => profile.id === userId));
	// const user: User | undefined = useSelector((state: RootState) =>
	// 	state.users.users.find((user) => user.id === userId)
	// );

	



	return (
		<div className="flex mt-4 gap-6 fixed h-4/5 w-full mx-auto">
			<div className="w-1/3 bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2  ml-auto">
				{/* <p>nadchodzace beermeety do ktorych uzytkownik moglby dolaczyc</p> */}
				<BeerMeetForm />
			</div>

			<div className="w-2/3 h-full w-full grow">
				<BeerMeetList />
			</div>
			
		</div>
	);
};

export default BeerMeets;
