import axiosConfig from "../axiosConfig";
import { ArticleComment } from "@prisma/client";
import { addArticleComment } from "../reducers/articleCommentsReducer";

export const GetArticleComments = async (): Promise<ArticleComment[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/article_comments",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetArticleCommentById = async (commentId: number): Promise<ArticleComment> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/article_comments/${commentId}`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetArticleCommentsByArticleId = async (articleId: number): Promise<ArticleComment[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/article_comments/article/${articleId}`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PostArticleComment = async (
	article_comment: Omit<ArticleComment, "id" | "createdAt" | "updatedAt" | "likes" | "dislikes">
	): Promise<ArticleComment> => {
	let data_to_send = JSON.stringify(article_comment);
	console.log("data to send API service post artcileComment", data_to_send);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/article_comments",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
