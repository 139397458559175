import { Article, Place, User } from "@prisma/client";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle } from "../../reducers/articlesReducer";
import { RootState } from "../../store";
import { PostArticle } from "../../Services/articlesAPICalls";
import { toastSuccess, toastError } from "../../Services/toastify";
import { Status } from "@prisma/client";
import ChoosePlaceDialog from "../Places/ChoosePlaceDialog";
// import { MenuItem, Select, Typography } from "@mui/material";
import { clearSelectedPlace, resetSearch } from "../../reducers/placesReducer";

const ArticleFormTile: React.FC = () => {
	// redux action dispatcher
	const dispatch = useDispatch();
	const selPlace: null | Place = useSelector((state: RootState) => state.places.selectedPlace);
	const [isLoading, setIsLoading] = useState(false);
	// formData
	const [formData, setFormData] = useState({
		title: "",
		content: "",
		status: Status.PUBLIC,
		// formularze tylko na stringach dzialaja
		place: "0",
	});

	// files input
	// max number of images
	const MAX_IMAGES_COUNT = 6;
	// uploded images state
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	// check if file limit is exceeded
	const [filelimit, setFilelimit] = useState(false);
	// custom file input
	const hiddenFileInput = useRef<HTMLInputElement>(null);

	// changes the state of the formData on every input
	const handleChange = (event: any) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		// console.log(formData);
	};

	let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);

	// submit
	const submitArticle = async (event: any) => {
		event.preventDefault();

		// content validator
		let content = "";
		if (formData.content) {
			content = formData.content;
			// console.log("content", content);
		} else {
			toastError("Please fill out content for article");
			return;
		}

		// title validator
		let title = "";
		if (formData.title) {
			title = formData.title;
			// console.log("title", title);
		} else {
			toastError("Please fill out title for article");
			return;
		}

		setIsLoading(true);
		// posting new article
		try {
			const newArticle: Omit<Article, "id" | "createdAt" | "updatedAt" | "likes" | "dislikes"> = {
				title,
				content,
				status: formData.status,
				placeId: selPlace?.id ? selPlace.id : 0,
				authorId: current_user?.id ? current_user.id : 0,
			};
			console.log("new article", newArticle);
			const submitResponse = await PostArticle(newArticle, uploadedFiles);
			dispatch(addArticle(submitResponse));
			console.log("after post article", submitResponse);
			toastSuccess("Your post has been submitted");
			event.target.reset();
			setFilelimit(false);
			setUploadedFiles([]);
			dispatch(resetSearch());

			formData.title = "";
			formData.content = "";
			formData.status = Status.PUBLIC;
			formData.place = "0";
		} catch (error) {
			toastError("Error while submitting article");
			console.log("Article error", error);
		} finally {
			setIsLoading(false);
		}
	};

	// przekierowanie custom buttona by wywołał kliknięcie inputa
	const UploadFilesClick = (event) => {
		// czemu sie to swieci ale działa błedów nie daje
		// nie podales typu w <> przy refie useRef<HTMLInputElement>(null)
		event.preventDefault();
		if (filelimit) {
			toastError(`You can only upload up to ${MAX_IMAGES_COUNT} images`);
			return;
		}
		hiddenFileInput.current?.click();
	};

	// obsługa dodawania obrazków musi taka być żeby można było dynamicznie to zmeiniać
	const handleUploadFiles = (event) => {
		const files = Array.prototype.slice.call(event.target.files);
		const uploaded = [...uploadedFiles];
		let limitExceeded = false;
		files.forEach((file) => {
			if (uploaded.findIndex((f) => f.name === file.name) === -1) {
				uploaded.push(file);
				if (uploaded.length === MAX_IMAGES_COUNT) setFilelimit(true);
				if (uploaded.length > MAX_IMAGES_COUNT) {
					toastError(`You can only upload up to ${MAX_IMAGES_COUNT} images`);
					setFilelimit(false);
					limitExceeded = true;
					return true;
				}
			}
		});
		if (!limitExceeded) setUploadedFiles(uploaded);
	};

	// remove image from state and update state
	const removeImage = (index) => {
		const newImages = [...uploadedFiles];
		newImages.splice(index, 1);
		setUploadedFiles(newImages);
		setFilelimit(false);
	};

	const choosePlace = (placeId: number) => {
		console.log("placeId", placeId);
		setFormData({ ...formData, place: placeId.toString() });
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 max-w-2xl ml-auto mr-auto ">
			<form onSubmit={submitArticle}>
				<div className="flex flex-col gap-3">
					<input
						type="text"
						id="title"
						name="title"
						onChange={handleChange}
						className="grow p-3 font-bold"
						placeholder={`Title`}
						maxLength={45}
					></input>
					<textarea
						id="content"
						name="content"
						onChange={handleChange}
						className="grow p-3"
						placeholder={`What's going on your mind, ${
							current_user?.username ? current_user.username : "user"
						}?`}
					></textarea>
				</div>
				<br />

				<div className="flex flex-row grow">
					{/* select place */}
					<ChoosePlaceDialog action={choosePlace} />

					{/* select file */}
					<input
						type="file"
						name="image"
						multiple
						accept="image/*"
						style={{ display: "none" }}
						ref={hiddenFileInput}
						onChange={handleUploadFiles}
						disabled={filelimit}
					/>
					<button onClick={UploadFilesClick} className={"bg-black text-white px-10 py-2 rounded-md m-2"}>
						Upload Files
					</button>

					{/* status */}
					<label htmlFor="status"> </label>
					<select
						id="status"
						name="status"
						onChange={handleChange}
						className="bg-black border text-white rounded-md m-2 focus:ring-blue-500 focus:border-blue-500 block py-2 px-5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					>
						<option defaultValue={Status.PUBLIC}>public</option>
						<option value={Status.FRIENDSONLY}>friends only</option>
						<option value={Status.PRIVATE}>private</option>
					</select>
				</div>
				{selPlace ? (
					<div>
						<label className={"bg-black text-white px-10 py-2 rounded-md m-2"}>
							Selected Place: {selPlace.name}
						</label>
						<button
							className={"bg-black text-white px-10 py-2 rounded-md m-2"}
							onClick={() => dispatch(clearSelectedPlace())}
						>
							X
						</button>
					</div>
				) : (
					<></>
				)}

				{/* Image show */}
				<div>
					<ul className="grid grid-cols-4 gap-4 ">
						{uploadedFiles.map((image, index) => (
							<div key={index}>
								<div>
									{/* TEGO BUTTONA TO NIE WIEM JAK ŁADNIE ZROBIĆ NA RAZIE MAM TO W POWARZANIU */}
									{/* JESZCZE MOŻNA TE PATRUKOWE TAILE ZROBIĆ HUH wie */}
									<button
										onClick={() => removeImage(index)}
										className="bg-black text-white rounded-md p-1"
									>
										x
									</button>
									<img
										width={"80%"}
										height={"80%"}
										src={URL.createObjectURL(image)}
										alt="uploaded file"
									/>
								</div>
							</div>
							// <li key={index}>
							// 	<img src={URL.createObjectURL(image)} alt="uploaded file" />
							// </li>
						))}
					</ul>
				</div>

				<div>
					<button
						type="submit"
						className={"bg-black text-white px-10 py-2 rounded-md m-2"}
						disabled={isLoading}
					>
						Share
					</button>
				</div>
			</form>
		</div>
	);
};

export default ArticleFormTile;
