import { User } from "@prisma/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { GetUsers } from "../../Services/userAPICalls";
import { useCookies } from "react-cookie";
import { Avatar } from "@mui/material";




const UsersGridList: React.FC = () => {
	const navigate = useNavigate();

    const [users, setUsers] = useState<User[]>([]);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);


    const fetchUsers = async () => {
        try {
            const users: User[] = await GetUsers();
            if(users.length > 12) setUsers(users.slice(0, 12));
            else setUsers(users); 
                
            const user = users.find((user) => user.id === cookies.id);
            if(user) setCurrentUser(user);

            console.log("users fetching reposnse ", users);
        } catch (error) {
            console.log("error fetching users", error);
        }
    };

    useEffect(() => {
        fetchUsers();
    },[])    

	console.log(users);

	return (
        <div className="w-full h-full mx-auto">
            <div className="m-2 w-full content-center">
                <p className="mx-auto text-center">People you may know</p>
            </div>
            <div className="shadow-md grid grid-cols-1 overflow-y-auto gap-2 mx-auto h-4/5 no-scrollbar pb-2"> 
            
            {[...users].map((user) => (
                <div key={user.id} className=" w-full ml-4 flex flex-row m-1 cursor-pointer mt-auto mb-auto hover:border-blue-500" onClick={() => navigate(`/profile/${user.id}`)} >
                    <div>
                        <Avatar alt={user.username ? user.username : ""} src={user?.avatarpath ? user.avatarpath : "http://www.w3.org/2000/svg"} />
                    </div>
                    <div className="m-2 ">
                        {user.username}
                    </div>
                </div>
                
            ))}
            </div>
            <div className="grow w-full">
                <button onClick={() => navigate("/users")} className=" mb-2 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded grow mt-2">
                    See more users
                </button>

            </div>
		</div>
	);
};

export default UsersGridList;
