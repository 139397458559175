import { Article, ArticleComment, User, Reaction, ArticleImage, Place, ArticleReaction } from "@prisma/client";
import React, { useEffect, useRef, useState } from "react";
import { howLongAgo } from "../../Services/parsing";
import ArticleCommentComponent from "./ArticleCommentComponent";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { Link, Navigate, parsePath } from "react-router-dom";
import { DeleteArticleReaction, PostArticleReaction } from "../../Services/articleReactionsAPICalls";
import { DeleteArticle, getArticleImages } from "../../Services/articlesAPICalls";

import { removeArticle } from "../../reducers/articlesReducer";
import { addArticleReaction, removeArticleReaction } from "../../reducers/articleReactionsReducer";
import { PostArticleComment } from "../../Services/articleCommentsAPICalls";
import { addArticleComment } from "../../reducers/articleCommentsReducer";
import { toastError, toastSuccess } from "../../Services/toastify";
import ArticleEditDialog from "./ArticleEditDialog";
import DeleteDialog from "../Extras/DeleteDialog";
import InfoIcon from "@mui/icons-material/Info";
import { Button, IconButton, ImageList, ImageListItem, ImageListItemBar, styled } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { socket } from "../../socket";

interface IProps {
	article: Article;
}
// reaction enum ;userid ;articleid
console.log("like", Reaction.LIKE);
console.log("dislike", Reaction.DISLIKE);

const ArticleTile: React.FC<IProps> = (props: IProps) => {
	const dispatch = useDispatch();
	const [dropdownHidden, setDropdownHidden] = React.useState(true);
	const [commentsHidden, setCommentsHidden] = React.useState(true);
	const [editImages, setEditImages] = React.useState(false);
	const [articleFiles, setArticleFiles] = useState<ArticleImage[]>([]);
	// const [filesURL, setFilesURl] = useState(String[]);
	let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);

	const article: Article = props.article;

	const place: Place | undefined = useSelector((state: RootState) =>
		state.places.places.find((place) => place.id === props.article.placeId)
	);
	const comments: ArticleComment[] = useSelector((state: RootState) =>
		state.article_comments.article_comments.filter((comment) => comment.articleId === props.article.id)
	);

	const likes: ArticleReaction[] = useSelector((state: RootState) =>
		state.article_reactions.article_reactions.filter(
			(reaction) => reaction.articleId === props.article.id && reaction.reaction === Reaction.LIKE
		)
	);

	const dislikes: ArticleReaction[] = useSelector((state: RootState) =>
		state.article_reactions.article_reactions.filter(
			(reaction) => reaction.articleId === props.article.id && reaction.reaction === Reaction.DISLIKE
		)
	);

	const current_user_like: ArticleReaction | undefined = useSelector((state: RootState) =>
		state.article_reactions.article_reactions.find(
			(reaction) =>
				reaction.articleId === props.article.id &&
				reaction.userId === current_user?.id &&
				reaction.reaction === Reaction.LIKE
		)
	);

	const current_user_dislike: ArticleReaction | undefined = useSelector((state: RootState) =>
		state.article_reactions.article_reactions.find(
			(reaction) =>
				reaction.articleId === props.article.id &&
				reaction.userId === current_user?.id &&
				reaction.reaction === Reaction.DISLIKE
		)
	);

	const author: User | undefined = useSelector((state: RootState) =>
		state.users.users.find((user) => user.id === props.article.authorId)
	);

	useEffect(() => {
		const fetchImages = async () => {
			try {
				const images: ArticleImage[] = await getArticleImages(props.article.id);
				// console.log("images", images);
				setArticleFiles(images);
			} catch (err) {
				console.log(err);
			}
		};
		fetchImages();
	}, [props.article.id]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	function switchDropdown() {
		setDropdownHidden(!dropdownHidden);
	}

	function switchComments() {
		setCommentsHidden(!commentsHidden);
	}

	const likeArticle = () => {
		if (current_user) {
			if (!current_user_like) {
				if (current_user_dislike) {
					//usuń dislike
					DeleteArticleReaction(current_user_dislike.id).then((res) => {
						console.log("delete articlereaction(dislike) res", res);
						dispatch(removeArticleReaction(current_user_dislike.id));
						console.log("deleted", current_user_dislike);
					});
				}
				const r: Reaction = Reaction.LIKE;
				const userId: number = current_user.id;
				const articleId: number = props.article.id;
				const reaction = { reaction: r, userId, articleId };
				console.log("reaction", reaction);
				PostArticleReaction(reaction).then((res) => {
					console.log("post articlereaction res", res);
					dispatch(addArticleReaction(res));
				});
			} else {
				DeleteArticleReaction(current_user_like.id).then((res) => {
					console.log("delete articlereaction(like) res", res);
					dispatch(removeArticleReaction(current_user_like.id));
					console.log("deleted", current_user_like);
				});
			}
		}

		//debug
		if (current_user_like && current_user_dislike) {
			console.log("both liked and dislike, shouldn't happen", current_user_like, current_user_dislike);
		}
	};

	const dislikeArticle = () => {
		if (current_user) {
			if (!current_user_dislike) {
				if (current_user_like) {
					//usuń dislike
					DeleteArticleReaction(current_user_like.id).then((res) => {
						console.log("delete articlereaction(like) res", res);
						dispatch(removeArticleReaction(current_user_like.id));
						console.log("deleted", current_user_like);
					});
				}
				const r: Reaction = Reaction.DISLIKE;
				const userId: number = current_user.id;
				const articleId: number = props.article.id;
				const reaction = { reaction: r, userId, articleId };
				console.log("reaction", reaction);
				PostArticleReaction(reaction).then((res) => {
					console.log("post articlereaction(dislike) res", res);
					dispatch(addArticleReaction(res));
				});
			} else {
				DeleteArticleReaction(current_user_dislike.id).then((res) => {
					console.log("delete articlereaction(dislike) res", res);
					dispatch(removeArticleReaction(current_user_dislike.id));
					console.log("deleted", current_user_dislike);
				});
			}
		}

		//debug
		if (current_user_like && current_user_dislike) {
			console.log("both liked and disliked, shouldn't happen", current_user_like, current_user_dislike);
		}
	};

	const handleDelete = () => {
		console.log("delete", props.article.id);
		DeleteArticle(props.article.id).then((res) => {
			console.log("res", res);
			dispatch(removeArticle(props.article.id));
		});
	};

	const parsePathToImage = (path: string) => {
		var parsedPath: string;
		parsedPath = `http://localhost:2137/${path.substring(4, path.length)}`;
		parsedPath = parsedPath.replace(/\\/g, "/");

		return parsedPath;
	};

	const [commentContent, setCommentContent] = useState<string>("");

	const handleChange = (event: any) => {
		const { value } = event.target;
		setCommentContent(value);
	};

	const sendComment = async () => {
		console.log("send comment");
		const comment: Omit<ArticleComment, "id" | "createdAt" | "updatedAt" | "likes" | "dislikes"> = {
			content: commentContent,
			userId: current_user?.id as number,
			articleId: article.id,
		};
		console.log("comment", comment);
		try {
			const submitResponse = await PostArticleComment(comment);
			dispatch(addArticleComment(submitResponse));
			setCommentContent("");
			socket.emit("new_article_comment", submitResponse);
		} catch (err) {
			console.log("sendComment in articleTile error: ", err);
			toastError("Failed to send comment");
		}
	};

	const editPhotos = () => {};

	return (
		<div className="ml-auto mr-auto bg-white shadow-md shadow-gray-500 rounded-md max-w-2xl mb-5 p-2">
			<div className="flex gap-3">
				{/* Avatar */}
				<div className="w-12 rounded-full overflow-hidden">
					<svg
						className="w-[48px] h-[48px] text-gray-800 dark:text-white"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path
							stroke="black"
							strokeWidth="2"
							d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
						/>
					</svg>
				</div>
				<div className="grow">
					<p>
						{author ? (
							<Link className="font-semibold" to={`/profile/${author!.id}`}>
								{author?.username}
							</Link>
						) : (
							"Loading..."
						)}{" "}
						shared a post
					</p>
					<p className="text-gray-500 text-sm">
						{howLongAgo(new Date(props.article.createdAt))} ago
						{place ? (
							<>
								, at <b>{place.name}</b>
							</>
						) : (
							""
						)}
						{/* przydaloby sie zrobic hyperlink do mapy w konkretnym miejscu */}
					</p>
				</div>
				{current_user?.id === author?.id ? (
					<div>
						<button onClick={switchDropdown}>
							<svg
								className="w-6 h-6 text-gray-800 dark:text-white"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path stroke="black" strokeLinecap="round" strokeWidth="2" d="M6 12h0m6 0h0m6 0h0" />
							</svg>
						</button>
						<div className="relative z-10" hidden={dropdownHidden}>
							<div className="absolute -right-6 w-52 bg-white shadow-md shadow-gray-300 p-3 rounded-sm border border-gray-100">
								<DeleteDialog action={handleDelete} dialogTitle="Delete Article" />
								<ArticleEditDialog article={props.article} />
							</div>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
			<div>
				<p className="m-1 font-bold">{props.article.title}</p>
				<div className="my-3 text-sm">
					{props.article.content}

					<div className="rounded-md overlow-hidden max-height-96">
						<ImageList variant="masonry" cols={3} gap={8}>
							{articleFiles.map((image, index) => (
								<ImageListItem key={index}>
									{editImages ? (
										<div>
											<img
												srcSet={`${parsePathToImage(
													image.path
												)}?w=248&fit=crop&auto=format&dpr=2 2x`}
												src={`${parsePathToImage(image.path)}?w=248&fit=crop&auto=format`}
												alt={image.name}
												loading="lazy"
											/>
											<ImageListItemBar
												subtitle="Click to remove"
												actionIcon={
													<IconButton sx={{ color: "rgba(255, 255, 255, 0.54)" }}>
														<InfoIcon />
													</IconButton>
												}
											/>
										</div>
									) : (
										<img
											srcSet={`${parsePathToImage(
												image.path
											)}?w=248&fit=crop&auto=format&dpr=2 2x`}
											src={`${parsePathToImage(image.path)}?w=248&fit=crop&auto=format`}
											alt={image.name}
											loading="lazy"
										/>
									)}
								</ImageListItem>
							))}
						</ImageList>
					</div>
				</div>
			</div>
			{/* Likes Dislkies */}
			<div className="mt-5 flex gap-9">
				<button className="flex gap-2 items-center" onClick={likeArticle}>
					<svg
						className="w-6 h-6 text-gray-800 dark:text-white"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path
							stroke="black"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={current_user_like ? "2" : "1.5"}
							d="M7 11c.9 0 1.4-.5 2.2-1a33.3 33.3 0 0 0 4.5-5.8 1.5 1.5 0 0 1 2 .3 1.6 1.6 0 0 1 .4 1.3L14.7 10M7 11H4v6.5c0 .8.7 1.5 1.5 1.5v0c.8 0 1.5-.7 1.5-1.5V11Zm6.5-1h5l.5.1a1.8 1.8 0 0 1 1 1.4l-.1.9-2.1 6.4c-.3.7-.4 1.2-1.7 1.2-2.3 0-4.8-1-6.7-1.5"
						/>
					</svg>
					{likes.length}
				</button>

				<button className="flex gap-2 items-center" onClick={dislikeArticle}>
					<svg
						className="w-6 h-6 text-gray-800 dark:text-white"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						transform="scale(1 -1)"
					>
						<path
							stroke="black"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={current_user_dislike ? "2" : "1.5"}
							d="M7 11c.9 0 1.4-.5 2.2-1a33.3 33.3 0 0 0 4.5-5.8 1.5 1.5 0 0 1 2 .3 1.6 1.6 0 0 1 .4 1.3L14.7 10M7 11H4v6.5c0 .8.7 1.5 1.5 1.5v0c.8 0 1.5-.7 1.5-1.5V11Zm6.5-1h5l.5.1a1.8 1.8 0 0 1 1 1.4l-.1.9-2.1 6.4c-.3.7-.4 1.2-1.7 1.2-2.3 0-4.8-1-6.7-1.5"
						/>
					</svg>
					{dislikes.length}
				</button>
			</div>
			{/* Comments and Comments form */}
			{current_user ? (
				<>
					<div hidden={commentsHidden}>
						{comments
							//od najmłodszego
							.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
							.map((comment) => {
								return <ArticleCommentComponent key={comment.id} comment={comment} />;
							})}
					</div>
					<div className="flex mt-4 gap-3">
						{/* comments show button */}
						<button className="flex gap-2 items-center" onClick={switchComments}>
							<svg
								className="w-6 h-6 text-gray-800 dark:text-white"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									stroke="black"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={commentsHidden ? "1.5" : "2"}
									d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1h-6.6a1 1 0 0 0-.7.3l-2.9 2.5c-.3.3-.8.1-.8-.3V17c0-.6-.4-1-1-1H5a1 1 0 0 1-1-1V6c0-.6.4-1 1-1Z"
								/>
							</svg>
							{/* TODO get artykuly z komentarzami, tutaj ilość komentarzy */}
							{/* {{ comments?.filter((c)=>c.articleId==articleId).length }} */}
							{/* {comments?.length} */}
							{comments.length}
						</button>
						<textarea
							onChange={handleChange}
							name="commentContent"
							id="comment-area"
							className="border grow p-2 px-5 h-12 rounded-full overflow-hidden"
							placeholder="Comment it!"
						></textarea>
						{/* @click="sendComment" */}
						<button onClick={sendComment}>
							<svg
								className="w-6 h-6 text-gray-800 dark:text-white"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									stroke="black"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M19 12H5m14 0-4 4m4-4-4-4"
								/>
							</svg>
						</button>
					</div>
				</>
			) : (
				<div v-if="!currentUser" className="flex p-3">
					Sign in to comment posts.
				</div>
			)}
		</div>
	);
};

export default ArticleTile;
