import { Beer, BeerOpinion, User } from "@prisma/client";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../store";
import { toastError, toastSuccess } from "../../Services/toastify";
import { postBeerOpinion } from "../../Services/beerOpinionAPICalls";
import { addBeerOpinion } from "../../reducers/beerOpinionsReducer";

interface IProps {
    beer: Beer;
}

const BeerRateTile: React.FC<IProps> = (props: IProps) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const dispatch = useDispatch();
    
    const beerId: number = props.beer.id;
    let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);
	const beer: Beer | undefined = useSelector((state: RootState) =>
        state.beers.beers.find((beer) => beer.id === beerId)
    )

    useEffect(() => {
        if (current_user != null && beer != undefined) {
            setIsLoading(false);
        }
    }, [current_user, beer]);

    const [formData, setFormData] = React.useState({
		stars: 0,
		content: "",
	});

    const handleChange = (event: any) => {
        setFormData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value,
        }))
    }

    const handleSubmit = async (event: any) => {
		event.preventDefault();

		// name validator
        let content = "";
		if (formData.content) {
			content = formData.content;
			console.log("beer name", content);
		} else {
			toastError("Please fill out content for opinion");
			return;
		}

        let stars = 0;
        if (formData.stars) {
            stars = formData.stars;
            console.log("beer stars", stars);
        } else {
            toastError("Please fill out stars for opinion");
            return;
        }

		setIsLoading(true);
		try {
            if (current_user != undefined) {
                const newBeerOpinion: Omit<BeerOpinion, "id" | "createdAt" | "updatedAt"> = {
                    stars,
                    content,
                    userId: current_user.id,
                    beerId: beerId,
                }

                const submitResponse = await postBeerOpinion(newBeerOpinion);
                dispatch(addBeerOpinion(submitResponse));
                console.log("after post beer opinion", submitResponse);
                toastSuccess("Your beer opinion has been submitted");
                event.target.reset();
            }
		} catch (error) {
			toastError("Error while submitting beer");
			console.log("Beer error", error);
		} finally {
			setIsLoading(false);
		}
	};

    return (
        <div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
            <form onSubmit={handleSubmit}>
                <label>
					Stars:
                    <select id="stars" name="stars" value={formData.stars} onChange={handleChange}>
                        <option value="0">*</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
					{/* <input type="number" name="name" value={formData.stars} onChange={handleChange} /> */}
				</label>
                <br />
                <label>
                    Content:
                    <input type="text" name="content" value={formData.content} onChange={handleChange} />
                </label>
                <br />
                <button type="submit" disabled={isLoading} className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m">Submit</button>
            </form>
        </div>
    );
};

export default BeerRateTile