import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Button, Rating } from "@mui/material";
import MapViewForPicker from '../Map/PlacePicker/MapViewForPicker';
import { useDispatch, useSelector} from 'react-redux';
import { resetSearch, updatePlace, updateSingleFilteredPlace } from '../../reducers/placesReducer';
import PlaceListFilter from './PlaceListFilter';
import PlaceListFiltered from './PlaceListFiltered';
import { RootState } from '../../store';
import { BeerMeet, Place, PlaceOpinion, User } from '@prisma/client';
import MapWithOnePin from '../Map/MapWithOnePin';
import { useEffect, useState } from 'react';
import { Decimal } from '@prisma/client/runtime/library';
import PlaceOpinionForm from './PlaceOpinionForm';
import PlaceOpinionList from './PlaceOpinionList';
import { getPlaceOpinionsByPlaceId } from '../../Services/placeOpinionAPICAlls';
import { GetPlaceById } from '../../Services/placesAPICalls';
import { GetUsersByIds } from '../../Services/userAPICalls';
import PlaceOpinionTile from './PlaceOpinionTile';
import BeerMeetTile from '../BeerMetts/BeerMeetTile';
import { getBeerMeetsByPlaceId } from '../../Services/beerMeetsAPICalls';


interface IProps {
    place: Place
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const PlaceInfoDialog: React.FC<IProps> = (props: IProps) => {
	const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const place:Place = props.place

	const handleClickOpen = () => {
    	setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

    const [opStart, setOpStart] = useState<PlaceOpinion[]>([]);
    const [opinions, setOpinions] = useState<PlaceOpinion[]>([]);
    const [opinionCount, setOpinionCount] = useState<number>(0);
    const [placeInfo, setPlaceInfo] = useState<Place>(props.place);
    const [bmSearch, setBmSearch] = React.useState("");
    const [users, setUsers] = React.useState<User[]>([]);
    const [beerMeets, setBeerMeets] = React.useState<BeerMeet[]>([]);
    const [switchToBeerMeets , setSwitchToBeerMeets] = React.useState<boolean>(false);



    const updateData = (opinion: PlaceOpinion, place: Place) => {
        const newOp = [...opinions, opinion];
        const newCount = opinionCount + 1;
        setOpinions(newOp);
        setOpinionCount(newCount);
        setPlaceInfo(place);
        dispatch(updatePlace(place));
        dispatch(updateSingleFilteredPlace(place));
    }


    useEffect(() => {
        const fetchBeerMeets = async () => {
            try{
                const bm: BeerMeet[] = await getBeerMeetsByPlaceId(place.id);
                setBeerMeets(bm);
            }catch(err){
                console.log(err);
            }
        }
        const getOpinions = async() => {    
            try{
                const opinions: PlaceOpinion[] = await getPlaceOpinionsByPlaceId(place.id);
                const p: Place = await GetPlaceById(place.id);
                setOpinions(opinions);
                setOpStart(opinions);
                setOpinionCount(opinions.length);
                setPlaceInfo(p);
    
            }catch(err){
                console.log(err);
            }
        }
        
        const fetchUsers = async (ids: number[]) => {
            try {
                const users: User[] = await GetUsersByIds(ids);
                setUsers(users);
                // console.log("users", users);
            } catch (error) {
                console.log(error);
            }
        }
        let ids: number[] = [...opinions].map((opinion) => opinion.userId);
		ids = [ ...new Set(ids) ];
		
		fetchUsers(ids);
        fetchBeerMeets();
        getOpinions();   
    }, [opinionCount]);


    const handleChange = (event: any) => {
		const {name, value} = event.target;
		if(value === '0'){
			setOpinions([...opStart]);
		}else{
			setOpinions(opStart.filter((opinion: PlaceOpinion) => opinion.stars === parseInt(value)));
		}
		
	}
    const filterBeerMeets = (meets: BeerMeet[]) => { 

        if(bmSearch === "") return meets
        else return meets.filter((beerMeet) => {
            if(beerMeet.name?.toLowerCase().includes(bmSearch.toLowerCase())) return true
            if(beerMeet.description?.toLowerCase().includes(bmSearch.toLowerCase())) return true
            else return false
        })
    }
    

	return (
		<div className="flex flex-row h-full">
			{/* Button to trigger the opening of the dialog */}
			<button onClick={handleClickOpen} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                See more info
            </button>
		

			{/* Dialog that is displayed if the state open is true */}
			<Dialog 
                className="p-10"
				open={open}
                maxWidth='lg'
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
                fullScreen
                TransitionComponent={Transition}>	

                <div className="p-5 h-full flex flex-row gap-4">
                    {/* Map with Info */}
                    <div className='h-full w-1/2 flex flex-col'>
                        <div className='h-2/5'>
                            {/* Map */}
                            <MapWithOnePin place={placeInfo} />    
                        </div>
                        <div className='h-3/5'>
                            {/* Info */}
                            <div className="bg-white shadow-md shadow-gray-500 rounded-md p-4 mt-2">      
                                <div>
                                    {/* <p className="text-gray-700 mb-2">{placeInfo.description}</p> */}
                                    <div className="flex flex-col">
                                        <p className="font-bold text-lg mb-2 m-2">{placeInfo.name}</p>
                                        <p className="w-full m-2"><strong>Place Type:</strong> {placeInfo.type}</p>
                                        <p className="w-full m-2"><strong>Location: </strong> {placeInfo.address}</p>
                                        <p className="w-full m-2"><strong>Open Time: </strong> {placeInfo.open_time}</p>
                                        
                                        <p className="w-full  m-2 align-center"><strong>Stars rating: </strong>{placeInfo.avgStars}</p>
                                        <p className='ml-2'><Rating
                                            name="read-only"
                                            precision={0.1}
                                            value={placeInfo.avgStars} 
                                            readOnly
                                        /></p>
                                        
                                        
                                        <p className="w-full m-2"><strong>Opinion count: </strong> {opinionCount}</p>
                                    </div> 
                                </div>
                            </div>
                            
                        </div>
                        <div className='flex flex-row'>
                            <button onClick={handleClose} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Return to map
                            </button>
                            <button onClick={() => setSwitchToBeerMeets(!switchToBeerMeets)} className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                {switchToBeerMeets ? "Show opinions" : "Show BeerMeets"}
                            </button>
                        </div>
                        
                    </div>
                    {/* List of Comments with comment form */}
                    {switchToBeerMeets ? 
                        // List of beerMeets
                        <div className='h-full w-1/2 flex flex-col'>
                            {/* sorting beermeets by name */}
                            <div className="w-full grow bg-white shadow-md shadow-gray-500 rounded-md p-2 mb-2 max-h-20">

                                <input onChange={(e) => setBmSearch(e.target.value)} type="text" name="search" maxLength={50} className="w-full p-2" placeholder="search beermeets"></input>

                            </div>
                            {/* list of beermeets */}
                            <div className="overflow-y-auto no-scrollbar w-full mb-5 ">
                                {
                                    filterBeerMeets(beerMeets).reverse().map((beerMeet) => 
                                        <BeerMeetTile key={beerMeet.id} beerMeet={beerMeet} 
                                    />)
                                }
                            </div>

                        </div> :
                        // List of opinions
                        <div className='h-full w-1/2 flex flex-col'>
                            <div className='w-full'>
                                <PlaceOpinionForm place={place} updateParent={updateData}/>
                            </div>
                            <div className='w-full overflow-auto h-full'>
                            <div className=" max-h-1/2">
                                <div className="flex justify-end m-2 p-2">
                                    {/* sort by stars */}
                                    <p className="p-2 m-2">Show</p>
                                    <select id="order" name="order" onChange={handleChange} className=" p-2 m-2 h-full">
                                        <option value="0">-------</option>
                                        <option value="5">5 stars</option>
                                        <option value="4">4 stars</option>
                                        <option value="3">3 stars</option>
                                        <option value="2">2 stars</option>
                                        <option value="1">1 stars</option>
                                    </select>
                                </div> 

                                <div>
                                    {/* List of places */}
                                    {[...opinions].reverse()
                                        .map((opinion: PlaceOpinion) => (
                                            <PlaceOpinionTile 
                                                key={opinion.id} 
                                                opinion={opinion} 
                                                user={users.find((user) => user.id === opinion.userId) as User}
                                                />
                                        ))}
                                </div> 
                            </div>
                            

                            </div>
                        </div>
                    }
                </div>		
			</Dialog>
		</div>
	);
};

export default PlaceInfoDialog;


