import axiosConfig from "../axiosConfig";
import { Article, ArticleImage } from "@prisma/client";

export const GetArticles = async (): Promise<Article[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/articles",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetUserArticles = async (userId: number): Promise<Article[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/articles/${userId}/articles`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PostArticle = async (
	article: Omit<Article, "id" | "createdAt" | "updatedAt" | "likes" | "dislikes">,
	fileArray: File[]
): Promise<Article> => {
	console.log("post article api call", article);
	console.log("post article api call", fileArray);
	const data_to_send = JSON.stringify(article);
	const formData = new FormData();
	fileArray.forEach((file, index) => {
		formData.append(`files`, file);
	});
	formData.append("data", data_to_send);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/articles",
		headers: {
			"Content-Type": "multiple/form-data",
		},
		data: formData,
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

// edit article
export const EditArticle = async (article: Article):Promise<Article> => {
	let config = {
		method: "put",
		maxBodyLength: Infinity,
		url: `/articles/${article.id}`,
		headers: {
			"Content-Type": "application/json",
		},
		data: JSON.stringify(article),
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("put article error API service", error.response);
		throw new Error("Somethink went wrong");
	}
	
}
// delete article
export const DeleteArticle = async (id: number): Promise<Article> => {
	let config = {
		method: "delete",
		maxBodyLength: Infinity,
		url: "/articles/" + id,
		headers: {
			"Content-Type": "multiple/form-data",
		},
	};

	try {
		const response = await axiosConfig.request(config);

		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
// get article Comments

// get article Images

export const getArticleImages = async (ArticleId: number): Promise<ArticleImage[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/articles/${ArticleId}/images`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		// axios response
		let response = await axiosConfig.request(Config);
		if (response.data === null) {
			return [] as ArticleImage[];
		}
		return response.data as ArticleImage[];
	} catch (error) {
		console.log("error", error);
		throw new Error("Somethink went wrong");
	}
};
