import axiosConfig from "../axiosConfig";
import { Profile } from "@prisma/client";

export const GetProfiles = async (): Promise<Profile[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/beers",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

// nie dodaje do store !!
export const PostProfile = async (beer: Omit<Profile, "id" | "createdAt" | "updatedAt">): Promise<Profile> => {
	let data_to_send = JSON.stringify(beer);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/beers",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
