import { Beer } from "@prisma/client";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import BeerFormTile from "../components/Encyclopedia/BeerFormTile";
import BeerList from "../components/Encyclopedia/BeerList";
import BeerTypeFormTile from "../components/Encyclopedia/BeerTypeFormTile";
import BreweryFormTile from "../components/Encyclopedia/BreweryFormTile";
import HopComponentFormTile from "../components/Encyclopedia/HopComponentFormTile";
import { useParams } from "react-router-dom";
import BeerTile from "../components/Encyclopedia/BeerTile";
import BeerRateTile from "../components/Encyclopedia/BeerRateTile";

const BeerRate: React.FC = () => {
    const params = useParams();
    const beerId: number = parseInt(params.beerId!);
	const beer: Beer | undefined = useSelector((state: RootState) =>
        state.beers.beers.find((beer) => beer.id === beerId)
    )

	if (beer != undefined) return (
        
		<div className="flex mt-4 px-20 w-full mx-auto gap-6">
			<div className="w-1/3 overflow-auto" style={{ height: "70vh" }}>
                <BeerTile key={beer.id} beer={beer} />
			</div>
			<div className="w-2/3 overflow-auto" style={{ height: "70vh" }}>
				<BeerRateTile key={beer.id} beer={beer}/>
			</div>
		</div>
	)
    else return (<></>)
};

export default BeerRate;
