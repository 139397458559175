import React from "react";
import PlaceForm from "../components/Places/PlaceForm";
import { Role, User } from "@prisma/client";
import { useSelector } from "react-redux";
import { RootState } from "../store";

import MapViewForPicker from "../components/Map/PlacePicker/MapViewForPicker";
import PlaceListFilter from "../components/Places/PlaceListFilter";
import PlaceListFiltered from "../components/Places/PlaceListFiltered";

const Places: React.FC = () => {
	let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);

	return (
		<div className="grid grid-cols-2 gap-4 h-full w-full">
			<div className="h-full m-2 p-2">
				<div className="h-2/3">
					 <MapViewForPicker/>
				</div>
				<div className="h-1/3">{current_user?.role === Role.ADMIN ? <PlaceForm /> : <></>}</div>
			</div>

			<div className="h-full">
				{/* list of places with search bar */}
				<div>
					<PlaceListFilter/>
				</div>
				<div>
				<PlaceListFiltered/>	
				</div>
				
				
				
			</div>
		</div>
	);
};

export default Places;
