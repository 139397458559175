import React from "react";
import { ArticleComment, Reaction } from "@prisma/client";
import { howLongAgo } from "../../Services/parsing";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { removeArticleCommentReaction, addArticleCommentReaction } from "../../reducers/articleCommentReactionsReducer";
import {
	DeleteArticleCommentReaction,
	PostArticleCommentReaction,
} from "../../Services/articleCommentReactionsAPICalls";

interface IProps {
	comment: ArticleComment;
}

const ArticleCommentComponent: React.FC<IProps> = (props: IProps) => {
	const dispatch = useDispatch();
	const current_user = useSelector((state: RootState) => state.current_user.current_user);
	const author = useSelector((state: RootState) => state.users.users.find((u) => u.id === props.comment.userId));
	const current_user_like = useSelector((state: RootState) =>
		state.article_comment_reactions.article_comment_reactions.find(
			(acr) =>
				acr.articleCommentId === props.comment.id && acr.userId === current_user?.id && acr.reaction === "LIKE"
		)
	);
	const current_user_dislike = useSelector((state: RootState) =>
		state.article_comment_reactions.article_comment_reactions.find(
			(acr) =>
				acr.articleCommentId === props.comment.id &&
				acr.userId === current_user?.id &&
				acr.reaction === "DISLIKE"
		)
	);
	const likes = useSelector((state: RootState) =>
		state.article_comment_reactions.article_comment_reactions.filter(
			(acr) => acr.articleCommentId === props.comment.id && acr.reaction === "LIKE"
		)
	);
	const dislikes = useSelector((state: RootState) =>
		state.article_comment_reactions.article_comment_reactions.filter(
			(acr) => acr.articleCommentId === props.comment.id && acr.reaction === "DISLIKE"
		)
	);

	const likeComment = () => {
		if (current_user) {
			if (!current_user_like) {
				if (current_user_dislike) {
					//usuń dislike
					DeleteArticleCommentReaction(current_user_dislike.id).then((res) => {
						console.log("delete articlereaction(dislike) res", res);
						dispatch(removeArticleCommentReaction(current_user_dislike.id));
						console.log("deleted", current_user_dislike);
					});
				}
				const r: Reaction = Reaction.LIKE;
				const userId: number = current_user.id;
				const articleCommentId: number = props.comment.id;
				const reaction = { reaction: r, userId, articleCommentId };
				console.log("reaction", reaction);
				PostArticleCommentReaction(reaction).then((res) => {
					console.log("post articlecommentreaction res", res);
					dispatch(addArticleCommentReaction(res));
				});
			} else {
				DeleteArticleCommentReaction(current_user_like.id).then((res) => {
					console.log("delete articlecommentreaction(like) res", res);
					dispatch(removeArticleCommentReaction(current_user_like.id));
					console.log("deleted", current_user_like);
				});
			}
		}
	};

	const dislikeComment = () => {
		if (current_user) {
			if (!current_user_dislike) {
				if (current_user_like) {
					//usuń dislike
					DeleteArticleCommentReaction(current_user_like.id).then((res) => {
						console.log("delete articlecommentreaction(like) res", res);
						dispatch(removeArticleCommentReaction(current_user_like.id));
						console.log("deleted", current_user_like);
					});
				}
				const r: Reaction = Reaction.DISLIKE;
				const userId: number = current_user.id;
				const articleCommentId: number = props.comment.id;
				const reaction = { reaction: r, userId, articleCommentId };
				console.log("reaction", reaction);
				PostArticleCommentReaction(reaction).then((res) => {
					console.log("post articlecommentreaction(dislike) res", res);
					dispatch(addArticleCommentReaction(res));
				});
			} else {
				DeleteArticleCommentReaction(current_user_dislike.id).then((res) => {
					console.log("delete articlecommentreaction(dislike) res", res);
					dispatch(removeArticleCommentReaction(current_user_dislike.id));
					console.log("deleted", current_user_dislike);
				});
			}
		}

		//debug
		if (current_user_like && current_user_dislike) {
			console.log("both liked and disliked, shouldn't happen", current_user_like, current_user_dislike);
		}
	};

	return (
		<div>
			<div className="flex mt-4 p-2 m-2 bg-slate-100 border rounded-md text-wrap">
				{props.comment.content}
				<br />
			</div>
			<div className="grid grid-cols-2 text-sm">
				<div className="text-left">
					{/* <NuxtLink to="/profile/1">{{ users?.find((u) => u.id == userId)?.displayname }}</NuxtLink>, */}
					{/* Likes Dislkies */}
					<button className="gap-2 inline-flex pr-4" onClick={likeComment}>
						<svg
							className="w-5 h-5 text-gray-800 dark:text-white"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<path
								stroke="black"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={current_user_like ? "1.75" : "1"}
								d="M7 11c.9 0 1.4-.5 2.2-1a33.3 33.3 0 0 0 4.5-5.8 1.5 1.5 0 0 1 2 .3 1.6 1.6 0 0 1 .4 1.3L14.7 10M7 11H4v6.5c0 .8.7 1.5 1.5 1.5v0c.8 0 1.5-.7 1.5-1.5V11Zm6.5-1h5l.5.1a1.8 1.8 0 0 1 1 1.4l-.1.9-2.1 6.4c-.3.7-.4 1.2-1.7 1.2-2.3 0-4.8-1-6.7-1.5"
							/>
						</svg>
						{likes.length}
					</button>

					<button className="gap-2 inline-flex" onClick={dislikeComment}>
						<svg
							className="w-5 h-5 text-gray-800 dark:text-white"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							transform="scale(1 -1)"
						>
							<path
								stroke="black"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={current_user_dislike ? "1.75" : "1"}
								d="M7 11c.9 0 1.4-.5 2.2-1a33.3 33.3 0 0 0 4.5-5.8 1.5 1.5 0 0 1 2 .3 1.6 1.6 0 0 1 .4 1.3L14.7 10M7 11H4v6.5c0 .8.7 1.5 1.5 1.5v0c.8 0 1.5-.7 1.5-1.5V11Zm6.5-1h5l.5.1a1.8 1.8 0 0 1 1 1.4l-.1.9-2.1 6.4c-.3.7-.4 1.2-1.7 1.2-2.3 0-4.8-1-6.7-1.5"
							/>
						</svg>
						{dislikes.length}
					</button>
				</div>
				<div className="text-right">
					<span>
						{author ? author.username : "Loading"},{" "}
						<span className="text-gray-400">{howLongAgo(new Date(props.comment.createdAt))} ago</span>
					</span>
				</div>
			</div>
		</div>
	);
};

export default ArticleCommentComponent;
