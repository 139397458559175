import axiosConfig from "../axiosConfig";
import { Beer } from "@prisma/client";
import { addBeer } from "../reducers/beersReducer";

export const GetBeers = async (): Promise<Beer[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/beers",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PostBeer = async (beer: Omit<Beer, "id" | "createdAt" | "updatedAt">): Promise<Beer> => {
	let data_to_send = JSON.stringify(beer);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/beers",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		addBeer(response.data);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
