import React, { useEffect, useRef, useState } from "react";
import { Place, PlaceType } from "@prisma/client";
import PlaceTile from "./PlaceTile";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedPlace, resetSearch, setFilteredPlaces } from "../../reducers/placesReducer";



const PlaceListFilter: React.FC<{}> = () => {
	const placesList: Place[] = useSelector((state: any) => state.places.places);
	const dispatch = useDispatch();
	const [sortData, setSortData] = useState({
		searchString: "",
		order: "asc",
		type: "NONE",
		stars: "NONE",
	});

	useEffect(() => {
		
		filterAndSort();
	
	},[placesList, sortData]);


	const filterAndSort = () => {
		let updatedList:Place[] = [...placesList];
		if(sortData.searchString===""){
			updatedList = placesList
		}
		else{
			updatedList = updatedList.filter((place: Place) => (
				// searching by string and place type
				   place.name.toLowerCase().includes(sortData.searchString.toLocaleLowerCase()) 
				|| place.description.toLocaleLowerCase().includes(sortData.searchString.toLocaleLowerCase()) 
				|| place.address.toLocaleLowerCase().includes(sortData.searchString.toLocaleLowerCase()) 
				|| place.open_time.toLocaleLowerCase().includes(sortData.searchString.toLocaleLowerCase()) 				
			));
		};


		if(sortData.type!=="NONE"){
			updatedList = updatedList.filter((place: Place) => (
				place.type === sortData.type
			))
		}

		if(sortData.stars!=="NONE"){
			if(sortData.stars === "asc"){
				updatedList = [...updatedList].sort((a:Place, b:Place) => a.avgStars > b.avgStars ? 1 : -1);
			}else{
				updatedList = [...updatedList].sort((a:Place, b:Place) => a.avgStars < b.avgStars ? 1 : -1);
			}
			
		}
		dispatch(setFilteredPlaces(updatedList));
		dispatch(clearSelectedPlace());

	}


	const handleChange = (event: any) => {
		const { name, value } = event.target;
		console.log(name, value);
		setSortData((prevData) => ({
			...prevData,
			[name]: value
		}))
		
		// filterAndSort();
		// console.log("sortData", sortData);
	}

	const Clear = (e) => {
		e.preventDefault();
		var form:HTMLFormElement= document.getElementById("form") as HTMLFormElement;
		setSortData({
			searchString: "",
			order: "asc",
			type: "NONE",
			stars: "0",
		})
		dispatch(resetSearch());
		form.reset();
	}

	return (
			<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 m-2 mr-6">
				{/* Searching and filtering */}    
				<form id="form">
					<div className="flex flex-row">
						<div className="flex flex-col p-2 m-2">
							<p className="p-3">Search Places</p>
							
							<input
								type="text"
								id="searchString"
								name="searchString"
								onChange={handleChange}
								placeholder={`search phrase`}
								maxLength={100}
								className=" grow p-3"
							></input>
						
						</div>	
						{/* <div className="flex flex-col w-30 p-2 m-2">
							<p className="p-3 h-full ">Order</p>
							<select id="order" name="order" onChange={handleChange} className=" p-2 m-2 h-full">
								<option value="NONE">---</option>
								<option value="asc">asc</option>
								<option value="desc">desc</option>
							</select>
						</div> */}
						
						<div className="flex flex-col w-30 m-2 p-2">
							{/* sort by stars */}
							<p className="p-3">Raiting</p>
							<select id="stars" name="stars" onChange={handleChange} className=" p-2 m-2 h-full">
								<option value="NONE">-------</option>
								<option value="asc">asc</option>
								<option value="desc">desc</option>
								{/* <option value="5">5 stars</option>
								<option value="4">4 stars</option>
								<option value="3">3 stars</option>
								<option value="2">2 stars</option>
								<option value="1">1 stars</option> */}
								
								
							</select>
						</div> 


						<div className="flex flex-col p-2 m-2">
							<p className="p-3">Place type </p>
							<select id="type" name="type" onChange={handleChange} className=" p-2 m-2">
								<option key={"None"} value={"NONE"}>NONE</option>
								{Object.keys(PlaceType).map((type: string) => (
									<option key={type} value={type}>
										{type}
									</option>
								))}
							</select>
						</div>
						<div className="flex flex-col p-2 m-2">
							<button onClick={Clear} className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m" >
								Clear 
							</button>
						</div>
						
					</div>
					{/* <div className="flex flex-row-reverse">
					<button onClick={Clear} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m" >
								Clear 
					</button>
					</div> */}
					
				</form>
			</div>
		
	);
};

export default PlaceListFilter;
