import { Beer, BeerMeet, BeerOpinion, User } from "@prisma/client";
import axiosConfig from "../axiosConfig";
import { Interface } from "readline";
import exp from "constants";


export const getBeerMeets = async(): Promise<BeerMeet[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/beer_meets",
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

export const getBeerMeet = async(id: number): Promise<BeerMeet> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/beer_meets/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

export const postBeerMeet = async(
    beerMeet,
    participants: number[]): Promise<BeerMeet> => {
    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/beer_meets",
        headers: {
            "Content-Type": "application/json",
        },
        data: {beerMeet, participants}
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Post: Something went wrong");
    }
}

export const updateBeerMeet = async(beerMeet: BeerMeet): Promise<BeerMeet> => {
    let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `/beer_meets/${beerMeet.id}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: beerMeet
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Put: Something went wrong");
    }
}   

export const deleteBeerMeet = async(id: number): Promise<void> => {
    let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `/beer_meets/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    }   

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Delete: Something went wrong");
    }
}


// get beermeet participants

export const getBeerMeetParticipants = async(id: number): Promise<User[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/beer_meets/${id}/participants`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

// get beermeets for place

export const getBeerMeetsByPlaceId = async(placeId: number): Promise<BeerMeet[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/beer_meets/place/${placeId}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

// get users created beermeets

export const getCreatedBeerMeets = async(ownerId: number): Promise<BeerMeet[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/beer_meets/owner/${ownerId}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

// get beermeets which user participated


