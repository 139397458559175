import React, { useEffect, useState } from "react";
import MessageFormTile from "../components/Chats/MessageFormTile";
import MessageTile from "../components/Chats/MessageTile";
import { Chat, ChatUser, Message, User } from "@prisma/client";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import StartChatTile from "../components/Chats/StartChatTile";
import ChatTile from "../components/Chats/ChatTile";
import { howLongAgo } from "../Services/parsing";
import ChatEditDialog from "../components/Chats/ChatEditDialog";
import ChooseUserDialog from "../components/Chats/ChooseUserDialog";
import { addChatUser } from "../reducers/chatUsersReducer";
import { PostChatUser } from "../Services/chatUsersAPICalls";
import { socket } from "../socket";

// id chatu w sciezce,
// lista po lewej to linki do chatow

// przed zaczeciem jakichkolwiek czatow lista znajomych i po kliknieciu na znajomego tworzony jest czas, a znajomy sie juz na liscie nie pojawia
// czaty sa wyswietlane przed znajomymi
// dodac

//TODO powiadomienia o nowych wiadomosciach (alert)
//TODO kafelki z zaczynaniem czatow tylko pod przyjaciol

const Messenger: React.FC = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const [settings, setSettings] = useState<boolean>(false);
	const chatId: number = parseInt(params.chatId!);
	const current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);
	const messages: Message[] = useSelector((state: RootState) =>
		state.messages.messages.filter((message) => message.chatId === chatId)
	);

	// chaty w ktorych jest uzytkownik
	// findLast jest, kij wie o co sie spina
	const chats: Chat[] = useSelector((state: RootState) =>
		state.chats.chats
			.filter((chat) => {
				return state.chat_users.chat_users
					.filter((chat_user) => chat_user.userId === current_user?.id)
					.map((chat_user) => chat_user.chatId)
					.includes(chat.id);
			})
			.sort((a: Chat, b: Chat) => {
				const last_a: Message | undefined = state.messages.messages.findLast(
					(message: any) => message.chatId === a.id
				);
				const last_b: Message | undefined = state.messages.messages.findLast(
					(message: any) => message.chatId === b.id
				);

				if (last_a && last_b) {
					return last_a.createdAt > last_b.createdAt ? -1 : 1;
				} else {
					return 0;
				}
			})
	);

	// znajomi bez czatow, do zakladania nowych czatow
	// aktualnie sciaga kazdego bez czatu
	const friends_without_chats: User[] = useSelector((state: RootState) =>
		state.users.users.filter((user) => {
			if (user.id === current_user?.id) {
				return false;
			}
			let c_user_chats = state.chat_users.chat_users.filter((chat_user) => chat_user.userId === current_user?.id);
			let smth = state.chat_users.chat_users
				.filter((chat_user) => {
					return chat_user.userId === user.id && c_user_chats.map((c) => c.chatId).includes(chat_user.chatId);
				})
				.map((chat_user) => {
					return chat_user.userId;
				});
			return !smth.includes(user.id);
		})
	);

	const chat: Chat | undefined = useSelector((state: RootState) =>
		state.chats.chats.find((chat) => chat.id === chatId)
	);
	const all_users: User[] = useSelector((state: RootState) => state.users.users);
	const all_chat_users: ChatUser[] = useSelector((state: RootState) => state.chat_users.chat_users);
	const [chat_users, set_chat_users] = useState<User[]>([]);

	useEffect(() => {
		set_chat_users(
			all_users.filter((user) => {
				let userIds = all_chat_users
					.filter((chat_user) => chat_user.chatId === chatId)
					.map((chat_user) => chat_user.userId);
				let answ = userIds.includes(user.id);

				return answ;
			})
		);
	}, [all_chat_users, all_users, chat, chatId]);

	const selectedUser: User | null = useSelector((state: RootState) => state.users.selectedUser);

	const addUserToChat = () => {
		if (selectedUser) {
			PostChatUser({ chatId: chatId, userId: selectedUser.id })
				.then((response) => {
					return response;
				})
				.then((chat_user) => {
					socket.emit("new_chat_user", chat_user);
					dispatch(addChatUser(chat_user));
				});
		}
		setSettings(false);
	};

	return (
		<>
			<div className="flex mt-4 max-w-4xl mx-auto gap-6">
				<div
					className="w-1/4 bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 overflow-y-scroll"
					style={{ height: "70vh" }}
				>
					{settings ? (
						chat ? (
							<div className="rounded-md mb-5 p-2 w-full">
								<ChatEditDialog chat={chat}></ChatEditDialog>
								<ChooseUserDialog action={addUserToChat}></ChooseUserDialog>
							</div>
						) : null
					) : (
						<>
							{chats.map((chat) => {
								return <ChatTile chat={chat}></ChatTile>;
							})}
							{friends_without_chats.map((friend) => {
								return <StartChatTile user={friend} />;
							})}
						</>
					)}
				</div>

				<div className="w-3/4">
					<div
						className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 w-full"
						style={{ height: "70vh" }}
					>
						<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 grid grid-cols-2">
							<div>
								{chat ? (
									<button
										onClick={() => {
											settings ? setSettings(false) : setSettings(true);
										}}
									>
										{/* Gear wheel 6-cog */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											className="w-6 h-6"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
											/>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
											/>
											{settings ? (
												<path d="M10 10 L20 20 M10 20 L20 10" stroke="red" fill="none" />
											) : (
												<></>
											)}
										</svg>
									</button>
								) : null}
							</div>
							<div className="text-right">
								{" "}
								{chat
									? chat.name
										? chat.name
										: chat_users.map((cu) => {
												return <>{cu.username + " "}</>;
										  })
									: "Select or start new chat!"}{" "}
							</div>
						</div>
						<div className="overflow-y-scroll h-3/5">
							{messages.map((mess) => (
								<>
									<MessageTile message={mess} key={mess.id} />
									<p className="text-right text-sm text-gray-400 mr-3 mb-4">
										{" "}
										sent {howLongAgo(new Date(mess.createdAt))} ago
									</p>
								</>
							))}
						</div>

						{chat ? (
							<>
								<MessageFormTile />
							</>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default Messenger;
