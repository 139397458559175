import axiosConfig from "../axiosConfig";
import { HopComponent } from "@prisma/client";
import { addHopComponent } from "../reducers/hopComponentsReducer";

export const GetHopComponents = async (): Promise<HopComponent[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/hop_components",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetHopComponentById = async (id: number): Promise<HopComponent> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/hop_components/${id}`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};


export const PostHopComponent = async (
	hop_component: Omit<HopComponent, "id" | "createdAt" | "updatedAt">
): Promise<HopComponent> => {
	let data_to_send = JSON.stringify(hop_component);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/hop_components",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		addHopComponent(response.data);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
