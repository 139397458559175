import axiosConfig from "../axiosConfig";
import { BeerType } from "@prisma/client";
import { addBeerType } from "../reducers/beerTypesReducer";

export const GetBeerTypes = async (): Promise<BeerType[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/beer_types",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetBeerTypeById = async (id: number): Promise<BeerType> => {
	let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/beer_types/${id}`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
}

export const PostBeerType = async (beer_type: Omit<BeerType, "id" | "createdAt" | "updatedAt">): Promise<BeerType> => {
	let data_to_send = JSON.stringify(beer_type);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/beer_types",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		addBeerType(response.data);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};


