import axiosConfig from "../axiosConfig";
import { ChatUser } from "@prisma/client";
import { addChatUser } from "../reducers/chatUsersReducer";

export const GetChatUsers = async (): Promise<ChatUser[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/chat_users",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PostChatUser = async (chat_user: Omit<ChatUser, "id" | "createdAt" | "updatedAt">): Promise<ChatUser> => {
	let data_to_send = JSON.stringify(chat_user);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/chat_users",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		addChatUser(response.data);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
