import { TransitionProps } from '@mui/material/transitions';
import { Beer, BeerOpinion, BeerType, Brewery, HopComponent } from '@prisma/client';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Slide from '@mui/material/Slide';
import { getBeerOpinionByBeerId } from '../../Services/beerOpinionAPICalls';
import { GetBeerTypeById } from '../../Services/beerTypesAPICalls';
import { GetBreweryById } from '../../Services/breweriesAPICalls';
import { GetHopComponentById } from '../../Services/hopComponentsAPICalls';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';

interface IProps {
    beer: Beer
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export const BeerInfoDialog: React.FC<IProps> = (props: IProps) => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
    	setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

    const [beer_opinions, setBeer_opinions] = React.useState<BeerOpinion[]>([]);
    const [this_beer_info, setBeerInfo] = React.useState<Beer>(props.beer);
    const [this_beer_type, setBeerType] = React.useState<BeerType>();
    const [this_beer_brewery, setBrewery] = React.useState<Brewery>();
    const [this_beer_hop_components, setHop_component] = React.useState<HopComponent>();

    const getBeerData = async () => {
        try{
            const opinions: BeerOpinion[] = await getBeerOpinionByBeerId(props.beer.id);
            const beer_type: BeerType = await GetBeerTypeById(props.beer.typeId);
            const beer_brewery: Brewery = await GetBreweryById(props.beer.breweryId);
            const beer_hop_components: HopComponent = await GetHopComponentById(props.beer.hopComponentId);

            setBeer_opinions(opinions);
            setBeerType(beer_type);
            setBrewery(beer_brewery);
            setHop_component(beer_hop_components);
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        getBeerData();
    }, [])

    return(
        <div className="flex flex-row h-full">
            {/* Button to trigger the opening of the dialog */}
			<button onClick={handleClickOpen} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                See more info
            </button>

            <Dialog 
                className="p-10"
				open={open}
                maxWidth='lg'
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
                fullScreen
                TransitionComponent={Transition}>	
            
            </Dialog>
        </div>
    )
}

export default BeerInfoDialog