import type { Location, Place } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LocationState {
	locations: Location[];
	init_status: true | false;
}

const initialState: LocationState = {
	init_status: true,
	locations: [],
};

export const locationSlice = createSlice({
	name: "locations",
	initialState,
	reducers: {
		// setArtciles populates the list
		setLocations(state, action: PayloadAction<Location[]>) {
			if (state.init_status) {
				state.locations = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for users
		addLocation(state, action: PayloadAction<Location>) {
			state.locations.push(action.payload);
		},
		removeLocation(state, action: PayloadAction<number>) {
			state.locations = state.locations.filter((loc) => loc.id !== action.payload);
		},
		updateLocation(state, action: PayloadAction<Location>) {
			let index = state.locations.findIndex((loc) => loc.id === action.payload.id);
			state.locations[index] = action.payload;
		},
	},
	selectors: {
		getLocations: (state) => state.locations,
		getLocationForPlace: (state, action: PayloadAction<Place>): Location =>
			state.locations.find((loc) => loc.id === action.payload.locationId) as Location,
	},
});

export const { addLocation, removeLocation, updateLocation, setLocations } = locationSlice.actions;
export default locationSlice.reducer;
