import { faCampground } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pin } from "@vis.gl/react-google-maps";
import React from "react";

const OutdoorBlue: React.FC = () => {

    return (
        <Pin background={'#00BFFF'} glyphColor={'#000'} borderColor={'#000'} >
			<FontAwesomeIcon icon={faCampground} />
		</Pin>
    )
}
export default OutdoorBlue