import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { Article, BeerMeet, BeerMeetType, Place, Status, User } from "@prisma/client";
import ArticleList from "../Articles/ArticleList";
import Tile from "../Layout/Tile";
// import ProfileTile from "../Profiles/ProfileTile";
import CalendarTile from "../Profiles/CalendarTile";
import PostsTile from "../Profiles/PostsTile";

// pozyskanie id ze sciezki, np. /profile/1
// useSelector do pobrania profilu ze store

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import * as dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import ChoosePlaceDialog from "../Places/ChoosePlaceDialog";
import { clearSelectedPlace } from "../../reducers/placesReducer";
import { toastError, toastSuccess } from "../../Services/toastify";
import { Cookies, useCookies } from "react-cookie";
import ChooseUsersDialog from "../Users/ChooseUsersDialog";
import { postBeerMeet } from "../../Services/beerMeetsAPICalls";
import { clearSelectedParticipants, clearSelectedUser } from "../../reducers/usersReducer";
dayjs().format();

const BeerMeetForm: React.FC = () => {
	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		startingAt: "",
		endingAt: "",
		type: BeerMeetType.OPEN,
		placeId: "",
	});
	// dayjs('2022-04-17T15:30')
	const [valueStart, setValueStart] = React.useState<Dayjs | null>(null);
	const [valueEnd, setValueEnd] = React.useState<Dayjs | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	let cUserId: number | null = cookies.id ? cookies.id : null;

	const selPlace: null | Place = useSelector((state: RootState) => state.places.selectedPlace);
	const selUsers: number[] = useSelector((state: RootState) => state.users.selectedParticipants);

	const handleChange = (event: any) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		// console.log(formData);
		// console.log(valueStart)
		// console.log(valueEnd)
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		if (cUserId === null) {
			toastError("Please log in to add Meets");
			return;
		}

		if (formData.name === "") {
			toastError("Please fill out name for meet");
			return;
		}
		if (valueStart === null) {
			toastError("Please fill out start date for meet");
			return;
		}
		if (valueEnd === null) {
			toastError("Please fill out end date for meet");
			return;
		}
		if (!valueEnd.isAfter(valueStart)) {
			toastError("End date must be after start date");
			return;
		}

		setIsLoading(true);
		try {
			const newMeet = {
				name: formData.name,
				description: formData.description,
				// startingAt : valueStart.toISOString(),
				// endingAt : valueEnd.toISOString(),
				startingAt: valueStart.toDate(),
				endingAt: valueEnd.toDate(),
				placeId: selPlace ? selPlace.id : null,
				ownerId: cUserId,
				type: formData.type,
			};
			const usersForMeet: number[] = [...selUsers, cUserId];
			console.log("usersForMeet", usersForMeet);
			const submitResponse = await postBeerMeet(newMeet, usersForMeet);
			console.log(submitResponse);

			console.log("newMeet", newMeet);
			toastSuccess("BeerMeet submitted");
		} catch (err) {
			console.error(err);
			toastError("Error while submitting meet");
		}
		event.target.reset();
		setFormData({
			name: "",
			description: "",
			startingAt: "",
			endingAt: "",
			type: BeerMeetType.OPEN,
			placeId: "",
		});
		setValueStart(null);
		setValueEnd(null);
		dispatch(clearSelectedPlace());
		dispatch(clearSelectedParticipants());
		setIsLoading(false);

		const choosePlace = (placeId: number) => {
			console.log("placeId", placeId);
			setFormData({ ...formData, placeId: placeId.toString() });
		};
	};
	return (
		<div className="flex mt-4 gap-2 h-full w-full mx-auto">
			<form onSubmit={handleSubmit} className="flex flex-col">
				<div className="text-2xl p-2 m-2">Create your BeerMeet</div>
				<input
					className="p-2 m-2"
					type="text"
					name="name"
					onChange={handleChange}
					placeholder="Name of the meet"
					maxLength={100}
				></input>
				<input
					className="p-2 m-2"
					type="text"
					name="description"
					onChange={handleChange}
					placeholder="Description"
					maxLength={600}
				></input>
				{/* dates */}
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<div className="p-2 m-2">
						<DateTimePicker
							label="Meet start"
							value={valueStart}
							onChange={(newValue) => setValueStart(newValue)}
						/>
					</div>
					<div className="p-2 m-2">
						<DateTimePicker
							label="Meet end"
							value={valueEnd}
							onChange={(newValue) => setValueEnd(newValue)}
						/>{" "}
					</div>
				</LocalizationProvider>

				{/* place */}
				<div className="flex flex-row">
					<ChoosePlaceDialog />
					{selPlace ? (
						<div>
							<label className={"bg-black text-white px-10 py-2 rounded-md m-2"}>{selPlace.name}</label>
							<button
								className={"bg-black text-white px-10 py-2 rounded-md m-2"}
								onClick={() => dispatch(clearSelectedPlace())}
							>
								X
							</button>
						</div>
					) : (
						<></>
					)}
				</div>
				{/* participants invitations */}

				<div className="flex flex-row">
					<ChooseUsersDialog />

					<label className={"bg-black text-white px-10 py-2 rounded-md m-2"}>
						Selected Users: {selUsers.length > 0 ? selUsers.length : 0}
					</label>
				</div>
				{/*  */}

				{/* type */}
				<div className="flex p-2 m-2">
					<label htmlFor="type" className="m-2 mt-auto mb-auto">
						Choose place type{" "}
					</label>
					<select
						id="type"
						name="type"
						onChange={handleChange}
						className="bg-black border text-white rounded-md m-2 focus:ring-blue-500 focus:border-blue-500 block p-2 m-2 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					>
						{Object.keys(BeerMeetType).map((type: string) => (
							<option key={type} value={type}>
								{type}
							</option>
						))}
					</select>
				</div>

				{/* participants invitations */}

				<div className="flex flex-row">
					<ChooseUsersDialog />

					<label className={"bg-black text-white px-10 py-2 rounded-md m-2"}>
						Selected Users: {[selUsers].length > 0 ? [selUsers].length : 0}
					</label>
				</div>
				{/*  */}

				<button type="submit" className={"bg-black text-white px-10 py-2 rounded-md m-2"} disabled={isLoading}>
					Create BeerMeet
				</button>
			</form>
		</div>
	);
};

export default BeerMeetForm;
