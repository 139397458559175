import type { Chat } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ChatState {
	chats: Chat[];
	init_status: true | false;
}

const initialState: ChatState = {
	init_status: true,
	chats: [],
};

export const chatSlice = createSlice({
	name: "chats",
	initialState,
	reducers: {
		// setChats populates the list
		setChats(state, action: PayloadAction<Chat[]>) {
			if (state.init_status) {
				state.chats = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for chats
		addChat(state, action: PayloadAction<Chat>) {
			state.chats.push(action.payload);
		},
		removeChat(state, action: PayloadAction<number>) {
			state.chats = state.chats.filter((chat) => chat.id !== action.payload);
		},
		updateChat(state, action: PayloadAction<Chat>) {
			let index = state.chats.findIndex((chat) => chat.id === action.payload.id);
			state.chats[index] = action.payload;
		},
	},
});

export const { addChat, removeChat, updateChat, setChats } = chatSlice.actions;
export default chatSlice.reducer;
