import React, { useEffect, useState } from "react";
import ArticleList from "../components/Articles/ArticleList";

import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Article, Profile, Status, User } from "@prisma/client";
import ArticleFormTile from "../components/Articles/ArticleFormTile";
import LoginFormTile from "../components/Users/LoginFormTile";
import { useCookies } from "react-cookie";
import UsersGridList from "../components/Users/UsersGridList";
import { GetUsers, getMutualFriends } from "../Services/userAPICalls";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetProfiles } from "../Services/profilesAPICalls";

const Users: React.FC = () => {
	const navigate = useNavigate();
	const [users, setUsers] = useState<User[]>([]);
	const [profiles, setProfiles] = useState<Profile[]>([]);
	const [usersMF, setUsersMF] = useState<number[]>([]);
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);
	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const users: User[] = await GetUsers();
				setUsers([...users].filter((user) => user.id !== cookies.id));
				const user = users.find((user) => user.id === cookies.id);
				if (user) setCurrentUser(user);

				console.log("users fetching reposnse ", users);
			} catch (error) {
				console.log("error fetching users", error);
			}
		};

		const fetchProfiles = async () => {
			try {
				const profiles: Profile[] = await GetProfiles();
				setProfiles([...profiles].filter((profile) => profile.userId !== cookies.id));
				const profile = profiles.find((profile) => profile.userId === cookies.id);
				if (profile) setCurrentProfile(profile);

				console.log("users fetching reposnse ", users);
			} catch (error) {
				console.log("error fetching users", error);
			}
		};

		fetchUsers();
		fetchProfiles();
	}, []);

	const [search, setSearch] = useState<string>("");

	const handleChange = (event: any) => {
		setSearch(event.target.value);
	};

	return (
		<div className="flex mt-4 gap-6 fixed h-5/6 w-full mx-auto">
			<div className="w-1/6 bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2  ml-auto">
				<p>nadchodzace beermeety do ktorych uzytkownik moglby dolaczyc</p>
			</div>

			<div className="w-1/2 h-full flex flex-col max-w-2xl">
				<div className="w-full grow bg-white shadow-md shadow-gray-500 rounded-md p-2 mb-2">
					<input
						type="text"
						name="search"
						onChange={handleChange}
						maxLength={50}
						className="w-full p-2"
						placeholder="search users"
					></input>
				</div>
				<div className="overflow-y-auto no-scrollbar w-full mb-5">
					<div className="grid gap-4 grid-flow-row grid-cols-1 bg-white shadow-md shadow-gray-500 rounded-md p-2">
						{[...users]
							.filter((user) => {
								if (search === "") return true;
								else return user.username?.includes(search);
							})
							.map((user, index) => (
								<div
									key={user.id}
									className=" cursor-pointer"
									onClick={() => navigate(`/profile/${user.id}`)}
								>
									<div className="flex flex-row">
										<Avatar
											variant="square"
											sx={{ width: 80, height: 80 }}
											alt={user.username ? user.username : ""}
											src={user?.avatarpath ? user.avatarpath : "http://www.w3.org/2000/svg"}
										/>
										<div className="flex flex-col">
											<div className="pl-1">{user.username}</div>
											<div className="pl-1">
												BM count:{" "}
												{
													profiles.find((p) => {
														p.userId = user.id;
													})?.beerMeetsParticipated
												}{" "}
											</div>
											{/* <div className="pl-1">{() => fetchMutualFriends(user.id, cookies.id)} mutual Friends</div> */}
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
			<div className="w-1/6 bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2  mr-auto">
				<div className="h-full">Cos</div>
			</div>
		</div>
	);
};

export default Users;
