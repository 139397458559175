import { useSelector } from "react-redux";
import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Socket } from "socket.io-client";
import { RootState } from "../store";
import { Message, User, UserDetail } from "@prisma/client";
import { toast } from "react-toastify";
import { socket } from "../socket";
import { GetUserDetailByUser } from "../Services/userDetailsAPICalls";

type NotifyMessage = {
	message: Message;
	from: User;
	to: number[];
};

const BasicLayout = () => {
	const currentUser = useSelector((state: RootState) => state.current_user.current_user);
	const [userDetail, setUserDetail] = useState<UserDetail | null>(null);

	useEffect(() => {
		const fetchUserDetail = async () => {
			if (currentUser) {
				try {
					console.log("CURRENT USER ID", currentUser.id);
					await GetUserDetailByUser(currentUser.id)
						.then((ud) => {
							setUserDetail(ud);
						})
						.finally(() => {
							console.log("user detail fetching reposnse ", userDetail);
						});
				} catch (error) {
					console.log("error fetching user detail", error);
				}
			}
		};

		fetchUserDetail();
	}, [currentUser]);

	useEffect(() => {
		if (userDetail?.chatNotifications) {
			socket.on("notify_end", (mess: NotifyMessage) => {
				toast("new message from " + mess.from.username);
			});
		}
		return () => {
			socket.off("notify_end");
		};
	}, [userDetail]);

	return (
		<>
			<div className="flex flex-col h-screen">
				<div className="flex bg-red-100">
					<Navbar />
				</div>
				<div className="flex flex-1 bg-gray-100">
					<Outlet />
				</div>
				<div className="flex bg-blue-100">
					<Footer />
				</div>
			</div>
		</>
	);
};

export default BasicLayout;
export type { NotifyMessage };
