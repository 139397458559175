import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Avatar, Button } from "@mui/material";
import MapViewForPicker from '../Map/PlacePicker/MapViewForPicker';
import { useDispatch, useSelector} from 'react-redux';
import { resetSearch } from '../../reducers/placesReducer';
import PlaceListFilter from './PlaceListFilter';
import PlaceListFiltered from './PlaceListFiltered';
import { RootState } from '../../store';
import { clearSelectedParticipants, clearSelectedUser, setSelectedParticipants } from '../../reducers/usersReducer';
import { User } from '@prisma/client';
import { GetUsers } from '../../Services/userAPICalls';
import { ListUserFriends } from '../../Services/invitesAPICalls';
import { useCookies } from 'react-cookie';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ChooseUsersDialog: React.FC = () => {
	const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
    let cUserId: number = cookies.id ? cookies.id : null;

    const [userFriends, setUserFriends] = React.useState<User[]>([]);
    const [users, setUsers] = React.useState<User[]>([]);
    const [selUsers, setSelUsers] = React.useState<User[]>([]);

    const [friendsSearch, setFriendsSearch] = React.useState<string>("");
    const [usersSearch, setUsersSearch] = React.useState<string>("");


	const handleClickOpen = () => {
        dispatch(clearSelectedParticipants());
		setOpen(true);
	};
	const handleClose = () => {
        dispatch(clearSelectedParticipants());
        setSelUsers([]);
		setOpen(false);
	};

	const handleAgree = () => {
        dispatch(setSelectedParticipants(selUsers.map((u) => u.id)));
		setOpen(false);
	};




    React.useEffect(() => {
        const fetchUsers = async () => {
            try {
                const users: User[] = await GetUsers();

                setUsers(users.filter((u) => u.id !== cUserId));
                const friends: User[] = await ListUserFriends(cUserId);
                setUserFriends(friends);
            } catch (error) {
                console.log("error fetching users", error);
            }
        }

        fetchUsers();
    }, []);

    const addToSelected = (user: User) => {
        if(selUsers.includes(user)) return
        setSelUsers([...selUsers, user]);
    }
    const removeSelected = (user: User) => {
        setSelUsers(selUsers.filter((u) => u.id !== user.id));
    }
    const handleFSChange = (event: any) => {
        setFriendsSearch(event.target.value);
        console.log(friendsSearch)
    }
    const handleUSChange = (event: any) => {
        setUsersSearch(event.target.value);
    }

	return (
		<div className="flex flex-row h-full">
			{/* Button to trigger the opening of the dialog */}
			
			<button
                        type="button"
						className={"bg-black text-white px-10 py-2 rounded-md m-2"}
                        onClick={handleClickOpen}
					>
						Choose Participants
			</button>
			
			
			

			{/* Dialog that is displayed if the state open is true */}
			<Dialog className="p-10"
				open={open}
                maxWidth='md'
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
                fullScreen
                TransitionComponent={Transition}
			>	
                <div className="h-full w-full no-scrollbar ">
                    {/* Info */}
                    <div className='flex flex-col mt-5 grid-cols-2 gap-4 h-full w-full'>
                        <div className='w-full h-1/4 mb-10 p-2 m-2'>
                            <h1 className='text text-2xl'>Select Users for this meet</h1>        
                            <div>Selected users: </div>
                            <div className='grid grid-cols-10 gap-4 overflow-y-auto'>
                                {[...selUsers].map((user, index) => (
                                    <div onClick={() => removeSelected(user)} key={index} className='bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2'>
                                    <div className='flex flex-row text'>
                                        <Avatar alt={user.username ? user.username : ""} src={user?.avatarpath ? user.avatarpath : "http://www.w3.org/2000/svg"} />
                                        <div className='ml-2 mt-auto mb-auto'>{user.username}</div>

                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                        <div className='grid grid-cols-2 w-full h-2/3'>
                            <div className='w-full p-2 m-2'>
                                <div className='flex flex-col w-full'>
                                    <div className='mt-auto mb-auto text-2xl'>
                                        Friends
                                    </div>
                                    <div className='p-2 m-2 w-full'>
                                        Sort: <input className='p-2 w-full' placeholder='search friends' type='text' name='text' onChange={handleFSChange}></input>
                                    </div>
                                </div>
                                
                                <div className='grid grid-cols-3 gap-4'>
                                {[...userFriends]
                                .filter((user) => user.username?.toLowerCase().includes(friendsSearch.toLowerCase()))
                                .filter((user) => !selUsers.includes(user))
                                .map((user, index) => (
                                    <div onClick={() => addToSelected(user)} key={index} className='bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2'>
                                        <div className='flex flex-row text'>
                                            <Avatar alt={user.username ? user.username : ""} src={user?.avatarpath ? user.avatarpath : "http://www.w3.org/2000/svg"} />
                                            <div className='ml-2 mt-auto mb-auto'>{user.username}</div>

                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                            <div className='p-2 m-2'>
                                <div className='flex flex-col'>
                                    <div className='mt-auto mb-auto text-2xl'>
                                        All users
                                    </div>
                                    <div className='p-2 m-2 w-full'>
                                        Sort: <input className='p-2 w-full' placeholder='search users' type='text' name='text' onChange={handleUSChange}></input>
                                    </div>
                                </div>
                                
                                
                                <div className='grid grid-cols-3 gap-4'>
                                {[...users]
                                .filter((user) => user.username?.toLowerCase().includes(usersSearch.toLowerCase()))
                                .filter((user) => !selUsers.includes(user))
                                .map((user, index) => (
                                    <div onClick={() => addToSelected(user)} key={index} className='bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2'>
                                        <div className='flex flex-row text'>
                                            <Avatar alt={user.username ? user.username : ""} src={user?.avatarpath ? user.avatarpath : "http://www.w3.org/2000/svg"} />
                                            <div className='ml-2 mt-auto mb-auto'>{user.username}</div>

                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                        
                    </div>  				
                    {/* Action Buttons */}
                    <div className='flex flex-row-reverse m-2'>
                        
                        {/* <button onClick={handleClose} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Cancel
                        </button>
                        <button onClick={handleAgree} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Choose Selected
                        </button> */}
                    </div>
                </div>		
                <DialogActions>
                    <Toolbar>
                        <button onClick={handleClose} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Cancel
                        </button>
                        <button onClick={handleAgree} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Choose Selected
                        </button>
                    </Toolbar>
                </DialogActions>		
			</Dialog>
		</div>
	);
};

export default ChooseUsersDialog;
