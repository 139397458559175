import { Chat, User } from "@prisma/client";
import React, { MouseEvent } from "react";
import { useCookies } from "react-cookie";
import { PostChat } from "../../Services/chatsAPICalls";
import { PostChatUser } from "../../Services/chatUsersAPICalls";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { addChat } from "../../reducers/chatsReducer";
import { response } from "express";
import { addChatUser } from "../../reducers/chatUsersReducer";
import { useNavigate } from "react-router-dom";
import { socket } from "../../socket";

interface IProps {
	user: User;
}

const StartChatTile: React.FC<IProps> = (props: IProps) => {
	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
	const userId: number = parseInt(cookies.id);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const changeChat = (id: number) => {
		console.log("change chat to", id);
		navigate(`/messenger/${id}`);
	};

	const createChat = async (e: MouseEvent<HTMLDivElement>) => {
		let new_chat: Chat;
		console.log("create chat with user id", props.user.id);
		PostChat()
			.then((response) => {
				return response;
			})
			.then((chat) => {
				new_chat = chat;
				console.log("chat created", new_chat);
				dispatch(addChat(new_chat));
				socket.emit("new_chat", new_chat);
				PostChatUser({ chatId: new_chat.id, userId: props.user.id })
					.then((response) => {
						return response;
					})
					.then((chat_user) => {
						socket.emit("new_chat_user", chat_user);
						dispatch(addChatUser(chat_user));
					});
				PostChatUser({ chatId: new_chat.id, userId: userId })
					.then((response) => {
						return response;
					})
					.then((chat_user) => {
						socket.emit("new_chat_user", chat_user);
						dispatch(addChatUser(chat_user));
					});
				changeChat(new_chat.id);
			})
			.catch((error) => {
				console.log("error creating chat", error);
			});
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			{/* @click="changeChat(friend.id)" */}
			<div className="p-2 hover:font-semibold" onClick={createChat}>
				<div>{props.user.username}</div>
				<div className="shadow-gray-300 shadow-inner">
					<div className="text-center text-gray-600">
						start chat with <br></br>
						<span className="font-semibold">{props.user.username}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StartChatTile;
