import type { HopComponent } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HopComponentState {
	hop_components: HopComponent[];
	init_status: true | false;
}

const initialState: HopComponentState = {
	init_status: true,
	hop_components: [],
};

export const hopComponentSlice = createSlice({
	name: "hop_components",
	initialState,
	reducers: {
		// setHopComponents populates the list
		setHopComponents(state, action: PayloadAction<HopComponent[]>) {
			if (state.init_status) {
				state.hop_components = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for hopComponents
		addHopComponent(state, action: PayloadAction<HopComponent>) {
			state.hop_components.push(action.payload);
		},
		removeHopComponent(state, action: PayloadAction<number>) {
			state.hop_components = state.hop_components.filter((hopComponent) => hopComponent.id !== action.payload);
		},
		updateHopComponent(state, action: PayloadAction<HopComponent>) {
			let index = state.hop_components.findIndex((hopComponent) => hopComponent.id === action.payload.id);
			state.hop_components[index] = action.payload;
		},
	},
});

export const { addHopComponent, removeHopComponent, updateHopComponent, setHopComponents } = hopComponentSlice.actions;
export default hopComponentSlice.reducer;
