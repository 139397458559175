// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
	/* display: flex; */
	/* position: fixed;
	top: 0;
	width: 100%; */
	background-color: #1b1b1b;
}

.footer {
	background-color: #1b1b1b;
}

.logo-container {
	display: flex;
	align-items: center;
}
.menu-container {
	/* background-color: aqua; */
	display: flex;
	align-items: center;
	flex-basis: auto;
	flex-grow: 1;
}

ul.menu-list {
	padding-left: 0px;
	text-align: right;
}

ul.menu-list > li:hover {
	animation: pulse_nav 1s infinite;
}

ul.menu-list > li {
	display: inline-block;
	padding: 10px 20px;
}

@keyframes pulse_nav {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/navbar.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB;;eAEc;CACd,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;AACA;CACC,4BAA4B;CAC5B,aAAa;CACb,mBAAmB;CACnB,gBAAgB;CAChB,YAAY;AACb;;AAEA;CACC,iBAAiB;CACjB,iBAAiB;AAClB;;AAEA;CACC,gCAAgC;AACjC;;AAEA;CACC,qBAAqB;CACrB,kBAAkB;AACnB;;AAEA;CACC;EACC,mBAAmB;CACpB;CACA;EACC,qBAAqB;CACtB;CACA;EACC,mBAAmB;CACpB;AACD","sourcesContent":[".navbar {\n\t/* display: flex; */\n\t/* position: fixed;\n\ttop: 0;\n\twidth: 100%; */\n\tbackground-color: #1b1b1b;\n}\n\n.footer {\n\tbackground-color: #1b1b1b;\n}\n\n.logo-container {\n\tdisplay: flex;\n\talign-items: center;\n}\n.menu-container {\n\t/* background-color: aqua; */\n\tdisplay: flex;\n\talign-items: center;\n\tflex-basis: auto;\n\tflex-grow: 1;\n}\n\nul.menu-list {\n\tpadding-left: 0px;\n\ttext-align: right;\n}\n\nul.menu-list > li:hover {\n\tanimation: pulse_nav 1s infinite;\n}\n\nul.menu-list > li {\n\tdisplay: inline-block;\n\tpadding: 10px 20px;\n}\n\n@keyframes pulse_nav {\n\t0% {\n\t\ttransform: scale(1);\n\t}\n\t50% {\n\t\ttransform: scale(1.1);\n\t}\n\t100% {\n\t\ttransform: scale(1);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
