import { Place, PlaceOpinion, User } from "@prisma/client";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setSelectedPlace } from "../../reducers/placesReducer";
import { Rating, Typography } from "@mui/material";
import PlaceInfoDialog from "./PlaceInfoDialog";
import { howLongAgo } from "../../Services/parsing";

interface IProps {
    opinion: PlaceOpinion,
    user: User
}

const PlaceOpinionTile: React.FC<IProps> = (props: IProps) => {
    
    const { opinion, user } = props;

    return (
        <div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 m-2">      
            <div className="flex flex-col">
                <div className="flex justify-between m-2 p-2 grow">
                    <div className="flex flex-row">
                    <p>{user ? user.username : "Unknown"}</p>
                    <div className=""><Rating
                    name="rating"
                    value={opinion.stars}
                    readOnly>

                    </Rating></div>
                    </div>
                    <div className="">
                        {howLongAgo(new Date(opinion.createdAt))} ago
                    </div>
                </div>
                
                <div className="p-2 m-2">
                    <p>{opinion.content}</p>
                </div>
            </div>        
        </div>
    );
}

export default PlaceOpinionTile;