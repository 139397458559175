import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { PostLogin } from "../Services/authAPICalls";
import { toastSuccess, toastError } from "../Services/toastify";

const Login: React.FC = () => {
	document.title = "Beerhub - Login Page";

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const handleChange = (event: any) => {
		setFormData((prevData) => ({
			...prevData,
			[event.target.name]: event.target.value,
		}));
	};

	const HandleSubmit = async (event: any) => {
		setError("");
		event.preventDefault();
		if (formData.email === "") {
			setError("Please fill out all fields");
			return;
		}
		if (formData.password === "") {
			setError("Please fill out all fields");
			return;
		}

		// validation check regexes
		// if(!emailRegex.test(formData.email)) {
		//     setError("Please enter a valid email");
		// }

		// api call
		setIsLoading(true);
		try {
			const loginResponse = await PostLogin(formData.email, formData.password);
			console.log("lr", loginResponse);
			toastSuccess("Login successful, redirecting...");
			Cookies.set("token", loginResponse.data.token);
			Cookies.set("id", loginResponse.data.userId);
			navigate("/");
		} catch (error) {
			toastError("Login failed, bad credentials");
			console.log("Login error", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<h1>Login Page</h1>

			<form onSubmit={HandleSubmit}>
				<label> Email</label>
				<input type="text" name="email" placeholder="Email" onChange={handleChange}></input>
				<br></br>
				<label> Password</label>
				<input type="text" name="password" placeholder="Password" onChange={handleChange}></input>
				<br></br>
				<button type="submit" disabled={isLoading}>
					Login
				</button>
				<br></br>
			</form>

			<p>{error}</p>
		</>
	);
};

export default Login;
