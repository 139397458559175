import { Beer } from "@prisma/client";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import BeerFormTile from "../components/Encyclopedia/BeerFormTile";
import BeerList from "../components/Encyclopedia/BeerList";
import BeerTypeFormTile from "../components/Encyclopedia/BeerTypeFormTile";
import BreweryFormTile from "../components/Encyclopedia/BreweryFormTile";
import HopComponentFormTile from "../components/Encyclopedia/HopComponentFormTile";

const Encycylopedia: React.FC = () => {
	const beers: Beer[] = useSelector((state: RootState) => state.beers.beers);

	return (
		<div className="flex mt-4 px-20 w-full mx-auto gap-6">
			<div className="w-1/3 overflow-auto" style={{ height: "70vh" }}>
				{/* Lista piwska */}
				<BeerList beerList={beers}></BeerList>
			</div>
			<div className="w-2/3 overflow-auto" style={{ height: "70vh" }}>
				{/* Opis piwska */}
				<BeerFormTile></BeerFormTile>
				<BeerTypeFormTile></BeerTypeFormTile>
				<BreweryFormTile></BreweryFormTile>
				<HopComponentFormTile></HopComponentFormTile>
			</div>
		</div>
	);
};

export default Encycylopedia;
