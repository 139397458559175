import { BeerMeet, Place, User } from "@prisma/client";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBeerMeetParticipants } from "../../Services/beerMeetsAPICalls";
import { GetPlaceById } from "../../Services/placesAPICalls";
import { Avatar } from "@mui/material";

interface IProps {
    beerMeet: BeerMeet
}

const BeerMeetTile: React.FC<IProps> = (props:  IProps) => {
    
    const beermeet: BeerMeet = props.beerMeet;
    const [participants, setParticipants] = useState<User[]>([]);
    const [place, setPlace] = useState<Place>({} as Place);
    const [owner, setOwner] = useState<User>({} as User);

    const navigate = useNavigate();
    const [fetchPlace, setFetchPlace] = useState<boolean>(false);
    useEffect(() => {
        const fetchParticipants = async () => {
            try{
                const response = await getBeerMeetParticipants(beermeet.id);
                setParticipants(response);
                setOwner(participants.find((user) => user.id === beermeet.ownerId) as User);
            } catch(err) {
                console.log(err);
            }
            
        }
        const fetchPlace = async () => {
            try{
                if(!beermeet.placeId) return
                const response:Place = await GetPlaceById(beermeet.placeId);
                setPlace(response);
                setFetchPlace(true);
            } catch(err) {
                console.log(err);
            }
            
        }
        fetchParticipants();
        fetchPlace();
        // console.log("typ ",typeof beermeet.createdAt)
        // console.log(beermeet.createdAt)
    }, []);

    const parseDate = (date: string) => {
        return new Date(date).getDate() 
        + "." + (new Date(date).getMonth() + 1 
        + ".") + new Date(date).getFullYear() + " " + new Date(date).getHours() + ":" + new Date(date).getMinutes();
    }

    const parseDMY = (date: string) => {
        return new Date(date).getDate() 
        + "." + (new Date(date).getMonth() + 1 
        + ".") + new Date(date).getFullYear();
    }

    return (
        <div className="p-2 ml-auto mr-auto mb-2 bg-white shadow-md shadow-gray-500 rounded-md w-full">
            <div className="flex flex-row">
                <div className="flex flex-col mr-auto">
                    <div className="text-lg font-bold ml-2 mt-2 mr-2 p-2"> {beermeet.name}</div>

                    <div className="text-sm m-2 p-2"> {beermeet.description}</div>    
                </div>
                <div className="flex flex-col ml-auto">
                    <div className="m-2 p-2">
                        <div className="text-right text-gray-500">{owner ? owner.username : "undefined"} {parseDMY(beermeet.createdAt)}</div>
                        <div><strong>Event begins: </strong>{parseDate(beermeet.startingAt)}-{ parseDate(beermeet.endingAt)}</div>
                        <div className="text-right">
                            {place === null ? null : <><strong>Place: </strong>{place.name}</>}
                            </div>
                            
                        
                    </div>
                </div>
                

            </div>
            <div>
                <div className="m-2 p-2">Participants </div>
                <div className="m-2 p-2 grid grid-cols-4">

                    {[...participants].slice(0, 3).map((user) => <div className="cursor-pointer" onClick={() => navigate(`/profile/${user.id}`)}>
                        <Avatar alt={user.username ? user.username : ""} src={user?.avatarpath ? user.avatarpath : "http://www.w3.org/2000/svg"} />
                        <div>{user.username}</div>
                    </div>)}
                    <div>
                        {[...participants].length > 3 ? <div className="cursor-pointer" onClick={() => navigate(`/profile/${owner.id}`)}>
                            <div className="ml-auto mr-auto"><Avatar alt="+" src={owner?.avatarpath ? owner.avatarpath : "http://www.w3.org/2000/svg"} /></div>
                            <div>More</div>
                        </div> : null}
                    </div>

                </div>
            </div>
            
            


        </div>
    );
}

export default BeerMeetTile;