import type { ArticleComment, ArticleCommentReaction } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ArticleCommentReactionState {
	article_comment_reactions: ArticleCommentReaction[];
	init_status: true | false;
}

const initialState: ArticleCommentReactionState = {
	init_status: true,
	article_comment_reactions: [],
};

export const articleCommentReactionSlice = createSlice({
	name: "article_comment_reactions",
	initialState,
	reducers: {
		// setArtciles populates the list
		setArticleCommentReactions(state, action: PayloadAction<ArticleCommentReaction[]>) {
			if (state.init_status) {
				state.article_comment_reactions = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for users
		addArticleCommentReaction(state, action: PayloadAction<ArticleCommentReaction>) {
			state.article_comment_reactions.push(action.payload);
		},
		removeArticleCommentReaction(state, action: PayloadAction<number>) {
			state.article_comment_reactions = state.article_comment_reactions.filter(
				(loc) => loc.id !== action.payload
			);
		},
		updateArticleCommentReaction(state, action: PayloadAction<ArticleCommentReaction>) {
			let index = state.article_comment_reactions.findIndex((loc) => loc.id === action.payload.id);
			state.article_comment_reactions[index] = action.payload;
		},
	},
	selectors: {
		getArticleCommentReactions: (state) => state.article_comment_reactions,
		getArticleCommentReactionsForComment: (
			state,
			action: PayloadAction<ArticleComment>
		): ArticleCommentReaction[] =>
			state.article_comment_reactions.filter(
				(reaction) => reaction.articleCommentId === action.payload.id
			) as ArticleCommentReaction[],
	},
});

export const {
	addArticleCommentReaction,
	removeArticleCommentReaction,
	updateArticleCommentReaction,
	setArticleCommentReactions,
} = articleCommentReactionSlice.actions;
export default articleCommentReactionSlice.reducer;
