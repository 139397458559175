import axiosConfig from "../axiosConfig";
import { UserDetail } from "@prisma/client";

export const GetUserDetails = async (): Promise<UserDetail[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/user_details",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetUserDetail = async (id: number): Promise<UserDetail> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/user_details/${id}`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const GetUserDetailByUser = async (id: number): Promise<UserDetail> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: `/users/${id}/details`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

// nie dodaje do store !!
export const PostUserDetail = async (
	user_detail: Omit<UserDetail, "id" | "createdAt" | "updatedAt">
): Promise<UserDetail> => {
	let data_to_send = JSON.stringify(user_detail);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/user_details",
		headers: {
			"Content-Type": "application/json",
		},
		data: data_to_send,
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};
