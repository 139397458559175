import { Beer, BeerOpinion } from "@prisma/client";
import axiosConfig from "../axiosConfig";
import { Interface } from "readline";
import exp from "constants";

export const getBeerOpinions = async(): Promise<BeerOpinion[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/beer_opinions",
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

export const getBeerOpinion = async(id: number): Promise<BeerOpinion> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/beer_opinions/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

export const getBeerOpinionByBeerId = async(beerId: number): Promise<BeerOpinion[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/beer_opinions/beer/${beerId}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Get: Something went wrong");
    }
}

export const postBeerOpinion = async (beerOpinion: Omit<BeerOpinion, "id" | "createdAt" | "updatedAt">): Promise<BeerOpinion> => {
    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/beer_opinions",
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            stars: beerOpinion.stars,
            content: beerOpinion.content,
            userId: beerOpinion.userId,
            beerId: beerOpinion.beerId
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Post: Something went wrong");
    }
}

export const deleteBeerOpinion = async (id: number): Promise<void> => {
    let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `/beer_opinions/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Beer Delete: Something went wrong");
    }
}