import { Chat, Message } from "@prisma/client";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { howLongAgo } from "../../Services/parsing";

interface IProps {
	chat: Chat;
}

// get wszystkie id osob w wspolnych chatach

const ChatTile: React.FC<IProps> = (props: IProps) => {
	const navigate = useNavigate();
	const last_message: Message | undefined = useSelector((state: any) =>
		state.messages.messages.findLast((message: any) => message.chatId === props.chat.id)
	);

	const changeChat = () => {
		console.log("change chat to", props.chat.id);
		navigate(`/messenger/${props.chat.id}`);
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<div className="p-2 hover:font-semibold" onClick={changeChat}>
				<div>{props.chat.name ? props.chat.name : "chat_id " + props.chat.id}</div>
				<div className="shadow-gray-300 shadow-inner">
					<div className="text-center text-gray-800 overflow-hidden">
						{last_message ? last_message.content : "no messages yet"}
					</div>
					<div className="text-sm text-right text-gray-500">
						{last_message ? howLongAgo(new Date(last_message.updatedAt)) : "never"} ago
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatTile;
