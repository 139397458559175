import axiosConfig from "../axiosConfig";
import { Location } from "@prisma/client";

export const GetLocations = async (): Promise<Location[]> => {
    let Config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/locations",
        headers: {
            "Content-Type": "application/json",
        },
    };
    try {
        const response = await axiosConfig.request(Config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

