import React from "react";
import { User } from "@prisma/client";
import UserTile from "./UserTile";
import { useSelector } from "react-redux";

const UserListFiltered: React.FC<{}> = () => {
	const usersList: User[] = useSelector((state: any) => state.users.usersSearch);

	return (
		<div className="overflow-y-auto max-h-[40vh]">
			{/* List of users */}
			{[...usersList].map((user: User) => (
				<UserTile key={user.id} user={user} />
			))}
		</div>
	);
};

export default UserListFiltered;
