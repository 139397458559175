import { ChatUser, Message, User } from "@prisma/client";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { toastError } from "../../Services/toastify";
import { useParams } from "react-router-dom";
import { PostMessage } from "../../Services/messagesAPICalls";
import { addMessage } from "../../reducers/messagesReducer";
import io from "socket.io-client";
import { table } from "console";
import { socket } from "../../socket";

const MessageFormTile: React.FC = () => {
	const current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);
	const [isLoading, setIsLoading] = React.useState(false);
	const params = useParams();
	const chatId: number = parseInt(params.chatId!);
	const dispatch = useDispatch();

	const submitMessage = async (event: any) => {
		event.preventDefault();
		let el = document.getElementById("message-textarea") as HTMLTextAreaElement;

		// content validator
		let content = "";
		if (el.value.length > 0) {
			content = el.value;
		} else {
			return;
		}

		setIsLoading(true);
		// posting new article
		try {
			const newMessage: Omit<Message, "id" | "createdAt" | "updatedAt"> = {
				content,
				userId: current_user?.id ? current_user.id : 0,
				chatId: isNaN(chatId) ? 0 : chatId,
			};
			const submitResponse = await PostMessage(newMessage);
			dispatch(addMessage(submitResponse));
			console.log("after post message", submitResponse);
			socket.emit("new_message", submitResponse);
		} catch (error) {
			toastError("Error while submitting message");
			console.log("Message error", error);
		} finally {
			el.value = "";
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<div className="flex gap-3">
				<div className="w-12 rounded-full overflow-hidden">
					<svg
						className="w-[48px] h-[48px] text-gray-800 dark:text-white"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path
							stroke="black"
							stroke-width="2"
							d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
						/>
					</svg>
				</div>
				<textarea id="message-textarea" className="grow p-3" placeholder="Write something!"></textarea>
			</div>
			<div>
				<button
					className="bg-black text-white px-10 py-2 rounded-md m-2"
					onClick={submitMessage}
					disabled={isLoading}
				>
					Send
				</button>
			</div>
		</div>
	);
};

export default MessageFormTile;
