import { Article, Place, PlaceOpinion, User } from "@prisma/client";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle } from "../../reducers/articlesReducer";
import { RootState } from "../../store";
import { PostArticle } from "../../Services/articlesAPICalls";
import { toastSuccess, toastError } from "../../Services/toastify";
import { Status } from "@prisma/client";

import { PlaceType } from "@prisma/client";
import { addPlace } from "../../reducers/placesReducer";
import { PostPlace, PostPlaceWithLocation } from "../../Services/placesAPICalls";
import { setSearchedPlaces } from "../../reducers/placesSearchReducer";
import { Rating } from "@mui/material";
import { postPlaceOpinion } from "../../Services/placeOpinionAPICAlls";
import { IReturnPostPlaceOpinion } from "../../Services/placeOpinionAPICAlls";

interface IProps {
    place: Place,
    updateParent: (opinion: PlaceOpinion, place: Place) => void
}

const PlaceOpinionForm: React.FC<IProps> = (props: IProps) => {
	
    let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);
	
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		content : "",
        rating : 0,
	});
    
    

	// changes the state of the formData on every input
	const handleChange = (event: any) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

	};

	const submitPlace = async (event: any) => {
		event.preventDefault();

        if (formData.rating === 0) {
            toastError("Please select star rating");
            return;
        }

        setIsLoading(true);
		try {
            const newOpinion: Omit<PlaceOpinion, "id" | "createdAt" | "updatedAt"> = {
                stars: formData.rating as number,
                content: formData.content,
                placeId: props.place.id,
                userId: current_user ? current_user.id : 0
            }
            const res: IReturnPostPlaceOpinion = await postPlaceOpinion(newOpinion);
            // to działa czemuś ?????
            const {newPlaceOpinion, updatedPlace} = res;
            props.updateParent(res.newPlaceOpinion, res.updatedPlace);
            setFormData({
                content : "",
                rating : 0
            })
            toastSuccess("Opinion added");
		} catch (error) {
			console.log(error);
            toastError("Something went wrong");
		}
        var form:HTMLFormElement= document.getElementById("form") as HTMLFormElement;
        form.reset()
        setFormData({
            content : "",
            rating : 0
        })
        var textarea:HTMLTextAreaElement= document.getElementById("content") as HTMLTextAreaElement;
        textarea.value = ""
		setIsLoading(false);
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 m-2">
			<form onSubmit={submitPlace}>
                <div className="flex flex-row">
                    <div className="w-1/4">
                        <p className="p-3">Add your opinion</p>

                        <p className="p-3"><Rating
                                name="rating"
                                
                                value={formData.rating} 
                                // onChange={(event, newValue) => {
                                //     setValue(newValue);
                                // }}
                                onChange={handleChange}
                                
                            /></p>
                    </div>
                    <div className="w-3/4">
                        <textarea
                            id="content"
                            name="content"
                            onChange={handleChange}
                            placeholder={`your opinion`}
                            maxLength={200}
                            className="h-full w-full p-3"
                        ></textarea>
                    </div>
                    
                </div>
				
				<div className="flex justify-end">
					{/* info */}
                        <button
                            type="submit"
                            
                            className={"bg-black text-white px-10 py-2 rounded-md m-2"}
                            disabled={isLoading}
                        >
                            
                            Add Opinion{" "}
                        </button>
				    
				</div>
				
					

			</form>
		</div>
	);
};

export default PlaceOpinionForm;
