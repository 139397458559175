import type { BeerOpinion } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BeerOpinionsState {
    beer_opinions: BeerOpinion[];
    init_status: true | false;
}

const initialState: BeerOpinionsState = {
    init_status: true,
    beer_opinions: [],
};

export const beerOpinionsSlice = createSlice({
    name: "beer_opinions",
    initialState,
    reducers: {
        setBeerOpinions: (state, action: PayloadAction<BeerOpinion[]>) => {
            if (state.init_status) {
                state.beer_opinions = action.payload;
                state.init_status = false;
            }
        },
        addBeerOpinion: (state, action: PayloadAction<BeerOpinion>) => {
            state.beer_opinions.push(action.payload);
        },
        updateBeerOpinion: (state, action: PayloadAction<BeerOpinion>) => {
            let index = state.beer_opinions.findIndex(
                (beerOpinion) => beerOpinion.id === action.payload.id
            );
            state.beer_opinions[index] = action.payload;
        },
        removeBeerOpinion: (state, action: PayloadAction<BeerOpinion>) => {
            let index = state.beer_opinions.findIndex(
                (beerOpinion) => beerOpinion.id === action.payload.id
            );
            state.beer_opinions.splice(index, 1);
        },
    },
});

export const {
    setBeerOpinions,
    addBeerOpinion,
    updateBeerOpinion,
    removeBeerOpinion,
} = beerOpinionsSlice.actions;
export default beerOpinionsSlice.reducer;