import type { Article, ArticleReaction } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ArticleReactionState {
	article_reactions: ArticleReaction[];
	init_status: true | false;
}

const initialState: ArticleReactionState = {
	init_status: true,
	article_reactions: [],
};

export const articleReactionSlice = createSlice({
	name: "article_reactions",
	initialState,
	reducers: {
		// setArtciles populates the list
		setArticleReactions(state, action: PayloadAction<ArticleReaction[]>) {
			if (state.init_status) {
				state.article_reactions = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for users
		addArticleReaction(state, action: PayloadAction<ArticleReaction>) {
			state.article_reactions.push(action.payload);
		},
		removeArticleReaction(state, action: PayloadAction<number>) {
			state.article_reactions = state.article_reactions.filter((loc) => loc.id !== action.payload);
		},
		updateArticleReaction(state, action: PayloadAction<ArticleReaction>) {
			let index = state.article_reactions.findIndex((loc) => loc.id === action.payload.id);
			state.article_reactions[index] = action.payload;
		},
	},
	selectors: {
		getArticleReactions: (state) => state.article_reactions,
		getArticleReactionsForArticle: (state, action: PayloadAction<Article>): ArticleReaction[] =>
			state.article_reactions.filter((comment) => comment.articleId === action.payload.id) as ArticleReaction[],
	},
});

export const { addArticleReaction, removeArticleReaction, updateArticleReaction, setArticleReactions } =
	articleReactionSlice.actions;
export default articleReactionSlice.reducer;
