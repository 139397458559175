import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import MapViewForPicker from "../Map/PlacePicker/MapViewForPicker";
import { useDispatch, useSelector } from "react-redux";
import { resetSearch } from "../../reducers/placesReducer";
import PlaceListFilter from "./PlaceListFilter";
import PlaceListFiltered from "./PlaceListFiltered";
import { RootState } from "../../store";
import { Place } from "@prisma/client";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ChoosePlaceDialog: React.FC = () => {
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const selPlace: null | Place = useSelector((state: RootState) => state.places.selectedPlace);
	const handleClickOpen = () => {
		dispatch(resetSearch());
		setOpen(true);
	};
	const handleClose = () => {
		dispatch(resetSearch());
		setOpen(false);
	};

	const handleAgree = () => {
		setOpen(false);
	};

	return (
		<div className="flex flex-row h-full">
			{/* Button to trigger the opening of the dialog */}

			<button type="button" className={"bg-black text-white px-10 py-2 rounded-md m-2"} onClick={handleClickOpen}>
				Choose Place
			</button>

			{/* Dialog that is displayed if the state open is true */}
			<Dialog
				className="p-10"
				open={open}
				maxWidth="lg"
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullScreen
				TransitionComponent={Transition}
			>
				<div className="p-5 h-full">
					{/* Info */}
					<div className="grid mt-5 grid-cols-2 gap-4 h-full">
						{/* Map */}
						<MapViewForPicker />
						{/* List */}
						<div className="h-full">
							<PlaceListFilter />
							<PlaceListFiltered />
						</div>
					</div>
				</div>
				<DialogActions>
					<Toolbar>
						<Button autoFocus color="inherit" onClick={handleAgree} disabled={selPlace === null}>
							Choose Selected
						</Button>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Cancel
						</Button>
					</Toolbar>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ChoosePlaceDialog;
