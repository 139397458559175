import axiosConfig from "../axiosConfig";
import { Chat } from "@prisma/client";
import { addChat } from "../reducers/chatsReducer";

export const GetChats = async (): Promise<Chat[]> => {
	let Config = {
		method: "get",
		maxBodyLength: Infinity,
		url: "/chats",
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await axiosConfig.request(Config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const PostChat = async () => {
	// (chat: Omit<Chat, "id" | "createdAt" | "updatedAt">): Promise<Chat> => {
	// let data_to_send = JSON.stringify(chat);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: "/chats",
		headers: {
			"Content-Type": "application/json",
		},
		data: {},
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("error", error.response);
		throw new Error("Somethink went wrong");
	}
};

export const EditChat = async (chat: Chat): Promise<Chat> => {
	let config = {
		method: "put",
		maxBodyLength: Infinity,
		url: `/chats/${chat.id}`,
		headers: {
			"Content-Type": "application/json",
		},
		data: JSON.stringify(chat),
	};

	try {
		const response = await axiosConfig.request(config);
		return response.data;
	} catch (error) {
		console.log("put chat error API service", error.response);
		throw new Error("Somethink went wrong");
	}
};
