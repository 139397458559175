import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';
import { Article, Status } from "@prisma/client";
import { InputLabel, MenuItem, Button, Select } from "@mui/material";
import { toastError, toastSuccess } from "../../Services/toastify";
import { EditArticle } from "../../Services/articlesAPICalls";
import { useDispatch } from "react-redux";
import { updateArticle } from "../../reducers/articlesReducer";

interface IProps {
    article: Article
}

const ArticleEditDialog: React.FC<IProps> = (props: IProps) => {
	const [open, setOpen] = React.useState(false);
    const article: Article = props.article;
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
		title: article.title,
		content: article.content,
		status: article.status,
        place: article.placeId,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const resetForm = () => {
        setFormData({
            title: article.title,
            content: article.content,
            status: article.status,
            place: article.placeId ? article.placeId : 0,
        });
    }
    const handleClose = () => {
		setOpen(false);
        resetForm();
	};

	const handleSave = async (e) => {
		e.preventDefault();
		console.log(formData);
        if(formData.content === "" || formData.title === ""){
            toastError("Please fill out title and content");
            return;
        }
        try{
            const newValuesArticle: Article = {
                id: article.id,
                createdAt: article.createdAt,
                updatedAt: article.updatedAt,
                title: formData.title,
                content: formData.content,
                likes: article.likes,
                dislikes: article.dislikes,
                status: formData.status,
                authorId: article.authorId,
                placeId: article.placeId,
            }
            const submitResponse = await EditArticle(newValuesArticle);
            dispatch(updateArticle(submitResponse));
            toastSuccess("Your post has been submitted");
            resetForm();
        }catch(error){
            console.log("front edit error: ", error);
            toastError("Error while editing article");
        }

        handleClose();
	};
	
    

    const handleChange = (event: any) => {
        console.log(formData);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        
        
    };


	return (
		<>
			{/* Button to trigger the opening of the dialog */}
			<Button variant="text" onClick={handleClickOpen} className="flex hoverPointer text-left w-full p-2 gap-2 hover:shadow-gray-500 hover:shadow-sm active:shadow-inner">
                <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m10.8 17.8-6.4 2.1 2.1-6.4m4.3 4.3L19 9a3 3 0 0 0-4-4l-8.4 8.6m4.3 4.3-4.3-4.3m2.1 2.1L15 9.1m-2.1-2 4.2 4.2"
                    />
                </svg>
                Edit Article
            </Button>

			{/* Dialog that is displayed if the state open is true */}
			<Dialog className="p-10"
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
                PaperProps={{
                    component: "form",
                    
                }}
			>
                
				<DialogTitle >Article Edit</DialogTitle>
				<DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="title"
                    label="Title"
                    type="text"
                    fullWidth
                    value={formData.title}
                    onChange={handleChange}
                    />
                    <TextField
                    autoFocus
                    multiline
                    margin="dense"
                    name="content"
                    label="content"
                    type="text"
                    fullWidth
                    value={formData.content}
                    onChange={handleChange}
                    />
                    <InputLabel id="status_select">Status</InputLabel>
                    <Select
                        labelId="status_select"
                        id="demo-simple-select"
                        size="small"
                        value={formData.status}
                        label="Age"
                        onChange={handleChange}
                        name="status"
                        >
                        <MenuItem value={Status.PUBLIC}>{Status.PUBLIC}</MenuItem>
                        <MenuItem value={Status.PRIVATE}>{Status.PRIVATE}</MenuItem>
                        <MenuItem value={Status.FRIENDSONLY}>{Status.FRIENDSONLY}</MenuItem>
                    </Select>
                    <br></br>
                    <div className="flex flex-row-reverse mt-4 gap-2">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={handleSave}>Save</Button>
                    </div>
                    
                    
				</DialogContent>
			</Dialog>
		</>
	);
};
export default ArticleEditDialog;
