import React from "react";

import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "../../Services/toastify";
import { Brewery } from "@prisma/client";
import { PostBrewery } from "../../Services/breweriesAPICalls";
import { addBrewery } from "../../reducers/breweriesReducer";

const BreweryFormTile: React.FC = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = React.useState(false);
	const [formData, setFormData] = React.useState({
		name: "",
	});

	const handleChange = (event: any) => {
		setFormData((prevData) => ({
			...prevData,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		let name = "";
		if (formData.name) {
			name = formData.name;
		} else {
			toastError("Please fill out name for beer type");
			return;
		}

		setIsLoading(true);
		try {
			const newBrewery: Omit<Brewery, "id" | "createdAt" | "updatedAt"> = {
				name,
			};
			const submitResponse = await PostBrewery(newBrewery);
			dispatch(addBrewery(submitResponse));
			console.log("after post brewery", submitResponse);
			toastSuccess("Your brewery has been submitted");
			event.target.reset();
		} catch (error) {
			toastError("Error while submitting brewery");
			console.log("Brewery error", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<p>BreweryFormTile</p>
			<form onSubmit={handleSubmit}>
				<label>
					Name:
					<input type="text" name="name" value={formData.name} onChange={handleChange} />
				</label>
				<br />
				<button type="submit" onClick={handleSubmit} disabled={isLoading}>
					Submit
				</button>
			</form>
		</div>
	);
};

export default BreweryFormTile;
