import { Beer, BeerOpinion, BeerType, Brewery, HopComponent } from "@prisma/client";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { getBeerOpinionByBeerId } from "../../Services/beerOpinionAPICalls";
import BeerInfoDialog from "./BeerInfoDialog";

interface IProps {
	beer: Beer;
}

const BeerTile: React.FC<IProps> = (props: IProps) => {
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(true);

	const type: BeerType = useSelector((state: any) =>
		state.beer_types.beer_types.find((type: any) => type.id === props.beer.typeId)
	);
	const brewery: Brewery = useSelector((state: any) =>
		state.breweries.breweries.find((brewery: any) => brewery.id === props.beer.breweryId)
	);
	const hopComponent: HopComponent = useSelector((state: any) =>
		state.hop_components.hop_components.find((hopComponent: any) => hopComponent.id === props.beer.hopComponentId)
	);
	const beerOpinions: BeerOpinion[] = useSelector((state: any) =>
		state.beer_opinions.beer_opinions.filter((beerOpinion: any) => beerOpinion.beerId === props.beer.id)
	);


	const [stars, setStars] = React.useState<number>(0);

	const [beer_opinions, setBeer_opinions] = React.useState<BeerOpinion[]>([]);

	useEffect(() => {
		const fetchBeerOpinions = async () => {
			try {
				const response = await getBeerOpinionByBeerId(props.beer.id);
				setBeer_opinions(response);
				let stars = response.map((beerOpinion) => beerOpinion.stars).reduce((a, b) => a + b, 0) / response.length;
				setStars(stars);
			} catch (error) {
				console.log(error);
			}
		}
		fetchBeerOpinions();
		if (type && brewery && hopComponent) setLoading(false);
	}, [type, brewery, hopComponent]);

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<p>
				{props.beer.name}, id: {props.beer.id}
				<br/>
				avg. stars: {Number.isNaN(stars) ? 0 : stars.toPrecision(2)}, from: {beer_opinions.length} opinions
			</p>
			{loading ? (
				<p>Loading...</p>
			) : (
				<>
					<p>
						{type.name}, id:{type.id}
					</p>

					<p>
						{brewery.name}, id:{brewery.id}
					</p>

					<p>
						{hopComponent.name}, id:{hopComponent.id}
					</p>

					<button
						onClick={() => navigate(props.beer.id ? `/beerrate/${props.beer.id}` : `/`)}
						className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m"
					>
						Rate
					</button>
					<BeerInfoDialog beer={props.beer}/>
				</>
			)}
		</div>
	);
};

export default BeerTile;

{
	/* <p className=" m-2 p-2"><Rating
                                            name="read-only"
                                            precision={0.1}
                                            value={user.avgStars} 
                                            readOnly
                                        /></p> */
}
