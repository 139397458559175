import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { PostLogin } from "../../Services/authAPICalls";
import { toastSuccess, toastError } from "../../Services/toastify";

const LoginFormTile: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const handleChange = (event: any) => {
		setFormData((prevData) => ({
			...prevData,
			[event.target.name]: event.target.value,
		}));
	};

	const HandleSubmit = async (event: any) => {
		setError("");
		event.preventDefault();
		if (formData.email === "") {
			setError("Please fill out all fields");
			return;
		}
		if (formData.password === "") {
			setError("Please fill out all fields");
			return;
		}

		// validation check regexes
		// if(!emailRegex.test(formData.email)) {
		//     setError("Please enter a valid email");
		// }

		// api call
		setIsLoading(true);
		try {
			const loginResponse = await PostLogin(formData.email, formData.password);
			console.log("lr", loginResponse);
			toastSuccess("Login successful, redirecting...");
			Cookies.set("token", loginResponse.data.token);
			Cookies.set("id", loginResponse.data.userId);
			navigate("/");
		} catch (error) {
			toastError("Login failed, bad credentials");
			console.log("Login error", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2">
			<div className="block gap-3">
				<p className="px-2 text-sm text-gray-400">You are not signed in yet.</p>
				<p className="px-2 text-sm text-gray-400">
					Sign in to enjoy full access or{" "}
					{/* <NuxtLink to="/register" className="bold underline">sign up here to gain member privileges</NuxtLink> */}
					<button
						className="bold underline"
						onClick={() => {
							navigate("/register");
						}}
					>
						sign up here to gain member privileges
					</button>
					{/* /register */}
				</p>
				{/* @submit.prevent="submitCredentials" */}
				<form onSubmit={HandleSubmit}>
					<input
						className="p-2 m-2 border rounded-md shadow-sm shadow-gray-300"
						type="text"
						name="email"
						placeholder="Enter your e-mail"
						autoComplete="email username"
						onChange={handleChange}
						required
					/>
					<input
						className="p-2 m-2 border rounded-md shadow-sm shadow-gray-300"
						type="password"
						name="password"
						placeholder="Enter your password"
						autoComplete="current-password"
						onChange={handleChange}
						required
					/>
					<div className="grow"></div>
					<button
						className="bg-black text-white px-10 py-2 rounded-md m-2 shadow-sm shadow-gray-500 active:shadow-inner"
						disabled={isLoading}
						type="submit"
					>
						Log in
					</button>
				</form>
			</div>
			<p>{error}</p>
		</div>
	);
};

export default LoginFormTile;
