import React, { useEffect } from "react";
import ArticleList from "../components/Articles/ArticleList";

import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Article, Status, User } from "@prisma/client";
import ArticleFormTile from "../components/Articles/ArticleFormTile";
import LoginFormTile from "../components/Users/LoginFormTile";
import { useCookies } from "react-cookie";
import UsersGridList from "../components/Users/UsersGridList";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import { socket } from "../socket";
import { NotifyMessage } from "../Layout/BasicLayout";

const Homepage: React.FC = () => {
	let articles: Article[] = useSelector((state: RootState) =>
		state.articles.articles.filter((article) => article.status === Status.PUBLIC)
	);
	let current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);
	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);
	// const cookieId = cookies.id;

	console.log("current user", current_user);
	useEffect(() => {
		console.log("articles", articles);
	}, [articles]);

	// const current_user = useSelector((state: RootState) => state.current_user.current_user);
	useEffect(() => {
		function onFooEvent(value) {
			console.log("ja kurwa pierdole");
		}

		socket.on("notify_end", onFooEvent);
		// (mess: NotifyMessage) => {
		// 	console.log("notify_message", mess);
		// 	if (current_user) {
		// 		if (mess.to.includes(current_user.id)) {
		// 			console.log("notify_message", mess);
		// 			toast("new message from " + mess.from.username);
		// 		}
		// 	}
		// });
	}, []);

	return (
		<div className="flex mt-4 gap-6 fixed h-4/5 w-full mx-auto">
			<div className="w-1/6 bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 h-full ml-auto">
				<p>nadchodzace beermeety do ktorych uzytkownik moglby dolaczyc</p>
			</div>

			<div className=" overflow-y-auto no-scrollbar w-full max-w-2xl">
				{current_user ? (
					<>
						<ArticleFormTile />
					</>
				) : (
					<>
						<LoginFormTile />
					</>
				)}
				<ArticleList articleList={articles} />
			</div>
			<div className="w-1/6 bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-2 h-full  mr-auto">
				<div className="h-1/2">
					<UsersGridList />
				</div>
				<div className="h-1/2 shadow-md"></div>
			</div>
		</div>
	);
};

export default Homepage;
