import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Chat, Status } from "@prisma/client";
import { Button } from "@mui/material";
import { toastError, toastSuccess } from "../../Services/toastify";
import { EditChat } from "../../Services/chatsAPICalls";
import { useDispatch } from "react-redux";
import { updateChat } from "../../reducers/chatsReducer";

interface IProps {
	chat: Chat;
}

const ChatEditDialog: React.FC<IProps> = (props: IProps) => {
	const [open, setOpen] = React.useState(false);
	const chat: Chat = props.chat;
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		name: chat.name,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const resetForm = () => {
		setFormData({
			name: chat.name,
		});
	};
	const handleClose = () => {
		setOpen(false);
		resetForm();
	};

	const handleSave = async (e) => {
		e.preventDefault();
		if (formData.name === "") {
			toastError("Please fill out chat name");
			return;
		}
		try {
			const newValuesChat: Chat = {
				id: chat.id,
				createdAt: chat.createdAt,
				updatedAt: chat.updatedAt,
				name: formData.name,
			};
			const submitResponse = await EditChat(newValuesChat);
			dispatch(updateChat(submitResponse));
			toastSuccess("Chat name edited successfully");
			resetForm();
		} catch (error) {
			console.log("front edit error: ", error);
			toastError("Error while editing chat");
		}

		handleClose();
	};

	const handleChange = (event: any) => {
		console.log(formData);
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	return (
		<>
			{/* Button to trigger the opening of the dialog */}
			<Button
				variant="text"
				onClick={handleClickOpen}
				className="flex hoverPointer text-left w-full p-2 gap-2 hover:shadow-gray-500 hover:shadow-sm active:shadow-inner"
			>
				<svg
					className="w-6 h-6 text-gray-800 dark:text-white"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						stroke="black"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="m10.8 17.8-6.4 2.1 2.1-6.4m4.3 4.3L19 9a3 3 0 0 0-4-4l-8.4 8.6m4.3 4.3-4.3-4.3m2.1 2.1L15 9.1m-2.1-2 4.2 4.2"
					/>
				</svg>
				Edit Chat name
			</Button>

			{/* Dialog that is displayed if the state open is true */}
			<Dialog
				className="p-10"
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					component: "form",
				}}
			>
				<DialogTitle>Chat Edit</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						name="name"
						label="New name"
						type="text"
						fullWidth
						value={formData.name}
						onChange={handleChange}
					/>
					<br></br>
					<div className="flex flex-row-reverse mt-4 gap-2">
						<Button onClick={handleClose}>Cancel</Button>
						<Button variant="contained" onClick={handleSave}>
							Save
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};
export default ChatEditDialog;
