import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

interface IProps {
    action: () => void;
	dialogTitle?: string | undefined;
}

const DeleteDialog: React.FC<IProps> = (props: IProps) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleDisagree = () => {
		console.log("I do not agree.");
		handleClose();
	};
	const handleAgree = () => {
		console.log("I agree!");
		props.action();
		handleClose();
	};

	return (
		<div className="flex flex-row">
			{/* Button to trigger the opening of the dialog */}
			
			<Button variant="text" color="info" onClick={handleClickOpen} className="flex hoverPointer text-left w-full p-2 gap-2 hover:shadow-gray-500 hover:shadow-sm active:shadow-inner" sx={{ zIndex: 1300}} > 
            <svg
				className="w-6 h-6 text-gray-800 dark:text-white"
				aria-hidden="true"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<path
					stroke="black"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
				/>
			</svg>
			<label>
				{props.dialogTitle ? props.dialogTitle : "Delete"}						
			</label>
                
            </Button>
			
			
			

			{/* Dialog that is displayed if the state open is true */}
			<Dialog className="p-10"
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>	
			<div className="p-5">
				<DialogTitle id="alert-dialog-title">{props.dialogTitle ? props.dialogTitle : "Alert"}?</DialogTitle>
				<DialogActions>
					<Button onClick={handleAgree} >Delete</Button>
					<Button onClick={handleDisagree}>Cancel</Button>
				</DialogActions>
			</div>
				
			</Dialog>
		</div>
	);
};

export default DeleteDialog;
