import axiosConfig from "../axiosConfig";
import { Invite } from "@prisma/client";


// create new Invite
export const PostInvite = async (
    invite: Omit<Invite, "id" | "createdAt" | "updatedAt">): Promise<Invite> => {
    let data_to_send = JSON.stringify(invite);
    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/invites",
        headers: {
            "Content-Type": "application/json",
        },
        data: data_to_send,
    };

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

// get invites for user
export const GetPendingInvitesForUser = async (userID: number): Promise<Invite[]> => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/invites/user/${userID}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

// accept Invite
export const AcceptInvite = async (inviteID: number) => {
    let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `/invites/accept/${inviteID}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

// decline Invite via InviteId

export const DeclineInvite = async (inviteID: number) => {
    let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `/invites/decline/${inviteID}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

// decline Invite via sender and rceiver id
export const DeleteInviteBySenderIdAndReceiverId = async (senderID: number, receiverID: number) => {
    let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `/invites/delete/${senderID}/${receiverID}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}


// cancel invitec

export const CancelInvite = async (inviteID: number) => {
    let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `/invites/cancel/${inviteID}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

// check if invite exists
export const CheckIfInviteExists = async (senderId: number, receiverId: number) => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/invites/checkInvite/${senderId}/${receiverId}`,
        headers: {
            "Content-Type": "application/json",
        },
    }
    
    try {
        const response = await axiosConfig.request(config);
        console.log("checking fetch reposnse for invite ", senderId, receiverId);
        console.log("response", response.data);
        return response.data;
    } catch (error) {   
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

// check if users are friends

export const CheckIfUsersAreFriends = async (userID1: number, userID2: number) => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/invites/checkFriends/${userID1}/${userID2}`,
        headers: {
            "Content-Type": "application/json",
        },
    }

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}

// delete friends

export const DeleteFriend = async (user1Id: number, user2Id: number) => {
    let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `/invites/delete/${user1Id}/${user2Id}`,
        headers: {
            "Content-Type": "application/json",
        },
    }   

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {   
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}


// get user friends

export const ListUserFriends = async (userID: number) => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/invites/listFriends/${userID}`,
        headers: {
            "Content-Type": "application/json",
        },
    }   

    try {
        const response = await axiosConfig.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error.response);
        throw new Error("Somethink went wrong");
    }
}