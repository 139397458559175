import React, { useEffect, useState } from "react";
// import { GoogleMap, useLoadScript, Marker, Libraries } from '@react-google-maps/api';

import {
	APIProvider,
	Map,
} from "@vis.gl/react-google-maps";


import { Location, Place} from "@prisma/client";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import AdvancedMarkerWithInfoWindow from "./AdvanceMarkerWithInfoWIndow";
import { toastError } from "../../Services/toastify";





interface MarkerPosition {
	lat: number;
	lng: number;
}

interface IProps{
    place: Place
}


const GM_API = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MapWithOnePin: React.FC<IProps> = (props: IProps) => {
	
	
	const [geoPosition, setGeoPosition] = useState<MarkerPosition>({ lat: 53.11785563528795, lng: 23.146082016972432 });
	const place = props.place;
	const locations: Location[] = useSelector((state: RootState) => state.locations.locations);
	
	// geolocation for default position
	useEffect(() => {
        
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(function (position) {
				setGeoPosition({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			});
		} else {
			console.log("Geolocation is not available in your browser.");
			toastError("Geolocation is not available in your browser.\n Setting default value to Białystok.");
		}
	}, []);


	
	const getLatLngFromPlace = (place: Place): { lat: number; lng: number } | undefined => {
		let loc: Location | undefined = locations.find((loc) => loc.id === place.locationId);
		if (!loc) {
			return undefined;
		}
		const floatLat: number = parseFloat(loc.latitude);
		const floatLng: number = parseFloat(loc.longitude);
		return { lat: floatLat, lng: floatLng };
	};


	return (
        <div className="h-full">
				<APIProvider apiKey={GM_API as string}>
					<Map 
						defaultZoom={12}
						defaultCenter={geoPosition}
						mapId="infoMap"
						onClick={(ev) => {
						
						}}
						
					>
						{/* marker */}
                        <AdvancedMarkerWithInfoWindow 
                            place={place}
                            position={getLatLngFromPlace(place)}
                        />
					</Map>
				</APIProvider>            
		</div>
	);
};

export default MapWithOnePin;

