import { User } from "@prisma/client";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setSelectedUser } from "../../reducers/usersReducer";
import { Avatar, Rating, Typography } from "@mui/material";
// import UserInfoDialog from "./UserInfoDialog";

interface IProps {
	user: User;
}

const UserTile: React.FC<{ user: User }> = (props: IProps) => {
	const user: User = props.user;
	const selUser: null | User = useSelector((state: RootState) => state.users.selectedUser);
	const dispatch = useDispatch();
	const selectUser = () => {
		dispatch(setSelectedUser(user));
		console.log("selectUser", user.id);
	};

	return (
		<>
			{selUser?.id === user.id ? (
				<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-4 m-2">
					<div onClick={selectUser}>
						<div className="flex justify-between">
							<p className="font-bold text-lg mb-2">{user.username}</p>
							<Avatar
								alt={user?.username ? user?.username : ""}
								src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
							/>
						</div>
					</div>
					{/* <div className="flex justify-end"> */}
					{/* <button onClick={() => showUserInfo(user?.id)} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">See more info</button> */}
					{/* <UserInfoDialog user={user} /> */}
					{/* </div> */}
				</div>
			) : (
				<div className="bg-white shadow-md shadow-gray-500 rounded-md mb-5 p-4 m-2">
					<div onClick={selectUser}>
						<div className="flex justify-between">
							<p className="font-bold text-lg mb-2">{user.username}</p>
							<Avatar
								alt={user?.username ? user?.username : ""}
								src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
							/>
						</div>
					</div>
					{/* <div className="flex justify-end"> */}
					{/* <button onClick={() => showUserInfo(user?.id)} className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">See more info</button> */}
					{/* <UserInfoDialog user={user} /> */}
					{/* </div> */}
				</div>
			)}
		</>
	);
};

export default UserTile;
