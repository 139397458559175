import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../store";
import { Invite, InviteState, User } from "@prisma/client";
import { Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import { toastError, toastSuccess } from "../../Services/toastify";
import { CheckIfInviteExists, CheckIfUsersAreFriends, DeleteInviteBySenderIdAndReceiverId, PostInvite } from "../../Services/invitesAPICalls";
import { useCookies } from "react-cookie";

// pozyskanie id ze sciezki, np. /profile/1
// useSelector do pobrania profilu ze store

const ProfileTile: React.FC = () => {
	const params = useParams();
	const userId: number = Number(params.userId);
	// const profile = useSelector((state: RootState) => state.profiles.profiles.find((profile) => profile.id === userId));
	const user: User | undefined = useSelector((state: RootState) =>
		state.users.users.find((user) => user.id === userId)
	);
	const current_user: User | null = useSelector((state: RootState) => state.current_user.current_user);
	const isCurrentUserProfile:Boolean = user?.id === current_user?.id;

	const [cookies, setCookie, removeCookie] = useCookies(["id", "token"]);


	const [inviteButton, setInviteButton] = useState(false);
	const [cancelButton, setCancelButton] = useState(true);
	const [unfriendButton, setUnfriendButton] = useState(true);	
	const [helloText, setHelloText] = useState("");

	const fetchFriendsStatus = async () => {
		try{
			const isInvitedResponse = await CheckIfInviteExists(cookies.id, user?.id ? user?.id : 0);
			console.log("isInvitedResponse", isInvitedResponse);
			if(isInvitedResponse !== null){
				console.log("isInvitedResponse.status", isInvitedResponse.state);
				if(isInvitedResponse.state === InviteState.PENDING){
					setCancelButton(false);
					setInviteButton(true);
					setUnfriendButton(true);

				}else if(isInvitedResponse.state === InviteState.ACCEPTED){
					setUnfriendButton(false);
					setCancelButton(true);
					setInviteButton(true);
				}else{
					setInviteButton(false);
				}
			}
		}catch(e){
			console.log(e);
			
		}
	}

	useEffect(() => {
		fetchFriendsStatus();
		if(isCurrentUserProfile) setHelloText("Hello on your profile!");
	}, [current_user, user, inviteButton, cancelButton, unfriendButton, isCurrentUserProfile]);

	// yours profile
	const acceptInvite = () => {
		
	}
	const decliteInvite = () => {
		
	}

	// not yours profile
	const sendInvite = async () => {
		try{
			const invite: Omit<Invite, "id" | "createdAt" | "updatedAt"> = {
				state: InviteState.PENDING,
				senderId: cookies.id,
				receiverId: user?.id ? user?.id : 0,
			}
			const inviteResponse = await PostInvite(invite);
			console.log("isInvitedResponse", inviteResponse);
			toastSuccess("Friend invite sent!");
			if(inviteResponse !== null){
				setCancelButton(false);
				setInviteButton(true);
			}

		}catch(e){
			console.log(e);	
		}

	}
	const cancelInvite = async () => {
		try{			
			await DeleteInviteBySenderIdAndReceiverId(cookies.id, user?.id ? user?.id : 0);			
			toastSuccess("Friend invite canceled");
			setCancelButton(true);
			setInviteButton(false);
		}catch(e){
			console.log(e);
		}
	}
	const removeFriend = () => {
		
	}


	return (
		<div className="bg-white shadow-md shadow-gra y-500 rounded-md mb-5 p-2 flex flex-col">
			{/* Avaatar and name */}
			<div className="flex relative p-4 overflow-hidden rounded-md gap-4">
					<Avatar alt={user?.username ? user?.username : ""} src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
					<h1 className="text-3xl font-bold">{user?.username}</h1>
			</div>
			{/* Frriendly buttons */}
			<div className="p-4 pt-0 md:pt-4 pb-0 mb-4">
				{
					isCurrentUserProfile ? 
					<div>
						<Typography variant="body1">{helloText}</Typography>
						<button  hidden={unfriendButton} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
							Edit profile
						</button>
						<button  hidden={unfriendButton} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
							
						</button>
					</div>
					:
					<div>
						{/* For not tyour profile */}
						{/* if not friend send invite */}
						
						<button onClick={sendInvite} hidden={inviteButton} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
							Send Invite
						</button>
						{/* if not friend and invite sended show invite sended */}
						<button onClick={cancelInvite} hidden={cancelButton} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
							Cancel Invite
						</button>
						{/* if friend show that he is a friend - show option for unfriend*/}
						
						<button onClick={removeFriend} hidden={unfriendButton} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
							Unfriend
						</button>
						
					</div>
				}
			</div>
		</div>
	);
};

export default ProfileTile;
