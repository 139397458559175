import React from "react";
import { Beer } from "@prisma/client";
import BeerTile from "./BeerTile";

const BeerList: React.FC<{ beerList: Beer[] }> = (props) => {
	// ale to dlaczego to tak
	// co dlaczego? komunikuj co jest źle
	let beers: Beer[] = Array.from(props.beerList);
    console.log(beers);
	return (
		<>
			{[...beers]
				.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
				.map((beer: Beer) => (
					<BeerTile key={beer.id} beer={beer} />
				))}
		</>
	);
};

export default BeerList;
