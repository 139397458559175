import type { Place } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PlaceState {
	places: Place[];
	placesSearch: Place[];
	selectedPlace: null | Place;
	init_status: true | false;
}

const initialState: PlaceState = {
	init_status: true,
	places: [],
	placesSearch: [],
	selectedPlace: null,
};

export const placeSlice = createSlice({
	name: "places",
	initialState,
	reducers: {
		// setArtciles populates the list
		setPlaces(state, action: PayloadAction<Place[]>) {
			if (state.init_status) {
				state.places = action.payload;
				state.placesSearch = action.payload;
				state.init_status = false;
			}
		},
		// CRUD operations for users
		addPlace(state, action: PayloadAction<Place>) {
			state.places.push(action.payload);
		},
		removePlace(state, action: PayloadAction<number>) {
			state.places = state.places.filter((place) => place.id !== action.payload);
		},
		updatePlace(state, action: PayloadAction<Place>) {
			let index = state.places.findIndex((place) => place.id === action.payload.id);
			state.places[index] = action.payload;
		},
		setFilteredPlaces(state, action: PayloadAction<Place[]>) {
			state.placesSearch = action.payload;
		},
		updateSingleFilteredPlace(state, action: PayloadAction<Place>) {
			let index = state.placesSearch.findIndex((place) => place.id === action.payload.id);
			state.placesSearch[index] = action.payload;
		},
		resetSearch(state) {
			state.placesSearch = state.places;
			state.selectedPlace = null;
		},
		clearSelectedPlace(state) {
			state.selectedPlace = null;
		},
		setSelectedPlace(state, action: PayloadAction<Place | null>) {
			state.selectedPlace = action.payload;
			state.placesSearch = state.places.filter((place) => place.id === action.payload?.id);
		}
	},
	selectors: {
		getPlaces: (state) => state.places,
	},
});

export const {  
	updateSingleFilteredPlace, 
	setSelectedPlace, 
	clearSelectedPlace, 
	resetSearch, 
	addPlace, 
	removePlace, 
	updatePlace, 
	setPlaces, 
	setFilteredPlaces } = placeSlice.actions;
export const { getPlaces } = placeSlice.selectors;
export default placeSlice.reducer;
