import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Redux
import { store } from "./store";
import { Provider } from "react-redux";
import ReduxSetter from "./Layout/ReduxSetter";
// Routes
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Pages
import Homepage from "./Pages/Homepage";
import Test from "./Pages/Test";

//Layouts
import BasicLayout from "./Layout/BasicLayout";
import NoFooterLayout from "./Layout/NoFooterLayout";
import Login from "./Pages/Login";

import ToastComponent from "./components/Toast/ToastComponent";
import Register from "./Pages/Register";
import Messenger from "./Pages/Messenger";
import Profile from "./Pages/Profile";
import Places from "./Pages/Places";
import Encycylopedia from "./Pages/Encyclopedia";
import Users from "./Pages/Users";
import BeerRate from "./Pages/BeerRate";
import BeerMeets from "./Pages/BeerMeets";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<Routes>
				<Route path="/" element={<ReduxSetter />}>
					<Route path="/" element={<BasicLayout />}>
						<Route path="/" Component={Homepage} />
						<Route path="/messenger" Component={Messenger} />
						<Route path="/messenger/:chatId" Component={Messenger} />
						<Route path="/beermeets" Component={BeerMeets}/>
						<Route path="/register" Component={Register} />
						<Route path="/login" Component={Login} />
						<Route path="/users" Component={Users} />
						<Route path="/test" Component={Test} />
						<Route path="/places" Component={Places} />
						<Route path="/profile/:userId" Component={Profile} />
						<Route path="/encyclopedia" Component={Encycylopedia}/>
						<Route path="/beerrate/:beerId" Component={BeerRate} />
						<Route path="*" Component={Homepage} />

					</Route>
					<Route path="/" element={<NoFooterLayout />}>
						
					</Route>
				</Route>
			</Routes>
		</Provider>
		<ToastComponent />
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
